import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typograhy from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import styles from '../Styles';
import CancelIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { updatePhoneAccess } from '../../actions/authActions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

const INITIAL_STATE = {
  phoneNumber: '',
};

class PhoneAccessPopup extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  agreeToTerms = (event) => {
    //update with true and phonenumber
    const access = true;
    this.props.updatePhoneAccess(this.props.auth.uid, access, this.state.phoneNumber);
  };

  cancelAgreement = () => {
    //update with false
    const access = false;
    const empty = '';
    this.setState({ phoneNumber: '' });
    this.props.updatePhoneAccess(this.props.auth.uid, access, empty);
  };

  render() {
    const { phoneNumber } = this.state;
    const { profile, classes, auth } = this.props;
    const phoneNumberEntered = phoneNumber !== undefined && phoneNumber.length >= 10;

    if (profile.isLoaded && !profile.isEmpty && auth.uid) {
      return (
        <Dialog disableBackdropClick disableEscapeKeyDown aria-labelledby='phone-number-opt-in' aria-describedby='phone-number-opt-in' open={profile.phoneOpt === undefined} classes={{ paper: classes.termPopUp }}>
          <DialogTitle id='phone-number-opt-in-title'>
            <Grid container spacing={2} justifyContent='flex-start' alignItems='center'>
              <Grid item xs={10}>
                <Typograhy variant='h6'>Opt Into Text Notifcation</Typograhy>
              </Grid>
              <Grid item xs={2} align='right'>
                <IconButton onClick={this.cancelAgreement}>
                  <CancelIcon className={classes.soltaColor} />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Grid item xs container spacing={1}>
              <Grid item xs={12} sm={12}>
                <TextField className={classes.textField} onChange={this.onChange} id='phoneNumber' name='phoneNumber' value={phoneNumber} label='Phone Number' />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={1}>
              <Grid item xs={4} align='left'>
                <Button onClick={this.cancelAgreement}>Decline</Button>
              </Grid>
              <Grid item xs={8} align='right'>
                <Button disabled={!phoneNumberEntered} color='primary' onClick={this.agreeToTerms}>
                  Agree
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      );
    } else return null;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePhoneAccess: (user, access, phone) => dispatch(updatePhoneAccess(user, access, phone)),
  };
};

PhoneAccessPopup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation(), connect(mapStateToProps, mapDispatchToProps))(PhoneAccessPopup);
