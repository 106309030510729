import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//import ThermageLogo from '../../images/thermage_eyes.svg';
//import CBLogo from '../../images/CB_images/cb_logo.svg';
//import SoltaLogo from '../Logos/images/solta_logo.svg';
//import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LogoHeader from '../Header';
import { setPromo } from '../../actions/promoActions';
import LoadingBlock from '../Loading';
import ShowUsersPromosBlock from '../Promos/listPromos';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { closeCompanyPopUps } from '../../actions/companyActions';
import { registerLoggedinUsers, signOut } from '../../actions/authActions';
import * as COLOURS from '../Styles/colors';

//import CircularProgress from '@material-ui/core/CircularProgress';
//import SelectDoctor from '../Clinics/selectDoctorForm';
//import SelectPromoBlock from '../Promos/selectAPromo';
//import { ThermageButton } from '../Buttons/thermageButton';
//import Divider from '@material-ui/core/Divider';

const styles = {
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  customerNameCNB: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    color: COLOURS.soltaColor,
    fontSize: 48,
    '@media (max-width: 1025px)': {
      fontSize: 28,
    },
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  customerName: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    color: '#9b3e96',
    fontSize: 48,
    '@media (max-width: 1025px)': {
      fontSize: 28,
    },
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  logo: {
    marginTop: 30,
    width: 155,
    display: 'block',
    '@media (max-width: 1025px)': {
      width: 135,
      marginLeft: -40,
      marginTop: 0,
    },
  },
  alignCenter: {
    textAlign: 'center',
  },
  questionMark: {
    right: -50,
    display: 'block',
    position: 'absolute',
    top: 0,
    '@media (max-width: 1440px)': {
      right: -70,
    },
    '@media (max-width: 1300px)': {
      right: -85,
    },
    '@media (max-width: 1025px)': {
      right: -30,
    },
    '@media (max-width: 850px)': {
      right: -50,
    },
    '@media (max-width: 599px)': {
      right: -30,
    },
  },
  logoBlock: {
    position: 'relative',
    display: 'block',
  },
  mobileCenter: {
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const INITIAL_STATE = {
  //pagePromo: 'olff8ViVEXKmuBK179Zs', //This is the id of the promo, this will change to load from the url once we have more then one promo,
  a: false,
  loading: true,
};

class HomePageBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    this.setState({
      a: true,
    });
  }

  componentWillUnmount() {}

  componentDidUpdate() {
    //this.setState({ loading: true });
    if (this.state.loading) {
      if (this.props.promos) {
        //console.log('we only have one promo', this.props.promos[0].id);

        //this.props.setPromo(this.props.promos[0].id);
        this.setState({
          loading: false,
        });
      }
    }
  }

  closePopUps = () => {
    this.props.closeCompanyPopUps();
  };

  findUser = () => {
    //If we don't have any display information to add then we will have to ask for it
    if (this.props.auth.displayName) {
      this.props.registerLoggedinUsers(this.props.auth);
    } else {
      //We will need to create a popup first to add this onto
    }
  };

  sendToClinicSite = () => {
    this.props.signOut();
    window.location.href = 'https://clinic.solta.ca';
  };

  render() {
    const {
      //pagePromo
      loading,
    } = this.state;

    const { auth, t, classes, user, companyError, companyMessage, companyErrorPopUp, companyPopUp, userChecked } = this.props;

    if (!auth.uid) return <Redirect to={ROUTES.LOGIN} />;

    if (user.isLoaded && !user.isEmpty && !loading) {
      console.log('this is my user -->', user);

      return (
        <div className='meta-react'>
          <LogoHeader t={t} />
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className={classes.root}>
                  <Grid container spacing={3} alignItems='center' justifyContent='center'>
                    <Grid item xs={12} sm={12}>
                      <Typography variant='h3' className={classes.customerNameCNB}>
                        {user.fullName}
                      </Typography>
                      <Typography variant='body1' className={classes.mobileCenter}>
                        {t('home.seePromos')}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <ShowUsersPromosBlock />
                    </Grid>
                  </Grid>
                  <Snackbar open={companyPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
                    <Alert onClose={this.closePopUps} severity='success'>
                      {t(companyMessage)}
                    </Alert>
                  </Snackbar>
                  <Snackbar open={companyErrorPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
                    <Alert onClose={this.closePopUps} severity='error'>
                      {t(companyError)}
                    </Alert>
                  </Snackbar>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (user.isLoaded && user.isEmpty) {
      if (!userChecked) {
        this.findUser();
      } else {
        this.sendToClinicSite();
        return (
          <div className='meta-react'>
            <LogoHeader t={t} />
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <div className={classes.root}>
                    <Grid container spacing={3} justifyContent='center'>
                      <Grid item xs={12} align='center'>
                        <Typography variant='h6'>{t('home.clinicUserLoggedIn')}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
      return (
        <div className='meta-react'>
          <LogoHeader t={t} />
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className={classes.root}>
                  <Grid container spacing={3} justifyContent='center'>
                    <Grid item xs={12} align='center'>
                      <LoadingBlock />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className='meta-react'>
          <LogoHeader t={t} />
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className={classes.root}>
                  <Grid container spacing={3} justifyContent='center'>
                    <Grid item xs={12} align='center'>
                      <LoadingBlock />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

HomePageBase.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {
    auth: state.firebase.auth,
    user: state.firebase.profile,
    promos: state.firestore.ordered.promos,
    companyError: state.company.companyError,
    companyMessage: state.company.companyMessage,
    companyPopUp: state.company.companyPopUp,
    companyErrorPopUp: state.company.companyErrorPopUp,
    promoID: state.promos.promoID,
    userChecked: state.auth.userChecked,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPromo: (promoID) => dispatch(setPromo(promoID)),
    closeCompanyPopUps: () => dispatch(closeCompanyPopUps()),
    registerLoggedinUsers: (user) => dispatch(registerLoggedinUsers(user)),
    signOut: () => dispatch(signOut()),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => [{ collection: 'promos', where: [['justForReps', '==', false]] }])
)(HomePageBase);
