import { Redirect, Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import { addClinicToMyPromo, setPromo, getPromoCodeWithClinic } from '../../actions/promoActions';
import Typography from '@material-ui/core/Typography';
import ThermageLogo from '../../images/thermage_eyes.svg';
import CBLogo from '../../images/CB_images/cb_logo.svg';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
//import Divider from '@material-ui/core/Divider';
import LogoHeader from '../Header';
//import CircularProgress from '@material-ui/core/CircularProgress';
import LoadingBlock from '../Loading';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
//import { ThermageButton } from '../Buttons/thermageButton';
import { ClearBrilliantButton } from '../Buttons/clearBrilliantButton';
import { registerLoggedinUsers } from '../../actions/authActions';
import { getCompanyLogo } from '../../actions/companyActions';
//import defaultClinic from '../../images/default_clinic.svg';
//import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import NBAward from '../../images/NB_AwardSeal_2021.svg';
import firebase from 'firebase/app';

const styles = {
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  customerName: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    color: 'rgba(40,58,89,1)',
    fontSize: 38,
    '@media (max-width: 1025px)': {
      fontSize: 28,
    },
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  logo: {
    marginTop: 30,
    width: 155,
    display: 'block',
    '@media (max-width: 1025px)': {
      width: 135,
      marginLeft: -40,
      marginTop: 0,
    },
  },
  image: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  alignCenter: {
    textAlign: 'center',
  },
  questionMark: {
    right: -50,
    display: 'block',
    position: 'absolute',
    top: 0,
    '@media (max-width: 1440px)': {
      right: -70,
    },
    '@media (max-width: 1300px)': {
      right: -85,
    },
    '@media (max-width: 1025px)': {
      right: -30,
    },
    '@media (max-width: 850px)': {
      right: -50,
    },
    '@media (max-width: 599px)': {
      right: -30,
    },
  },
  logoBlock: {
    position: 'relative',
    display: 'block',
  },
  mobileCenter: {
    textAlign: 'left',
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  spacingTop: {
    marginTop: 20,
  },
};

const INITIAL_STATE = {
  //pagePromo: 'olff8ViVEXKmuBK179Zs', //This is the id of the promo, this will change to load from the url once we have more then one promo,
  loading: true,
  b: false,
  companyEnrolled: false,
  natCompanies: [],
  natCompanyLogos: {},
};

class NationalCodeLandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    this.setState({
      b: true,
    });
  }

  componentWillUnmount() {}

  componentDidUpdate() {
    //this.setState({ loading: true });
    if (this.state.loading) {
      if (this.props.promos && this.props.natProgram) {
        if (this.props.promos.length > 0 && this.props.natProgram.length > 0) {
          //console.log('we only have one promo', this.props.promos);
          let today = new Date();

          let promo = this.props.promos.filter((promo) => today < promo.promoEndDate.toDate() && promo.justForReps !== true);

          let promoIDs = [];

          promo.map((p) => promoIDs.push(p.id));

          //console.log('this is my promo', promo);
          const natCompanies = [];
          //const companyLogos = {};
          //console.log('this is my orderProgram', this.props.natProgram);
          firebase
            .firestore()
            .collection('companies')
            .where('nationalProgramID', '==', this.props.natProgram[0].id)
            .where('promos', 'array-contains-any', [...promoIDs])
            .get()
            .then((snapShot) => {
              if (!snapShot.isEmpty) {
                snapShot.forEach((company) => {
                  //console.log('this is my company -> ', company.data());
                  natCompanies.push({ id: company.id, ...company.data() });

                  //We are also going to have to get the companies logos as well
                  if (company.data().logo !== '') {
                    firebase
                      .storage()
                      .ref()
                      .child(company.data().logo)
                      .getDownloadURL()
                      .then((url) => {
                        //console.log('this is my url', url);

                        this.setState({
                          natCompanyLogos: {
                            ...this.state.natCompanyLogos,
                            [company.data().logo]: url,
                          },
                        });
                      });
                  }
                });

                this.setState({
                  natCompanies: [...natCompanies],
                  loading: false,
                });
                //console.log('this is my nate comapny listing', natCompanies);
              }
            });
        }
      }
    }
  }

  findUser = () => {
    this.props.registerLoggedinUsers(this.props.auth);
  };

  linkClinicToMyPromo = (companyID, company) => {
    this.props.addClinicToMyPromo({ userID: this.props.auth.uid, promoID: this.props.promoID, clinicID: companyID, user: this.props.user, company: company });
  };

  getAPromoCode = (promoID, promoTech, promo, company) => {
    /* console.log('this is my company', this.props.company[0].id);
    console.log('this is my promo ID', promoID); */
    this.props.getPromoCodeWithClinic({ userID: this.props.auth.uid, promoID: promoID, clinicID: company.id, promoTech: promoTech, user: this.props.user, company: company, promo: promo });
    this.props.history.push(ROUTES.LANDING);
  };

  render() {
    const {
      //pagePromo
      loading,
      //companyEnrolled,
      natCompanies,
      //natCompanyLogos,
    } = this.state;

    const {
      auth,
      t,
      classes, //companyError, companyMessage,
      user,
      //company,
      promoID,
      //companyErrorPopUp, companyPopUp,
      userChecked,
      //companyLogo,
      promos,
      match,
      natProgram,
    } = this.props;
    //console.log('this is my props', this.props);
    let lang = this.props.i18n.language;

    //console.log('is my company enrolled', companyEnrolled);
    //console.log('this is my auth', auth);
    //console.log('this is my user', user);

    /* console.log('this is my nat program', natProgram);
    console.log('this is my nat companies', natCompanies);
    console.log('this is my nat logos', natCompanyLogos);
 */
    if (user.isLoaded && !user.isEmpty) {
      if (user.promos[promoID]) {
        //console.log('I am in this promo');
        if (user.promos[promoID].clinic) {
          return <Redirect to={ROUTES.LANDING} />;
        }
      }
    }

    if (natProgram && !loading) {
      if (user.isLoaded && user.isEmpty && auth.uid) {
        if (!userChecked) {
          this.findUser();
        } else {
          return (
            <div className='meta-react'>
              <LogoHeader t={t} />
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <div className={classes.root}>
                      <Grid container spacing={3} justifyContent='center'>
                        <Grid item xs={12} sm={6} align='center'>
                          <Typography variant='h6'>{t('home.clinicUserLoggedIn')}</Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        }

        return (
          <div className='meta-react'>
            <LogoHeader t={t} />
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <div className={classes.root}>
                    <Grid container spacing={3} justifyContent='center'>
                      <Grid item xs={12} align='center'>
                        <LoadingBlock />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        return (
          <div className='meta-react'>
            <LogoHeader t={t} />
            <div className='container'>
              <div className='row'>
                <div className='col-12'>
                  <div className={classes.root}>
                    <Grid container spacing={3} justifyContent='center' alignItems='center'>
                      {/*  <Grid item xs={4} sm={2}>
                        {companyLogo ? <img src={companyLogo === 'default' ? defaultClinic : companyLogo} alt={company[0].name} className={classes.image} /> : <CircularProgress />}
                      </Grid> */}
                      <Grid item xs={12} sm={6}>
                        <Typography variant='h3' className={classes.customerName} gutterBottom>
                          {natProgram[0].name}
                        </Typography>
                        <Typography variant='h6'>{natCompanies.length} Locations across the country</Typography>
                        <Typography variant='body1'>Scroll down to find a location that is closest to you</Typography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <Grid container spacing={1} alignItems='center' justifyContent='center'>
                          <Grid item xs={3}>
                            <div className={classes.logoBlock}>
                              <Link to={match.params.id === '6973' ? ROUTES.ABOUT_THERMAGE : ROUTES.ABOUT} target='_blank' className={classes.purple}>
                                <img src={match.params.id === '6973' ? ThermageLogo : CBLogo} alt='Solta' className={classes.logo} />
                                <HelpOutlineIcon className={classes.questionMark} />
                              </Link>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <CompanyEnrolledBlock t={t} auth={auth} company={natCompanies} props={this.props} classes={classes} linkClinicToMyPromo={this.linkClinicToMyPromo} promo={promos} user={user} getAPromoCode={this.getAPromoCode} lang={lang} clinicPID={this.props.match.params.id} match={match} />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    } else {
      return (
        <div className='meta-react'>
          <LogoHeader t={t} />
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className={classes.root}>
                  <Grid container spacing={3} justifyContent='center'>
                    <Grid item xs={12} align='center'>
                      <LoadingBlock />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const CompanyEnrolledBlock = ({ auth, company, t, props, classes, linkClinicToMyPromo, promo, user, getAPromoCode, lang, clinicPID, match }) => {
  if (auth.uid) {
    if (user.isLoaded) {
      //Go throught the company signed up promos
      //If the user isn't signed up list the promo
      //Remove the select button to be that of showing the promos
      //One get code automatically sign up the clinic as the users profile
      return (
        <Grid item container spacing={3}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} align='center'>
            {company ? company.map((c) => <ClinicInfoBlock company={c} t={t} key={c.id} classes={classes} linkClinicToMyPromo={linkClinicToMyPromo} promos={promo} user={user} getAPromoCode={getAPromoCode} lang={lang} clinicPID={clinicPID} match={match} />) : ''}
          </Grid>
        </Grid>
      );
    } else return <LoadingBlock />;
  } else {
    return (
      <Grid item container spacing={3}>
        <Grid item xs={12} align='center' container spacing={2} justifyContent='center'>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} align='center'>
            <img src={NBAward} alt='New Beauty Award' style={{ height: 200 }} />
            <Typography variant='body1' style={{ textTransform: 'uppercase', fontSize: 18, marginTop: 10, color: '#293b59' }}>
              {t('signUp.bestLaser')}
            </Typography>
          </Grid>
          <Grid item xs={6} align='center'>
            <Typography variant='h5' align='center'>
              {t('signUp.cbtitle')}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} align='center'>
          {t('clinic.noAccount')}
        </Grid>
        <Grid item xs={12} align='center'>
          <Button variant='contained' size='large' component={Link} to={ROUTES.SIGN_UP + '/' + props.match.params.id}>
            {t('signIn.register')}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid item container spacing={2} alignItems='center' justifyContent='center'>
            <Grid item xs={4}>
              <Divider />
            </Grid>
            <Grid item xs={4} align='center'>
              <Typography variant='h6'>{t('signIn.or')}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} align='center'>
          <Typography variant='body1'>{t('clinic.pleaseLogin')}</Typography>
        </Grid>
        <Grid item xs={12} align='center'>
          <Button variant='contained' component={Link} to={ROUTES.LOGIN + '/' + props.match.params.id}>
            {t('signIn.signInButton')}
          </Button>
        </Grid>
      </Grid>
    );
  }
};

/* const CompanyNotEnrolledBlock = ({ auth, company, t }) => {
  return (
    <Grid item container spacing={3}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} align='center'>
        <Typography variant='body1'>{t('clinic.clinicNotEnrolled')}</Typography>
      </Grid>
    </Grid>
  );
};
 */

const ClinicInfoBlock = ({ company, t, classes, linkClinicToMyPromo, promos, user, getAPromoCode, lang, clinicPID, match }) => {
  //In here I will need all of the promos
  // I will need to loop through the promos thatt the clinic is signed up for
  // I will also need the users promos to see of they have a code already
  ///console.log('this is my company', company);
  //console.log('this is my promos', promos);
  //console.log('this is my user', user);

  return (
    <div className={classes.root}>
      <Grid container spacing={3} justifyContent='center' alignItems='center'>
        <Grid item xs={6}>
          <Grid container item spacing={2}>
            <Grid item sm={12} xs={12} className={classes.mobileCenter}>
              <Typography variant='h6'>{company.name}</Typography>
            </Grid>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.location')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                {company.location.streetAddress}
                {company.location.streetAddress2}
              </Typography>
            </Grid>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.city')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>{company.location.city}</Typography>
            </Grid>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.phone')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <a href={`tel://${company.phoneNumber}`} target='_blank' rel='noopener noreferrer'>
                  {company.phoneNumber}
                </a>
              </Typography>
            </Grid>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.email')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <a href={`mailto:${company.email}`} target='_blank' rel='noopener noreferrer'>
                  {company.email}
                </a>
              </Typography>
            </Grid>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.website')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <a href={company.website} target='_blank' rel='noopener noreferrer'>
                  {company.website}
                </a>
              </Typography>
            </Grid>
            <Grid item xs={5}></Grid>
          </Grid>
        </Grid>
        <Grid item xs={6} align='center'>
          {company.promos.map((p) => (
            <GetPromoCode t={t} promos={promos} getAPromoCode={getAPromoCode} key={p} promoID={p} lang={lang} company={company} />
          ))}
        </Grid>
        <Grid item xs={12} style={{ marginTop: 10, marginBottom: 10 }}>
          <Divider />
        </Grid>
      </Grid>
    </div>
  );
};

const GetPromoCode = ({ t, promos, getAPromoCode, promoID, lang, company }) => {
  let p = promos.filter((p) => p.id === promoID);
  let today = new Date();
  //console.log('this is my p', p[0]);
  //console.log('this is my company', company);
  //console.log('this is my clinic p id', clinicPID);
  if (p[0].promoEndDate.toDate() >= today) {
    return (
      <Grid item md={12} xs={12} key={p[0].id}>
        <Grid item xs container spacing={2} justifyContent='center' alignItems='center'>
          <Grid item xs={12} align='center'>
            <Typography variant='h6'>{p[0]['name' + lang]}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Divider />
          </Grid>
          <Grid item xs={12} align='center'>
            <Typography variant='body1'>{p[0]['description' + lang]}</Typography>
          </Grid>
          <Grid item xs={12} align='center'>
            <Typography variant='body2'>{t('selectPromo.startDate')}</Typography>
            <Typography variant='body1' gutterBottom>
              {moment(p[0].promoStartDate.toDate()).format('MMMM Do Y')}
            </Typography>
          </Grid>
          <Grid item xs={12} align='center'>
            <Typography variant='body2'>{t('selectPromo.endDate')}</Typography>
            <Typography variant='body1' gutterBottom>
              {moment(p[0].promoEndDate.toDate()).format('MMMM Do Y')}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Divider />
          </Grid>
          <Grid item xs={12} align='center'>
            <ClearBrilliantButton onClick={() => getAPromoCode(p[0].id, p[0].promoTechnology, p[0], company)}>{t('selectPromo.getCode')} </ClearBrilliantButton>
          </Grid>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};

NationalCodeLandingPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {
    auth: state.firebase.auth,
    user: state.firebase.profile,
    promos: state.firestore.ordered.promos,
    natProgram: state.firestore.ordered.orderPrograms,
    selectedCompany: state.company.company,
    promoID: state.promos.promoID,
    userChecked: state.auth.userChecked,
    companyLogo: state.company.companyLogo,
    loadedLogo: state.company.loadedLogo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPromo: (promoID) => dispatch(setPromo(promoID)),
    addClinicToMyPromo: ({ userID, promoID, clinicID, user, company }) => dispatch(addClinicToMyPromo({ userID, promoID, clinicID, user, company })),
    registerLoggedinUsers: (user) => dispatch(registerLoggedinUsers(user)),
    getCompanyLogo: (url) => dispatch(getCompanyLogo(url)),
    getPromoCodeWithClinic: ({ userID, promoID, clinicID, promoTech, user, company, promo }) => dispatch(getPromoCodeWithClinic({ userID, promoID, clinicID, promoTech, user, company, promo })),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => [{ collection: 'promos' }, { collection: 'orderPrograms', where: ['code', '==', props.match.params.id] }])
)(NationalCodeLandingPage);
