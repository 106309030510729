/*****************************************************************************/
/*  File Name: promoInfo.js                                                  */
/*  Date:      Aug 12, 2021                                                  */
/*  Author:    Christopher Cressman                                          */
/*  Purpose:   This block is just used to display the promos information     */
/*                                                                           */
/*                                                                           */
/*****************************************************************************/

import React, { useState } from 'react';
//import React, { useState, useEffect } from 'react'; //useState,
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
//import { getBrandLogo } from './actions';
import styles from './styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//import Skeleton from '@material-ui/lab/Skeleton';
import Buttons from '../Buttons';
import moment from 'moment';
import Logos from '../Logos';
import firebase from 'firebase/app';

const useStyles = makeStyles((theme) => ({
  ...styles,
}));

function PromoInfo({ promo, getPromoCode, promoTechnology, props, company }) {
  //Calculates how many days are left for the promo
  const today = new Date();
  const promoEndDate = new Date(promo.promoEndDate.toDate());
  const oneDay = 1000 * 60 * 60 * 24;
  const daysLeft = Math.round((promoEndDate.getTime() - today.getTime()) / oneDay);

  const t = props.t;
  const profile = useSelector((state) => state.firebase.profile);
  const [emailSent, setEmailSent] = useState(false);

  //Load all of the default component varaibles
  const classes = useStyles();

  const sendContactEmail = async (promo) => {
    //console.log('lets send an email to the company to contact', company);
    //console.log('this is my promo --> ', promo);

    try {
      await firebase
        .firestore()
        .collection('mail')
        .add({
          cc: [profile.email, company.email],
          message: {
            subject: `Book an Appointment for ${profile.fullName}`,
            text: `Hi ${company.name}, I would like to book an appointment so that I could see if Thermage is right for me. Thanks ${profile.fullName} `,
            html: `Hi ${company.name}, I would like to book an appointment so that I could see if Thermage is right for me. Thanks ${profile.fullName} `,
          },
        });

      setEmailSent(true);
    } catch (err) {
      console.log('this is an error', err);
    }
  };

  const sendPromoCodeEmail = async (promo) => {
    //console.log('lets send an email wiht your promo code in it.', promo);
    //console.log('this is my profile', profile.promos[promo.id].promoCode);
    //console.log('this is my profile ', profile);
    try {
      await firebase
        .firestore()
        .collection('mail')
        .add({
          cc: [profile.email],
          message: {
            subject: `Your Promo Code for ${promo['name' + props.i18n.language]}`,
            text: `Hi ${profile.firstName ? profile.firstName : profile.email}, Here is your promo code for the following promotion - ${promo['name' + props.i18n.language]}:<br/><br/> ${profile.promos[promo.id].promoCode}. Please note that the clinic will need your promo code when you book your appointment.`,
            html: `Hi ${profile.firstName ? profile.firstName : profile.email},<br/><br/> Here is your promo code for the following promotion - ${promo['name' + props.i18n.language]}:<br/><br/> <b>${profile.promos[promo.id].promoCode}</b>.<br/><br/> Please note that the clinic will need your promo code when you book your appointment. `,
          },
        });

      setEmailSent(true);
    } catch (err) {
      console.log('this is an error', err);
    }
  };

  //console.log('these are a my logos', techLogos);
  //aconsole.log('this is my profile', profile);

  return (
    <Grid item xs={12} container key={promo.id} spacing={0} className={`${classes.promoBlock}`}>
      <Grid item xs={12} container alignItems='center'>
        <Grid item md={4} xs={12}>
          <Logos brand={{ brandID: promo.promoTechnology, technologies: promoTechnology }} className={classes.brandLogo} />

          {/* {techLogos[promo.promoTechnology] ? <img src={techLogos[promo.promoTechnology]} alt='Logo' className={classes.brandLogo} /> : <Skeleton variant='rect' width={100} height={100} />} */}
        </Grid>
        <Grid item md={8} xs={12} container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h5' gutterBottom className={classes[promo.promoTechnology]}>
              {promo['name' + props.i18n.language]}
            </Typography>

            <Typography variant='body1' gutterBottom>
              {promo['description' + props.i18n.language]}
            </Typography>
            <Typography variant='body2'>
              {t('selectPromo.endDate')} {moment(promo.promoEndDate.toDate()).format('MMMM Do, YYYY')}
            </Typography>
          </Grid>
          <Grid item xs={12} container>
            {profile.promos ? (
              profile.promos[promo.id] ? (
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant='body1' gutterBottom>
                      {/* Promo Code: <span className={`${classes.boldFont} ${classes[promo.promoTechnology]}`}> {profile.promos[promo.id].promoCode} </span>{' '} */}
                      Your promo code has been emailed to you.
                    </Typography>
                    <Buttons brand={{ brandID: promo.promoTechnology, technologies: promoTechnology }} onClick={() => sendPromoCodeEmail(promo)} style={{ marginTop: 10, marginBottom: 15 }}>
                      Resend Promo Code Email
                    </Buttons>
                    <Typography variant='body2' gutterBottom>
                      *Please note that the clinic will need your promo code when you book your appointment
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1'>
                      You have <span className={`${classes.boldFont} ${classes[promo.promoTechnology]}`}> {daysLeft} days</span> left to redeem your promo code.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body1' gutterBottom>
                      {t('clinicBlocks.emailSent')}
                    </Typography>
                    <Typography variant='body1'>{t('clinicBlocks.emailSentTwo')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {emailSent ? (
                      <Typography variant='body1'>An emails has been sent to the clinic and yourself.</Typography>
                    ) : (
                      <Buttons brand={{ brandID: promo.promoTechnology, technologies: promoTechnology }} onClick={() => sendContactEmail(promo)}>
                        Book Your Appointment
                      </Buttons>
                    )}
                  </Grid>
                </Grid>
              ) : (
                <GetPromoButton promo={promo} promoTechnology={promoTechnology} getPromoCode={getPromoCode} t={t} />
              )
            ) : (
              <GetPromoButton promo={promo} promoTechnology={promoTechnology} getPromoCode={getPromoCode} t={t} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const GetPromoButton = ({ promo, promoTechnology, getPromoCode, t }) => {
  return (
    <Buttons brand={{ brandID: promo.promoTechnology, technologies: promoTechnology }} onClick={() => getPromoCode(promo)}>
      {t('selectPromo.getCode')}
    </Buttons>
  );
};

export default withTranslation()(PromoInfo);
