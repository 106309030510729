export const ONTARIO = { name: 'Ontario', abbrev: 'ON' };
export const QUEBEC = { name: 'Quebec', abbrev: 'QC' };
export const BRITISH_COLUMBIA = { name: 'British Columbia', abbrev: 'BC' };
export const ALBERTA = { name: 'Alberta', abbrev: 'AB' };
export const NOVA_SCOTIA = { name: 'Nova Scotia', abbrev: 'NS' };
export const NEW_BRUNSWICK = { name: 'New Brunswick', abbrev: 'NB' };
export const MANITOBA = { name: 'Manitoba', abbrev: 'MB' };
export const PRINCE_EDWARD_ISLAND = { name: 'Prince Edward Island', abbrev: 'PE' };
export const SASKATCHEWAN = { name: 'Saskatchewan', abbrev: 'SK' };
export const NEWFOUNDLAND = { name: 'Newfoundland and Labrador', abbrev: 'NL' };

export const CANADIAN_PROVINCES = [ONTARIO, QUEBEC, BRITISH_COLUMBIA, ALBERTA, NOVA_SCOTIA, NEW_BRUNSWICK, MANITOBA, PRINCE_EDWARD_ISLAND, SASKATCHEWAN, NEWFOUNDLAND];
