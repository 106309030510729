import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { setPromo } from '../../actions/promoActions';
import LoadingBlock from '../Loading';
import { closeCompanyPopUps } from '../../actions/companyActions';
import { registerLoggedinUsers } from '../../actions/authActions';
import { getPromoCode } from '../../actions/promoActions';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';
//import ClinicBlock from '../Clinics/clinicBlock';
import ClinicBlockConst from '../Clinics/clinicBlockConst';
//import ClinicCodeBlock from '../Clinics/clinicCodeBlock';
import { ClearBrilliantButton } from '../Buttons/clearBrilliantButton';
//import SelectPromoBlock from './selectAPromo';
//import SelectDoctor from '../Clinics/selectDoctorForm';
import SelectDoctorForm from '../Clinics/selectDoctorConst';
import { findCompanyFromCompanyCode, findCompanyFromCity, loadCompany } from '../../actions/companyActions';
import ClinicCodeBlockConst from '../Clinics/clinicCodeBlockConst';
import { addClinicToMyPromo, sendContactEmail, removeClinicToMyPromo } from '../../actions/promoActions';
import { clearCompanySearch } from '../../actions/companyActions';
import Logos from '../Logos';
import Skeleton from '@material-ui/lab/Skeleton';

const styles = {
  dividerPadding: {
    marginTop: 30,
    marginBottom: 30,
  },
  mobileCenter: {
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  brandLogo: {
    maxWidth: 150,
  },
};

const INITIAL_STATE = {
  a: false,
  loading: false,
};

class ShowUsersPromosBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    this.setState({
      a: true,
    });
  }

  componentWillUnmount() {}

  componentDidUpdate() {
    //this.setState({ loading: true });
    if (!this.state.loading) {
      if (this.props.promos) {
        if (this.props.profile) {
          this.props.promos.map((p) => {
            if (this.props.profile.promos[p.id]) {
              if (this.props.profile.promos[p.id].clinic) {
                return this.props.loadCompany(this.props.profile.promos[p.id].clinic);
              } else {
                return null;
              }
            } else {
              return null;
            }
          });

          this.setState({
            loading: true,
          });
        }
      }
    }
  }

  closePopUps = () => {
    this.props.closeCompanyPopUps();
  };

  getAPromoCode = (promoID, promo) => {
    //console.log('this is my promo tech', promoTech);
    console.log('this is my promo', promo);
    this.props.getPromoCode({ userID: this.props.auth.uid, promoID: promoID, promo: promo, user: this.props.profile });
  };

  render() {
    /*
    const {
      pagePromo
      loading,
    } = this.state;
*/
    const { t, classes, profile, selectedCompany, promos, technologies } = this.props;
    let lang = this.props.i18n.language;

    //console.log('this is my promos', promos);
    //console.log('this is my technologies', technologies);

    if (profile && promos) {
      return (
        <Grid item xs container spacing={3} alignContent='center' justifyContent='center'>
          {promos.map((p) =>
            p.id !== '8pH8XQUL4SbOUIUvVlWg' ? (
              profile.promos[p.id] ? (
                profile.promos[p.id].clinic ? (
                  <ClinicBlockConst key={p.id} props={this.props} promoID={p.id} promo={p} />
                ) : selectedCompany ? (
                  <ClinicCodeBlockConst key={p.id} props={this.props} promoID={p.id} promo={p} />
                ) : (
                  <Grid item xs container spacing={3} key={p.id}>
                    <Grid item xs={12}>
                      {technologies ? <Logos brand={{ brandID: p.promoTechnology, technologies: technologies }} className={classes.brandLogo} /> : <Skeleton variant='rect' width={100} height={100} />}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='h6'>{p['name' + lang]}</Typography>
                      <Typography variant='body1' className={classes.mobileCenter}>
                        {t('home.customerNameSecondCopy')}
                      </Typography>
                      <Typography variant='h5' className={classes.mobileCenter}>
                        Your promo code has been emailed to you
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <SelectDoctorForm props={this.props} promoID={p.id} />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider className={classes.dividerPadding} />
                    </Grid>
                  </Grid>
                )
              ) : (
                <Grid item xs={12} container spacing={3} key={p.id}>
                  <GetPromoCode promos={promos} t={t} p={p} key={p.id} getAPromoCode={() => this.getAPromoCode(p.id, p)} lang={lang} technologies={technologies} classes={classes} />
                </Grid>
              )
            ) : null
          )}
        </Grid>
      );
    } else {
      return (
        <Grid container spacing={3} justifyContent='center'>
          <Grid item xs={12} align='center'>
            <LoadingBlock />
          </Grid>
        </Grid>
      );
    }
  }
}

const GetPromoCode = ({ promos, t, p, getAPromoCode, lang, technologies, classes }) => {
  let today = new Date();
  //console.log('this is my p', p);
  if (p.promoEndDate.toDate() >= today && p.promoStartDate.toDate() <= today) {
    if (p.clinicPromo !== true) {
      return (
        <Grid item md={4} xs={12} key={p.id}>
          <Grid item xs container spacing={2} justifyContent='center' alignItems='center'>
            <Grid item xs={12} align='center'>
              {technologies ? <Logos brand={{ brandID: p.promoTechnology, technologies: technologies }} className={classes.brandLogo} /> : <Skeleton variant='rect' width={100} height={100} />}
            </Grid>
            <Grid item xs={12} align='center'>
              <Typography variant='h6'>{p['name' + lang]}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Divider />
            </Grid>
            <Grid item xs={12} align='center'>
              <Typography variant='body1'>{p['description' + lang]}</Typography>
            </Grid>
            <Grid item xs={12} align='center'>
              <ClearBrilliantButton onClick={() => getAPromoCode(p.id, p)}>{t('selectPromo.getCode')} </ClearBrilliantButton>
            </Grid>
            <Grid item xs={4}>
              <Divider />
            </Grid>
            <Grid item xs={12} align='center'>
              <Typography variant='body2'>{t('selectPromo.redeemCode')}</Typography>
              <Typography variant='body1' gutterBottom>
                {moment(p.promoEndDate.toDate()).format('MMMM Do, Y')}
              </Typography>
            </Grid>

            <Grid item xs={12} align='center'>
              <Typography variant='body1' gutterBottom>
                {t('selectPromo.followUp', { date: moment(p.promoEndDate.toDate()).format('MMMM Do, Y') })}
              </Typography>
              <Typography variant='body2' gutterBottom>
                {t('selectPromo.finePrint')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

ShowUsersPromosBlock.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    selectedCompany: state.company.company,
    promos: state.firestore.ordered.promos,
    company: state.company.company,
    promoClinics: state.company.promoClinics,
    technologies: state.firestore.ordered.technologies,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPromo: (promoID) => dispatch(setPromo(promoID)),
    closeCompanyPopUps: () => dispatch(closeCompanyPopUps()),
    registerLoggedinUsers: (user) => dispatch(registerLoggedinUsers(user)),
    getPromoCode: ({ userID, promoID, promo, user }) => dispatch(getPromoCode({ userID, promoID, promo, user })),
    findCompanyFromCompanyCode: ({ code, promo }) => dispatch(findCompanyFromCompanyCode({ code, promo })),
    findCompanyFromCity: ({ city, promo, user }) => dispatch(findCompanyFromCity({ city, promo, user })),
    addClinicToMyPromo: ({ userID, promoID, clinicID, user, company, promo }) => dispatch(addClinicToMyPromo({ userID, promoID, clinicID, user, company, promo })),
    clearCompanySearch: () => dispatch(clearCompanySearch()),
    sendContactEmail: ({ userID, promoID, clinicID, user, company }) => dispatch(sendContactEmail({ userID, promoID, clinicID, user, company })),
    removeClinicToMyPromo: ({ userID, promoID }) => dispatch(removeClinicToMyPromo({ userID, promoID })),
    loadCompany: (clinicID) => dispatch(loadCompany(clinicID)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => [{ collection: 'promos', where: ['justForReps', '==', false] }, { collection: 'technologies' }])
)(ShowUsersPromosBlock);
