import Button from '@material-ui/core/Button';
import { blue } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

export const ClearBrilliantButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    paddingLeft: 20,
    paddingRight: 20,
    '&:hover': {
      backgroundColor: blue[700],
    },
    '&:active': {
      backgroundColor: blue[700],
    },
    '&:focus': {
      backgroundColor: blue[700],
    },
  },
}))(Button);
