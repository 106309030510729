import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//import ThermageLogo from '../../images/thermage_eyes.svg';
//import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
//import Divider from '@material-ui/core/Divider';
import LogoHeader from '../Header';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = {
  root: {
    flexGrow: 1,
    whiteSpace: 'pre-line',
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  customerNameCNB: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    color: '#0080B2',
    fontSize: 48,
    '@media (max-width: 1025px)': {
      fontSize: 28,
    },
    '@media (max-width: 599px)': {
      //textAlign: 'center',
    },
  },
  customerName: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    color: '#9b3e96',
    fontSize: 48,
    '@media (max-width: 1025px)': {
      fontSize: 28,
    },
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  logo: {
    marginTop: 30,
    width: 155,
    display: 'block',
    '@media (max-width: 1025px)': {
      width: 135,
      marginLeft: -40,
      marginTop: 0,
    },
  },
  alignCenter: {
    textAlign: 'center',
  },
  questionMark: {
    right: -50,
    display: 'block',
    position: 'absolute',
    top: 0,
    '@media (max-width: 1440px)': {
      right: -70,
    },
    '@media (max-width: 1300px)': {
      right: -85,
    },
    '@media (max-width: 1025px)': {
      right: -30,
    },
    '@media (max-width: 850px)': {
      right: -50,
    },
    '@media (max-width: 599px)': {
      right: -30,
    },
  },
  logoBlock: {
    position: 'relative',
    display: 'block',
  },
  mobileCenter: {
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
};

const INITIAL_STATE = {
  loading: true,
};

class FaqPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate() {}

  render() {
    const { t, classes } = this.props;

    return (
      <div className='meta-react'>
        <LogoHeader t={t} />
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant='h3' className={classes.customerNameCNB}>
                      FAQ
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls='faq1a-content' id='faq1a-header'>
                        <Typography className={classes.heading}>{t('faq.header.pointOne')}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>{t('faq.body.pointOne')}</Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls='faq2a-content' id='faq2a-header'>
                        <Typography className={classes.heading}>{t('faq.header.pointTwo')}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>{t('faq.body.pointTwo')}</Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls='faq3a-content' id='faq3a-header'>
                        <Typography className={classes.heading}>{t('faq.header.pointThree')}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>{t('faq.body.pointThree')}</Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls='faq4a-content' id='faq4a-header'>
                        <Typography className={classes.heading}>{t('faq.header.pointFour')}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>{t('faq.body.pointFour')}</Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls='faq5a-content' id='faq5a-header'>
                        <Typography className={classes.heading}>{t('faq.header.pointFive')}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>{t('faq.body.pointFive')}</Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls='faq6a-content' id='faq6a-header'>
                        <Typography className={classes.heading}>{t('faq.header.pointSix')}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>{t('faq.body.pointSix')}</Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls='faq7a-content' id='faq7a-header'>
                        <Typography className={classes.heading}>{t('faq.header.pointSeven')}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>{t('faq.body.pointSeven')}</Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls='faq8a-content' id='faq8a-header'>
                        <Typography className={classes.heading}>{t('faq.header.pointEight')}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>{t('faq.body.pointEight')}</Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls='faq9a-content' id='faq9a-header'>
                        <Typography className={classes.heading}>{t('faq.header.pointNine')}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>{t('faq.body.pointNine')}</Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} aria-controls='faq10a-content' id='faq10a-header'>
                        <Typography className={classes.heading}>{t('faq.header.pointTen')}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>{t('faq.body.pointTen')}</Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FaqPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {
    //auth: state.firebase.auth,
    //user: state.firebase.profile,
    //promos: state.firestore.ordered.promos,
    //companyError: state.company.companyError,
    //companyMessage: state.company.companyMessage,
    //selectedCompany: state.company.company,
    //promoID: state.promos.promoID,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //setPromo: (promoID) => dispatch(setPromo(promoID)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => [])
)(FaqPage);
