/*****************************************************************************/
/*  File Name: signIn.js                                                     */
/*  Date:      Aug 16, 2021                                                  */
/*  Author:    Christopher Cressman                                          */
/*  Purpose:   This is the sign in components                                */
/*                                                                           */
/*****************************************************************************/

import React, { useState, useEffect } from 'react'; //useState,
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../styles';
import Grid from '@material-ui/core/Grid';
//import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';

import * as ROUTES from '../../../constants/routes';
import Divider from '@material-ui/core/Divider';
import GoogleLogo from '../../../images/icons/GoogleLogo';
import Typography from '@material-ui/core/Typography';
import firebase from 'firebase/app';
import moment from 'moment';

//import { SoltaButton } from '../../Buttons/soltaButton';

const useStyles = makeStyles((theme) => ({
  ...styles,
}));

function SignIn({ company, props, promo, setShowLogin }) {
  const t = props.t;
  const classes = useStyles();

  const profile = useSelector((state) => state.firebase.profile);
  const auth = useSelector((state) => state.firebase.auth);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    async function getPromoCode({ profile, auth, company, promo }) {
      //console.log('We have logged in is this working ? ');

      firebase.analytics().logEvent('get_promo_code', { name: 'getCode', user: auth.uid, promo: promo.id });

      //Lets get all of the codes
      let promoCodeResp = await firebase.firestore().collection('promoCodes').doc('freeCodes').get();

      //Lets get the first code
      const promoCode = promoCodeResp.data().codes[0];

      //This is to add the promo in
      const promoPath = 'promos.' + promo.id;

      //Add the promo code to the new user
      await firebase
        .firestore()
        .collection('consumer')
        .doc(auth.uid)
        .update({
          [promoPath]: {
            promoCode: promoCode,
            brand: promo.promoTechnology,
            promoCodeClaimed: false,
            clinic: company.id,
          },
        });

      //Now we need to filter out the used promo code
      const unusedPromoCodes = promoCodeResp.data().codes.filter((code) => code !== promoCode);

      //Add the updated list of codes
      await firebase.firestore().collection('promoCodes').doc('freeCodes').set({
        codes: unusedPromoCodes,
      });

      await firebase
        .firestore()
        .collection('promoCodes')
        .doc('promoCodesLeft')
        .update({
          count: firebase.firestore.FieldValue.increment(-1),
        });

      await firebase
        .firestore()
        .collection('promoCodes')
        .doc('usedCodes')
        .update({
          codes: firebase.firestore.FieldValue.arrayUnion(promoCode),
        });

      await firebase
        .firestore()
        .collection('mail')
        .add({
          cc: [profile.email],
          message: {
            subject: `Your Promo Code for ${promo['name' + props.i18n.language]}`,
            text: `Hi ${profile.firstName ? profile.firstName : profile.email}, Here is your promo code for the following promotion - ${promo['name' + props.i18n.language]}:<br/><br/> ${promoCode}. Please note that the clinic will need your promo code when you book your appointment.`,
            html: `Hi ${profile.firstName ? profile.firstName : profile.email},<br/><br/> Here is your promo code for the following promotion - ${promo['name' + props.i18n.language]}:<br/><br/> <b>${promoCode}</b>.<br/><br/> Please note that the clinic will need your promo code when you book your appointment. `,
          },
        });

      //Now lets send out an email
      await firebase
        .firestore()
        .collection('mail')
        .add({
          cc: [profile.email, company.email],
          message: {
            subject: 'You have linked your promo with a clinic',
            text: `Congratulations ${profile.firstName ? profile.firstName : profile.email}, you have linked your ${promo.nameen} with the following clinic: ${company.name}. You can claim your promo by booking an appointment by ${moment(promo.promoEndDate.toDate()).format(
              'MMMM Do, Y'
            )} with the clinic. We have included the clinic in this email to help you book an appointment. `,
            html: `Congratulations ${profile.firstName ? profile.firstName : profile.email},<br/><br/> You have linked your ${promo.nameen} with the following clinic:<b> ${company.name}</b>. <br/><br/> You can claim your promo by booking an appointment by<b> ${moment(promo.promoEndDate.toDate()).format(
              'MMMM Do, Y'
            )}</b> with the clinic.<br/><br/> We have included the clinic in this email to help you book an appointment.<br/><br/> Thank you for signing up with The Solta Advantage Program. `,
          },
        });

      setShowLogin(false);
    }

    //If the profile is loaded then lets call this function
    //console.log('this is my profile before the if statement ---> ', profile);
    if (profile.isLoaded && !profile.isEmpty) {
      //console.log('this is my profile in the effect ---> ', profile);
      if (profile.promos) {
        if (!profile.promos[promo.id]) {
          getPromoCode({ auth: auth, profile: profile, company: company, promo: promo });
        }
      } else {
        //console.log('we can get a promo code');
        getPromoCode({ auth: auth, profile: profile, company: company, promo: promo });
      }
    }
  }, [auth, profile, company, promo, setShowLogin, props.i18n.language]);

  const onSubmit = async (event) => {
    event.preventDefault();

    firebase.analytics().logEvent('login', { method: 'Email' });

    await firebase.auth().signInWithEmailAndPassword(email, password);
  };

  /* 
  const loginFacebook = () => {
    this.props.signInFacebook();
  };
 */
  const loginGoogle = async () => {
    //console.log('this is my loging in with google');
    firebase.analytics().logEvent('login', { method: 'Google' });
    let provider = new firebase.auth.GoogleAuthProvider();

    await firebase.auth().signInWithPopup(provider);
  };

  const isInvalid = password === '' || email === '';

  return (
    <Grid container spacing={3} justifyContent='center'>
      <Grid item md={12} xs={12}>
        <form onSubmit={onSubmit}>
          <Grid item container spacing={3} justifyContent='center'>
            <Grid item xs={12}>
              <TextField name='email' className={classes.textField} value={email} onChange={(event) => setEmail(event.target.value)} type='text' label={t('signIn.emailAddress')} />
            </Grid>
            <Grid item xs={12}>
              <TextField name='password' className={classes.textField} value={password} onChange={(event) => setPassword(event.target.value)} type='password' label={t('signIn.password')} />
            </Grid>
            <Grid item xs={12} align='center'>
              <Button disabled={isInvalid} type='submit' variant='contained' onClick={onSubmit}>
                {t('signIn.signInButton')}
              </Button>
            </Grid>
            <Grid item xs={12} align='center'>
              <Button component={Link} to={ROUTES.FORGOTTEN_PASSWORD} className={classes.forgot}>
                {t('signIn.forgotPassword')}
              </Button>
            </Grid>
            <Grid item container spacing={2} xs={12} alignContent='center' alignItems='center'>
              <Grid item xs={4}>
                <Divider />
              </Grid>
              <Grid item xs={4} align='center'>
                <Typography variant='h6'>{t('signIn.or')}</Typography>
              </Grid>

              <Grid item xs={4}>
                <Divider />
              </Grid>
            </Grid>
            <Grid item xs={12} align='center'>
              <Button variant='contained' size='large' onClick={loginGoogle} startIcon={<GoogleLogo />}>
                {t('signIn.signInGoogle')}
              </Button>
            </Grid>
            {/*}
                        <Grid item xs={12}>
                          <Button variant='contained' size='large' className={classes.button} onClick={this.loginFacebook} startIcon={<FacebookIcon />}>
                            {t('signIn.signInFacebook')}
                          </Button>
                        </Grid>

      */}
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}

export default withTranslation()(SignIn);
