import Button from '@material-ui/core/Button';
import { soltaColor } from '../Styles/colors';
//import { teal } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

console.log('this is my soltaColor', soltaColor);

export const SoltaButton = withStyles((theme) => ({
  root: {
    //color: theme.palette.getContrastText(teal[300]),
    color: '#fff',
    backgroundColor: soltaColor,
    paddingLeft: 20,
    paddingRight: 20,
    '&:hover': {
      backgroundColor: soltaColor,
    },
    '&:active': {
      backgroundColor: soltaColor,
    },
    '&:focus': {
      backgroundColor: soltaColor,
    },
    '&:disabled': {
      backgroundColor: '#fff',
    },
  },
}))(Button);
