import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { ClearBrilliantButton } from '../Buttons/clearBrilliantButton';
import LoadingBlock from '../Loading';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },

  alignCenter: {
    textAlign: 'center',
  },
  mobileCenter: {
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  orSpacing: {
    marginTop: 30,
  },
  purple: {
    color: '#9b3e96',
  },
  blue: {
    color: 'rgba(40,58,89,1)',
  },
  spacingTop: {
    marginTop: 30,
  },
}));

const ClinicCodeBlockConst = ({ props, promoID, promo }) => {
  const classes = useStyles();
  const linkClinicToMyPromo = (companyID, company) => {
    props.addClinicToMyPromo({ userID: props.auth.uid, promoID: promoID, clinicID: companyID, user: props.profile, company: company, promo: promo });
  };

  const clearSearch = () => {
    props.clearCompanySearch();
  };

  //console.log('this is company details', company)

  const { t, company } = props;
  //console.log('these are my clinics', company);
  //console.log('this is my company', company);
  //console.log('this is my user', this.props.user);

  if (company) {
    return (
      <div>
        <Grid container spacing={3} justifyContent='center' alignItems='center'>
          <Grid item sm={4} xs={2}>
            <Divider />
          </Grid>
          <Grid item sm={4} xs={8} align='center'>
            <Typography variant='h6'>{t('clinicBlocks.clinicsFound', { number: company.length ? company.length : 1 })}</Typography>
          </Grid>
          <Grid item sm={4} xs={2}>
            <Divider />
          </Grid>
        </Grid>
        <ClinicBlock companies={company} classes={classes} t={t} linkClinicToMyPromo={linkClinicToMyPromo} />

        <Grid item xs={12} className={classes.orSpacing}>
          <Grid item container spacing={3} justifyContent='center' alignItems='center'>
            <Grid item xs={2}>
              <Divider />
            </Grid>
            <Grid item xs={4} align='center'>
              {t('signIn.or')}
            </Grid>
            <Grid item xs={2}>
              <Divider />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} align='center' className={classes.orSpacing}>
          <Button onClick={clearSearch}>{t('clinicBlocks.searchForAnother')}</Button>
        </Grid>
      </div>
    );
  } else {
    return <LoadingBlock />;
  }
};

const ClinicBlock = ({ companies, classes, t, linkClinicToMyPromo }) => {
  //console.log('this is my company length', companies.length);

  if (companies.length) {
    let block = companies.map((c) => {
      //console.log('this is my c', c);
      return (
        <Grid container spacing={3} justifyContent='center' key={c.id}>
          <Grid item xs={12} align='center' className={classes.spacingTop}>
            <Typography variant='h4' className={classes.blue}>
              {c.data.name}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid container item spacing={2}>
              <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>
                  <b>{t('clinicBlocks.location')}</b>
                </Typography>
              </Grid>
              <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>
                  {c.data.location.streetAddress}
                  {c.data.location.streetAddress2}
                </Typography>
              </Grid>
              <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>
                  <b>{t('clinicBlocks.city')}</b>
                </Typography>
              </Grid>
              <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>{c.data.location.city}</Typography>
              </Grid>
              <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>
                  <b>{t('clinicBlocks.phone')}</b>
                </Typography>
              </Grid>
              <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>
                  <a href={`tel://${c.data.phoneNumber}`} target='_blank' rel='noopener noreferrer'>
                    {c.data.phoneNumber}
                  </a>
                </Typography>
              </Grid>
              <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>
                  <b>{t('clinicBlocks.email')}</b>
                </Typography>
              </Grid>
              <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>
                  <a href={`mailto:${c.data.email}`} target='_blank' rel='noopener noreferrer'>
                    {c.data.email}
                  </a>
                </Typography>
              </Grid>
              <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>
                  <b>{t('clinicBlocks.website')}</b>
                </Typography>
              </Grid>
              <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                <Typography variant='body1'>
                  <a href={`${c.data.website}`} target='_blank' rel='noopener noreferrer'>
                    {c.data.website}
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={5}></Grid>
              <Grid item xs={2} align='center' className={classes.spacingTop}>
                <Divider />
              </Grid>
              <Grid item xs={12} align='center'>
                <ClearBrilliantButton variant='contained' color='primary' onClick={(e) => linkClinicToMyPromo(c.id, c)} className={classes.margin}>
                  {t('clinicBlocks.select')}
                </ClearBrilliantButton>
              </Grid>
              <Grid item xs={12} className={classes.spacingTop}>
                <Divider />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    });

    //console.log('this is my block', block);
    return block;
  } else {
    return (
      <Grid container spacing={3} justifyContent='center' key={companies.id}>
        <Grid item xs={12} align='center'>
          <Typography variant='h4' className={classes.blue}>
            {companies.data.name}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Grid container item spacing={2}>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.location')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                {companies.data.location.streetAddress}
                {companies.data.location.streetAddress2}
              </Typography>
            </Grid>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.city')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>{companies.data.location.city}</Typography>
            </Grid>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.phone')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <a href={`tel://${companies.data.phoneNumber}`} target='_blank' rel='noopener noreferrer'>
                  {companies.data.phoneNumber}
                </a>
              </Typography>
            </Grid>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.email')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <a href={`mailto:${companies.data.email}`} target='_blank' rel='noopener noreferrer'>
                  {companies.data.email}
                </a>
              </Typography>
            </Grid>
            <Grid item sm={2} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <b>{t('clinicBlocks.website')}</b>
              </Typography>
            </Grid>
            <Grid item sm={10} xs={12} className={classes.mobileCenter}>
              <Typography variant='body1'>
                <a href={`${companies.data.website}`} target='_blank' rel='noopener noreferrer'>
                  {companies.data.website}
                </a>
              </Typography>
            </Grid>
            <Grid item xs={5}></Grid>
            <Grid item xs={2} align='center'>
              <Divider />
            </Grid>
            <Grid item xs={12} align='center'>
              <ClearBrilliantButton variant='contained' color='primary' onClick={(e) => linkClinicToMyPromo(companies.id, companies)} className={classes.margin}>
                {t('clinicBlocks.select')}
              </ClearBrilliantButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
};

export default ClinicCodeBlockConst;
