const initState = {
  promoError: null,
  promoMessage: null,
  promoPopUp: false,
  promoErrorPopUp: false,
  promoID: '',
};

const promoReducer = (state = initState, action) => {
  switch (action.type) {
    case 'PROMO_ADDED':
      //console.log('promo has been added')
      return {
        ...state,
        promoError: null,
      };
    case 'SET_PROMO_CODE':
      //console.log('promo has been added', action.promoID)
      return {
        ...state,
        promoID: action.promoID,
      };
    case 'CONTACT_EMAIL_SENT':
      return {
        ...state,
        promoMessage: 'Email has been sent to the clinic',
        promoPopUp: true,
      };
    case 'ERROR_SENDING_CONTACT_EMAIL':
      return {
        ...state,
        promoError: action.err.message,
        promoErrorPopUp: true,
      };
    case 'CLOSE_PROMO_POPUPS':
      return {
        ...state,
        promoError: null,
        promoErrorPopUp: false,
        promoMessage: null,
        promoPopUp: false,
      };
    default:
      return state;
  }
};

export default promoReducer;
