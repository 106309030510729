//import { firestoreConnect } from 'react-redux-firebase';
//import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
//import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
//import { findCompanyFromCompanyCode, findCompanyFromCity } from '../../actions/companyActions';
import { ClearBrilliantButton } from '../Buttons/clearBrilliantButton';
import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';
import firebase from 'firebase/app';
import LoadingBlock from '../Loading';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  alignCenter: {
    textAlign: 'center',
  },
  textField: {
    width: '100%',
  },
  mobileCenter: {
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
}));

const SelectDoctorForm = ({ props, promoID }) => {
  const classes = useStyles();
  const [city, setCity] = useState('');
  const [clinicCode, setClinicCode] = useState('');
  //const [siteSettings, setSiteSettings] = useState({});
  const [loading, setLoading] = useState(true);
  const [userLockedDown, setLockDown] = useState(false);

  const { t, profile } = props;

  const changeCity = (event) => {
    setCity(event.target.value);
  };

  const isUserinLockDown = (settings) => {
    if (profile.province) {
      const userProv = profile.province;
      if (settings.lockDownProv.includes(userProv)) {
        setLockDown(true);
      }
    }
  };

  useEffect(() => {
    firebase
      .firestore()
      .collection('settings')
      .doc('promoSite')
      .get()
      .then((snapShot) => {
        if (snapShot.exists) {
          //setSiteSettings(snapShot.data());
          isUserinLockDown(snapShot.data());
        } else {
          setLockDown(false);
        }
        //console.log('this is my snapshot', snapShot);
        setLoading(false);
      });
  });

  const changeClinicCode = (event) => {
    setClinicCode(event.target.value);
  };

  const searchforClinicByCity = (event) => {
    event.preventDefault();
    console.log('this is my profile', props.profile);
    props.findCompanyFromCity({ city: city, promo: promoID, user: props.profile });
  };

  const searchForClinicByClinicCode = (event) => {
    event.preventDefault();
    props.findCompanyFromCompanyCode({ code: clinicCode, promo: promoID });
  };
  if (!loading) {
    if (!userLockedDown) {
      return (
        <div className={classes.root}>
          <Grid container spacing={4} alignItems='center' justifyContent='center'>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems='center' justifyContent='center'>
                <Grid item xs={12}>
                  <Typography variant='h6' align='center'>
                    {t('home.pleaseSelectTwo')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item container spacing={2}>
                <Grid item xs={12} sm={5}>
                  <form onSubmit={searchforClinicByCity}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='h6' className={classes.mobileCenter}>
                          {t('home.findaClinicTitle')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField id='city' className={classes.textField} name='city' value={city} onChange={changeCity} label={t('home.citySearch')} />
                      </Grid>
                      <Grid item xs={12} className={classes.mobileCenter}>
                        <ClearBrilliantButton variant='contained' color='primary' className={classes.margin} onClick={searchforClinicByCity}>
                          {t('buttons.find')}
                        </ClearBrilliantButton>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
                <Hidden xsDown>
                  <Grid item sm={2} align='center'>
                    <Divider orientation='vertical' />
                  </Grid>
                </Hidden>
                <Hidden smUp>
                  <Grid item container spacing={2} xs={12} alignContent='center' alignItems='center'>
                    <Grid item xs={4}>
                      <Divider />
                    </Grid>
                    <Grid item xs={4} align='center'>
                      <Typography variant='h6'>{t('signIn.or')}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Hidden>

                <Grid item xs={12} sm={5}>
                  <form onSubmit={searchForClinicByClinicCode}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant='h6' className={classes.mobileCenter}>
                          {t('home.clinicCodeTitle')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField id='clinicCode' className={classes.textField} name='clinicCode' value={clinicCode} onChange={changeClinicCode} label={t('home.clinicCodeSearch')} />
                      </Grid>
                      <Grid item xs={12} className={classes.mobileCenter}>
                        <ClearBrilliantButton variant='contained' color='primary' onClick={searchForClinicByClinicCode} className={classes.margin}>
                          {t('buttons.start')}
                        </ClearBrilliantButton>
                      </Grid>
                    </Grid>
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return (
        <div className={classes.root}>
          <Grid container spacing={4} alignItems='center' justifyContent='center'>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems='center' justifyContent='center'>
                <Grid item xs={12}>
                  <Typography variant='h6' align='center'>
                    {t('home.provinceLockDown')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    }
  } else {
    return (
      <div className={classes.root}>
        <Grid container spacing={4} alignItems='center' justifyContent='center'>
          <Grid item xs={12} align='center'>
            <LoadingBlock />
          </Grid>
        </Grid>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {
    //auth: state.firebase.auth,
    user: state.firebase.profile,
    companyError: state.company.companyError,
    companyMessage: state.company.companyMessage,
    //promoSettings: state.firestore.ordered.settings,
    //selectedCompany: state.company.company
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //findCompanyFromCompanyCode: ({ code, promo }) => dispatch(findCompanyFromCompanyCode({ code, promo })),
    //findCompanyFromCity: ({ city, promo }) => dispatch(findCompanyFromCity({ city, promo })),
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
  //firestoreConnect((props) => [{ collection: 'settings', doc: 'promoSite' }])
)(SelectDoctorForm);
