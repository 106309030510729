import React from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import firebase from 'firebase/app';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { signOut } from '../../actions/authActions';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { compose } from 'redux';
import { withTranslation, Trans } from 'react-i18next';
import soltaLogo from '../../images/solta_white.svg';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import moment from 'moment';
import * as COLOURS from '../Styles/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  footerCNB: {
    //background: 'rgb(67,22,63)',
    background: COLOURS.soltaGrey,
    position: 'relative',
    paddingTop: 30,
    paddingBottom: 30,
    marginTop: 60,
    bottom: 0,
    width: '100%',
  },
  footerPurple: {
    //background: 'rgb(67,22,63)',
    background: 'linear-gradient(90deg, rgba(67,22,63,1) 0%, rgba(155,62,150,1) 100%)',
    position: 'relative',
    paddingTop: 30,
    paddingBottom: 30,
    marginTop: 60,
    bottom: 0,
    width: '100%',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: '#fff',
  },
  right: {
    textAlign: 'right',
  },
  copy: {
    color: '#fff',
    fontSize: 11,
  },
  white: {
    color: '#fff !important',
  },
  languageTop: {
    paddingTop: '15px !important',
  },
  links: {
    color: '#fff !important',
    cursor: 'pointer',
  },
  logo: {
    maxWidth: 250,
    display: 'block',
    '@media (max-width: 599px)': {
      width: 150,
    },
  },
}));

const Footer = (props) => {
  const classes = useStyles();

  const changeLang = (lang) => {
    firebase.analytics().logEvent('change_language', { language: lang, value: lang });
    props.i18n.changeLanguage(lang);
  };

  const { auth, t } = props;

  const signOutLinks = auth.uid ? (
    <Button onClick={props.signOut} className={classes.white}>
      {t('navigation.signOutButton')}
    </Button>
  ) : (
    <Button component={Link} to={ROUTES.LOGIN} className={classes.white}>
      {t('navigation.signInButton')}
    </Button>
  );
  //console.log('logo props profile', props)
  return (
    <div className={classes.footerCNB}>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <Grid container spacing={3}>
              <Grid item sm={2} xs={12}>
                <Link to={ROUTES.HOME}>
                  <img src={soltaLogo} alt='Solta' className={classes.logo} />
                </Link>
              </Grid>
              <Hidden xsDown>
                <Grid item xs={2}></Grid>
              </Hidden>
              <Grid item sm={8} xs={12}>
                <Grid container spacing={2}>
                  <Grid container item xs={6} spacing={1} className={classes.languageTop}>
                    <Grid item xl={1} lg={2} md={2} xs={2}>
                      <Typography variant='body1' className={classes.links} onClick={(e) => changeLang('en')}>
                        EN
                      </Typography>
                    </Grid>
                    <Grid item xs={1} align='center'>
                      <Typography variant='body1' className={classes.white}>
                        |
                      </Typography>
                    </Grid>

                    <Grid item xl={1} lg={2} md={2} xs={2}>
                      <Typography variant='body1' className={classes.links} onClick={(e) => changeLang('fr')}>
                        FR
                      </Typography>
                    </Grid>
                    <Grid item xs={1} align='center'>
                      <Typography variant='body1' className={classes.white}>
                        |
                      </Typography>
                    </Grid>

                    <Grid item xl={1} lg={2} md={2} xs={2}>
                      <Typography variant='body1' className={classes.links} onClick={(e) => changeLang('zh')}>
                        ZH
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid item xs={6}>
                    <Button component={Link} to={ROUTES.HOME} className={classes.white}>
                      {t('buttons.home')}
                    </Button>
                    <Button component={Link} to={ROUTES.PROFILE} className={classes.white}>
                      {t('buttons.profile')}
                    </Button>
                    <Button component={Link} to={ROUTES.FAQ} className={classes.white}>
                      {t('buttons.faq')}
                    </Button>
                    {signOutLinks}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body2' className={classes.copy}>
                      <Trans> {t('footer.finePrintOne')}</Trans>
                    </Typography>
                    <Typography variant='body2' className={classes.copy}>
                      <Trans>{t('footer.finePrintTwo')}</Trans>
                    </Typography>
                    <Typography variant='body2' className={classes.copy}>
                      {t('footer.finePrintThree', { year: moment().format('YYYY') })}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Button component={Link} to={ROUTES.TERMS} className={classes.white}>
                      {t('buttons.terms')}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button href='mailto:info@fmad.ca' className={classes.white}>
                      info@fmad.ca
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  //console.log('this is ym state', state);
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDisptachToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
  };
};

export default compose(withTranslation(), connect(mapStateToProps, mapDisptachToProps))(Footer);
