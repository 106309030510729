import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

//import TextField from '@material-ui/core/TextField';
import styles from '../Styles';
import CancelIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { updatePhoneAccess } from '../../actions/authActions';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import firebase from 'firebase/app';
import { signOut } from '../../actions/authActions';
import Typography from '@material-ui/core/Typography';

const INITIAL_STATE = {
  phoneNumber: '',
};

class PhoneAccessPopup extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  agreeToTerms = async (event) => {
    //update with true and phonenumber

    await firebase.firestore().collection('consumer').doc(this.props.auth.uid).update({
      agreeToTerms: true,
      agreeToTermsDate: firebase.firestore.FieldValue.serverTimestamp(),
    });
  };

  cancelAgreement = () => {
    //update with false
    console.log('this is my signout?');
    this.props.signOut();
  };

  render() {
    const { profile, classes, auth, t } = this.props;

    if (profile.isLoaded && !profile.isEmpty && auth.uid) {
      return (
        <Dialog disableBackdropClick disableEscapeKeyDown aria-labelledby='phone-number-opt-in' aria-describedby='phone-number-opt-in' open={profile.agreeToTerms === undefined} classes={{ paper: classes.termPopUp }}>
          <DialogTitle id='phone-number-opt-in-title'>
            <Grid container spacing={2} justifyContent='flex-start' alignItems='center'>
              <Grid item xs={10}>
                <Typography variant='h6'>We have updated our terms and services</Typography>
              </Grid>
              <Grid item xs={2} align='right'>
                <IconButton onClick={this.cancelAgreement}>
                  <CancelIcon className={classes.soltaColor} />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Typography variant='h5'>{t('terms.title')}</Typography>
                <Typography variant='body2'>{t('terms.sectionOne')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadTwo')}</Typography>
                <Typography variant='body2'>{t('terms.sectionTwo')}</Typography>
                <br />
                <Typography variant='body2'>{t('terms.sectionTwob')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadThree')}</Typography>
                <Typography variant='body2'>{t('terms.sectionThree')}</Typography>
                <br />
                <Typography variant='body2'>{t('terms.sectionThreeb')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadFour')}</Typography>
                <Typography variant='body2'>{t('terms.sectionFour')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadFive')}</Typography>
                <Typography variant='body2'>{t('terms.sectionFive')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadSix')}</Typography>
                <Typography variant='body2'>{t('terms.sectionSix')}</Typography>
                <br />
                <Typography variant='body2'>{t('terms.sectionSixb')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadSeven')}</Typography>
                <Typography variant='body2'>{t('terms.sectionSeven')}</Typography>
                <br />
                <Typography variant='body2'>{t('terms.sectionSevenb')}</Typography>
                <br />
                <Typography variant='body2'>{t('terms.sectionSevenc')}</Typography>
                <br />
                <Typography variant='body2'>{t('terms.sectionSevend')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadEight')}</Typography>
                <Typography variant='body2'>{t('terms.sectionEight')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadNine')}</Typography>
                <Typography variant='body2'>{t('terms.sectionNine')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadTen')}</Typography>
                <Typography variant='body2'>{t('terms.sectionTen')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadEleven')}</Typography>
                <Typography variant='body2'>{t('terms.sectionEleven')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadTwelve')}</Typography>
                <Typography variant='body2'>{t('terms.sectionTwelve')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadThirteen')}</Typography>
                <Typography variant='body2'>{t('terms.sectionThirteen')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadFourteen')}</Typography>
                <Typography variant='body2'>{t('terms.sectionFourteen')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadFifteen')}</Typography>
                <Typography variant='body2'>{t('terms.sectionFifteen')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6'>{t('terms.subHeadSixteen')}</Typography>
                <Typography variant='body2'>{t('terms.sectionSixteen')}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='body2' className={classes.fineprint}>
                  {t('terms.fineprint')}
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={1}>
              <Grid item xs={4} align='left'>
                <Button onClick={this.cancelAgreement}>Decline</Button>
              </Grid>
              <Grid item xs={8} align='right'>
                <Button color='primary' onClick={this.agreeToTerms}>
                  Agree
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      );
    } else return null;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    updatePhoneAccess: (user, access, phone) => dispatch(updatePhoneAccess(user, access, phone)),
  };
};

PhoneAccessPopup.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation(), connect(mapStateToProps, mapDispatchToProps))(PhoneAccessPopup);
