import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//import ThermageLogo from '../../images/thermage_eyes.svg';
//import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
//import Divider from '@material-ui/core/Divider';

import LogoHeader from '../Header';

const styles = {
  root: {
    flexGrow: 1,
    whiteSpace: 'pre-line',
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  customerName: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    color: '#9b3e96',
    fontSize: 48,
    '@media (max-width: 1025px)': {
      fontSize: 28,
    },
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  logo: {
    marginTop: 30,
    width: 155,
    display: 'block',
    '@media (max-width: 1025px)': {
      width: 135,
      marginLeft: -40,
      marginTop: 0,
    },
  },
  alignCenter: {
    textAlign: 'center',
  },
  questionMark: {
    right: -50,
    display: 'block',
    position: 'absolute',
    top: 0,
    '@media (max-width: 1440px)': {
      right: -70,
    },
    '@media (max-width: 1300px)': {
      right: -85,
    },
    '@media (max-width: 1025px)': {
      right: -30,
    },
    '@media (max-width: 850px)': {
      right: -50,
    },
    '@media (max-width: 599px)': {
      right: -30,
    },
  },
  logoBlock: {
    position: 'relative',
    display: 'block',
  },
  mobileCenter: {
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  fineprint: {
    fontSize: 11,
  },
};

const INITIAL_STATE = {
  loading: true,
};

class TermsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {}

  componentWillUnmount() {}

  componentDidUpdate() {}

  render() {
    const { t, classes } = this.props;

    return (
      <div className='meta-react'>
        <LogoHeader t={t} />
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className={classes.root}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant='h5'>{t('terms.title')}</Typography>
                    <Typography variant='body2'>{t('terms.sectionOne')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>{t('terms.subHeadTwo')}</Typography>
                    <Typography variant='body2'>{t('terms.sectionTwo')}</Typography>
                    <br />
                    <Typography variant='body2'>{t('terms.sectionTwob')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>{t('terms.subHeadThree')}</Typography>
                    <Typography variant='body2'>{t('terms.sectionThree')}</Typography>
                    <br />
                    <Typography variant='body2'>{t('terms.sectionThreeb')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>{t('terms.subHeadFour')}</Typography>
                    <Typography variant='body2'>{t('terms.sectionFour')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>{t('terms.subHeadFive')}</Typography>
                    <Typography variant='body2'>{t('terms.sectionFive')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>{t('terms.subHeadSix')}</Typography>
                    <Typography variant='body2'>{t('terms.sectionSix')}</Typography>
                    <br />
                    <Typography variant='body2'>{t('terms.sectionSixb')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>{t('terms.subHeadSeven')}</Typography>
                    <Typography variant='body2'>{t('terms.sectionSeven')}</Typography>
                    <br />
                    <Typography variant='body2'>{t('terms.sectionSevenb')}</Typography>
                    <br />
                    <Typography variant='body2'>{t('terms.sectionSevenc')}</Typography>
                    <br />
                    <Typography variant='body2'>{t('terms.sectionSevend')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>{t('terms.subHeadEight')}</Typography>
                    <Typography variant='body2'>{t('terms.sectionEight')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>{t('terms.subHeadNine')}</Typography>
                    <Typography variant='body2'>{t('terms.sectionNine')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>{t('terms.subHeadTen')}</Typography>
                    <Typography variant='body2'>{t('terms.sectionTen')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>{t('terms.subHeadEleven')}</Typography>
                    <Typography variant='body2'>{t('terms.sectionEleven')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>{t('terms.subHeadTwelve')}</Typography>
                    <Typography variant='body2'>{t('terms.sectionTwelve')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>{t('terms.subHeadThirteen')}</Typography>
                    <Typography variant='body2'>{t('terms.sectionThirteen')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>{t('terms.subHeadFourteen')}</Typography>
                    <Typography variant='body2'>{t('terms.sectionFourteen')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>{t('terms.subHeadFifteen')}</Typography>
                    <Typography variant='body2'>{t('terms.sectionFifteen')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6'>{t('terms.subHeadSixteen')}</Typography>
                    <Typography variant='body2'>{t('terms.sectionSixteen')}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='body2' className={classes.fineprint}>
                      {t('terms.fineprint')}
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

TermsPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {
    //auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //setPromo: (promoID) => dispatch(setPromo(promoID)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => [])
)(TermsPage);
