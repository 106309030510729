import React from 'react';
import pure from 'recompose/pure';
import { SvgIcon } from '@material-ui/core';

let GoogleLogo = (props) => (
    <SvgIcon {...props}>
        <g>
            <path d="M4.7,10c0-1,0.3-1.9,0.7-2.7V3.9H2.1C0.7,5.7,0,7.8,0,10s0.7,4.3,2.1,6.1h3.4v-3.4C5,11.9,4.7,11,4.7,10z" fill="#fbbd00" />
            <path d="M10,15.3l-2.3,2.3L10,20c2.2,0,4.3-0.7,6.1-2.1v-3.4h-3.4C11.9,15,11,15.3,10,15.3z" fill="#0f9d58" />
            <path d="M5.4,12.7l-3.4,3.4c0.3,0.3,0.6,0.7,0.9,1C4.8,19,7.3,20,10,20v-4.7C8.1,15.3,6.4,14.3,5.4,12.7L5.4,12.7z" fill="#31aa52" />
            <path d="M20,10c0-0.6-0.1-1.2-0.2-1.8l-0.1-0.5H10v4.7h4.7c-0.5,0.9-1.2,1.7-2,2.2l3.4,3.4c0.3-0.3,0.7-0.6,1-0.9 C19,15.2,20,12.7,20,10z" fill="#3c79e6" />
            <path d="M13.8,6.2l0.4,0.4l3.3-3.3l-0.4-0.4C15.2,1,12.7,0,10,0L7.7,2.3L10,4.7C11.4,4.7,12.8,5.2,13.8,6.2z" fill="#cf2d48" />
            <path d="M10,4.7V0C7.3,0,4.8,1,2.9,2.9c-0.3,0.3-0.6,0.6-0.9,1l3.4,3.4C6.4,5.7,8.1,4.7,10,4.7z" fill="#eb4132" />
        </g>
    </SvgIcon>
);
GoogleLogo = pure(GoogleLogo);
GoogleLogo.displayName = 'GoogleLogo';
GoogleLogo.muiName = 'SvgIcon';

export default GoogleLogo;