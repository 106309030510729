import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import HomePage from '../Home';
import LoginPage from '../SignIn';
import SignUp from '../SignUp';
import FaqPage from '../Faq';
import TermsPage from '../Terms';

import ThermageEyePage from '../About/thermageEyePage';
import ClearAndBrilliantPage from '../About/clearAndBrilliantPage';
import ClinicCodeLandingPage from '../Clinics/clinicCodeLandingPage';
import NationalCodeLandingPage from '../Clinics/nationalCodeLanding';
import ForgotPasswordForm from '../SignIn/forgotPassword';
import ProfilePage from '../Profile';
import Footer from '../Footer';
import SignUpQC from '../SignUp/signUpQC';
import * as ROUTES from '../../constants/routes';
import PhoneAccessPopup from '../Terms/phoneAccessPopUp';
import TermsPopup from '../Terms/termsPopUp';
import ProfilePopUp from '../Profile/profilePopUp';

const App = () => (
  <div className='app'>
    <Router>
      <div className='app-page-content'>
        <Route exact path={ROUTES.LANDING} component={HomePage} />
        <Route exact path={ROUTES.SIGN_UP} component={SignUp} />
        <Route exact path={ROUTES.SIGN_UP_REDIRECT} component={SignUp} />
        <Route exact path={ROUTES.SIGN_UP_QUEBEC} component={SignUpQC} />
        <Route exact path={ROUTES.SIGN_UP_QUEBEC_REDIRECT} component={SignUpQC} />
        <Route exact path={ROUTES.LOGIN} component={LoginPage} />
        <Route exact path={ROUTES.LOGIN_REDIRECT} component={LoginPage} />
        <Route exact path={ROUTES.FAQ} component={FaqPage} />
        <Route exact path={ROUTES.TERMS} component={TermsPage} />
        <Route exact path={ROUTES.ABOUT} component={ClearAndBrilliantPage} />
        <Route exact path={ROUTES.ABOUT_THERMAGE} component={ThermageEyePage} />
        <Route exact path={ROUTES.CLINICS} component={ClinicCodeLandingPage} />
        <Route exact path={ROUTES.NATIONAL_ACCOUNT} component={NationalCodeLandingPage} />
        <Route exact path={ROUTES.FORGOTTEN_PASSWORD} component={ForgotPasswordForm} />
        <Route exact path={ROUTES.PROFILE} component={ProfilePage} />
      </div>
      <Footer />
      <PhoneAccessPopup />
      <TermsPopup />
      <ProfilePopUp />
    </Router>
  </div>
);

export default App;
