import moment from 'moment';

export const setPromo = (promoID) => {
  return (dispatch, getState) => {
    //console.log('this is the id i am trying to set', promoID)
    dispatch({ type: 'SET_PROMO_CODE', promoID });
  };
};

export const addClinicToMyPromo = ({ userID, promoID, clinicID, user, company, promo }) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    firebase.analytics().logEvent('select_company', { name: 'add', company_id: clinicID, user: userID });
    //console.log('this is my end date', moment(newPromo.promoEndDate).valueOf())

    const promoUpdateLine = 'promos.' + promoID + '.clinic';

    //console.log('this is my promo', promo);

    try {
      await firestore
        .collection('consumer')
        .doc(userID)
        .update({
          [promoUpdateLine]: clinicID,
        });
    } catch (err) {
      dispatch({ type: 'ERROR_ADDING_CLINIC_TO_CONSUMER', err });
    }

    let companyName = company.data ? company.data.name : company.name;
    let companyEmail = company.data ? company.data.email : company.email;

    try {
      await firestore.collection('mail').add({
        cc: [user.email, companyEmail],
        message: {
          subject: 'You have linked your promo with a clinic',
          text: `Congratulations ${user.fullName}, you have linked your ${promo.nameen} with the following clinic: ${companyName}. You can claim your promo by booking an appointment by ${moment(promo.promoEndDate.toDate()).format('MMMM Do, Y')} with the clinic. We have included the clinic in this email to help you book an appointment. `,
          html: `Congratulations ${user.fullName},<br/><br/> You have linked your ${promo.nameen} with the following clinic:<b> ${companyName}</b>. <br/><br/> You can claim your promo by booking an appointment by<b> ${moment(promo.promoEndDate.toDate()).format(
            'MMMM Do, Y'
          )}</b> with the clinic.<br/><br/> We have included the clinic in this email to help you book an appointment.<br/><br/> Thank you for signing up with The Solta Advantage Program. `,
        },
      });
    } catch (err) {
      console.log('that was not suppose to happen', err);
      dispatch({ type: 'ERROR_SENDING_CLINIC_EMAIL', err });
    }

    let clinicSnapShot = await firestore.collection('companies').doc(clinicID).get();

    let resp = { id: clinicSnapShot.id, data: clinicSnapShot.data() };
    dispatch({ type: 'ADDED_CLINIC_TO_CONSUMER', resp });
  };
};

export const sendContactEmail = ({ userID, promoID, clinicID, user, company }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    firebase.analytics().logEvent('contact_email_sent', { company_id: clinicID, user: userID });
    //console.log('this is my end date', moment(newPromo.promoEndDate).valueOf())

    firestore
      .collection('mail')
      .add({
        cc: [user.email, company.email],
        message: {
          subject: `Book an Appointment for ${user.fullName}`,
          text: `Hi ${company.name}, I would like to book an appointment so that I could see if Clear + Brilliant is right for me. Thanks ${user.firstName} `,
          html: `Hi ${company.name}, I would like to book an appointment so that I could see if Clear + Brilliant is right for me. Thanks ${user.firstName} `,
        },
      })
      .then(() => {
        dispatch({ type: 'CONTACT_EMAIL_SENT' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SENDING_CONTACT_EMAIL', err });
      });
  };
};

export const removeClinicToMyPromo = ({ userID, promoID }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase.analytics().logEvent('select_company', { name: 'remove', user: userID });
    //console.log('this is my end date', moment(newPromo.promoEndDate).valueOf())
    const promoUpdateLine = 'promos.' + [promoID] + '.clinic';
    firestore
      .collection('consumer')
      .doc(userID)
      .update({
        [promoUpdateLine]: '',
      })
      .then(() => {
        dispatch({ type: 'REMOVED_CLINIC_FROM_CONSUMER' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_REMOVING_CLINIC_FROM_CONSUMER', err });
      });
  };
};

export const closePromosPopUpMess = () => {
  return (dispatch) => {
    dispatch({ type: 'CLOSE_PROMO_POPUPS' });
  };
};

export const getPromoCode = ({ userID, promoID, promo, user }) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase.analytics().logEvent('get_promo_code', { name: 'getCode', user: userID, promo: promoID });
    //console.log('this is my end date', moment(newPromo.promoEndDate).valueOf())

    //console.log('this is my promo', promo);
    //console.log('this is my user', user);

    try {
      let promoCodeResp = await firestore.collection('promoCodes').doc('freeCodes').get();

      const promoCode = promoCodeResp.data().codes[0];
      const promoPath = 'promos.' + promoID;
      await firestore
        .collection('consumer')
        .doc(userID)
        .update({
          [promoPath]: {
            promoCode: promoCode,
            brand: promo.promoTechnology,
            promoCodeClaimed: false,
            clinic: '',
          },
        });

      await firestore.collection('mail').add({
        cc: [user.email],
        message: {
          subject: `You have gotten your ${promo.nameen} promo code!`,
          text: `Hi ${user.firstName ? user.firstName : user.email}, Here is your ${promo.nameen} promo code ${promoCode}. Now all you need to do is find a participating clinic to enroll in and get your treatment today! Simply log into fmad.ca and search for a clinic that is close to you. You have until ${moment(promo.promoEndDate.toDate()).format(
            'MMMM Do Y'
          )} to book your appointment.`,
          html: `Hi ${user.firstName ? user.firstName : user.email},<br/><br/> Here is your ${
            promo.nameen
          } promo code <b>${promoCode}</b>. <br/><br/> Now all you need to do is find a participating clinic to enroll in and get your treatment today! <br/><br/> Simply log into <a href="https://fmad.ca/">fmad.ca</a> and search for a clinic that is close to you.<br/><br/> <b>You have until ${moment(promo.promoEndDate.toDate()).format(
            'MMMM Do Y'
          )} to book your appointment.</b> `,
        },
      });

      const unusedPromoCodes = promoCodeResp.data().codes.filter((code) => code !== promoCode);

      await firestore.collection('promoCodes').doc('freeCodes').set({
        codes: unusedPromoCodes,
      });

      await firestore
        .collection('promoCodes')
        .doc('promoCodesLeft')
        .update({
          count: firestore.FieldValue.increment(-1),
        });

      await firestore
        .collection('promoCodes')
        .doc('usedCodes')
        .update({
          codes: firestore.FieldValue.arrayUnion(promoCode),
        });

      dispatch({ type: 'USER_GOT_PROMO_CODE' });
    } catch (e) {
      console.log('OH NO THAT WASNT SUPPOSE TO HAPPEN', e);
    }
  };
};

export const getPromoCodeWithClinic = ({ userID, promoID, clinicID, promoTech, user, company, promo }) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firebase.analytics().logEvent('get_promo_code_with_clinic', { name: 'getCode', user: userID, promo: promoID });
    //console.log('this is my end date', moment(newPromo.promoEndDate).valueOf())

    /* console.log('this is my company', company);
    console.log('this is my user', user);
    console.log('this is my promo', promo);
 */
    const promoCodeResp = await firestore.collection('promoCodes').doc('freeCodes').get();

    const promoCode = promoCodeResp.data().codes[0];
    const promoPath = 'promos.' + promoID;
    await firestore
      .collection('consumer')
      .doc(userID)
      .update({
        [promoPath]: {
          promoCode: promoCode,
          promoCodeClaimed: false,
          brand: promoTech ? promoTech : '',
          clinic: clinicID,
        },
      });

    try {
      //Send out an email with the code in it for the user
      await firestore.collection('mail').add({
        cc: [user.email],
        message: {
          subject: `You have gotten your ${promo.nameen} promo code!`,
          text: `Hi ${user.firstName ? user.firstName : user.email}, Here is your ${promo.nameen} promo code ${promoCode}. You have linked your ${promo.nameen} with the following clinic: ${company.name}. You can claim your promo by booking an appointment by ${moment(promo.promoEndDate.toDate()).format('MMMM Do, Y')} with the clinic.`,
          html: `Hi ${user.firstName ? user.firstName : user.email},<br/><br/> Here is your ${promo.nameen} promo code <b>${promoCode}</b>. <br/><br/> You have linked your <b>${promo.nameen}</b> with the following clinic: <b>${company.name}</b>. <br/><br/> You can claim your promo by booking an appointment by <b>${moment(promo.promoEndDate.toDate()).format(
            'MMMM Do, Y'
          )}</b> with the clinic. `,
        },
      });

      //Send out an email to the user and the clinic linking them together
      await firestore.collection('mail').add({
        cc: [user.email, company.email],
        message: {
          subject: 'You have linked your promo with a clinic',
          text: `Congratulations ${user.firstName ? user.firstName : user.email}, you have linked your ${promo.nameen} with the following clinic: ${company.name}. You can claim your promo by booking an appointment by ${moment(promo.promoEndDate.toDate()).format('MMMM Do, Y')} with the clinic. We have included the clinic in this email to help you book an appointment. `,
          html: `Congratulations ${user.firstName ? user.firstName : user.email},<br/><br/> You have linked your ${promo.nameen} with the following clinic:<b> ${company.name}</b>. <br/><br/> You can claim your promo by booking an appointment by<b> ${moment(promo.promoEndDate.toDate()).format(
            'MMMM Do, Y'
          )}</b> with the clinic.<br/><br/> We have included the clinic in this email to help you book an appointment.<br/><br/> Thank you for signing up with The Solta Advantage Program. `,
        },
      });
    } catch (err) {
      console.log('that was not suppose to happen', err);
      dispatch({ type: 'ERROR_SENDING_CLINIC_EMAIL', err });
    }

    const unusedPromoCodes = promoCodeResp.data().codes.filter((code) => code !== promoCode);

    await firestore.collection('promoCodes').doc('freeCodes').set({
      codes: unusedPromoCodes,
    });

    await firestore
      .collection('promoCodes')
      .doc('promoCodesLeft')
      .update({
        count: firestore.FieldValue.increment(-1),
      });

    await firestore
      .collection('promoCodes')
      .doc('usedCodes')
      .update({
        codes: firestore.FieldValue.arrayUnion(promoCode),
      });

    dispatch({ type: 'USER_GOT_PROMO_CODE' });
  };
};
