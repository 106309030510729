import React, { Component } from 'react';

import * as ROUTES from '../../constants/routes';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

//New sign in method
import { connect } from 'react-redux';
import { sendForgottenPasswordEmail, closeAuthPopUps } from '../../actions/authActions';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import ImageHeader from '../Header/imageHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const styles = {
  root: {
    flexGrow: 1,
    height: '100%',
  },
  textField: {
    width: '100%',
  },
  button: {
    width: '100%',
    textAlign: 'left',
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const INITIAL_STATE = {
  email: '',
};

class ForgotPasswordForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    event.preventDefault();
    this.props.sendForgottenPasswordEmail(this.state.email);
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  closePopUps = () => {
    this.props.closeAuthPopUps();
  };

  render() {
    const { email } = this.state;

    const isInvalid = email === '';

    const { auth, t, classes, authError, authMessage, authErrorPopUp, authPopUp } = this.props;

    if (this.props.match.params.id) {
      //console.log('we will redirect to my page', this.props.match.params.id);
      if (auth.uid) return <Redirect to={ROUTES.CLINIC + this.props.match.params.id} />;
    } else {
      if (auth.uid) return <Redirect to={ROUTES.LANDING} />;
    }

    return (
      <div className='meta-react'>
        <ImageHeader />
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className={classes.root}>
                <Grid container spacing={3} justifyContent='center'>
                  <Grid item xs={12}></Grid>
                  <Grid item md={4} xs={12} align='center'>
                    <Typography variant='h4'>{t('forgotPass.title')}</Typography>
                  </Grid>
                  <Grid item xs={12} container justifyContent='center'>
                    <Grid item sm={1} xs={2}>
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <form onSubmit={this.onSubmit}>
                      <Grid item container spacing={3} justifyContent='center'>
                        <Grid item xs={12}>
                          <TextField name='email' className={classes.textField} value={email} onChange={this.onChange} type='text' label={t('signIn.emailAddress')} />
                        </Grid>
                        <Grid item xs={12} align='center'>
                          <Button disabled={isInvalid} type='submit' variant='contained' onClick={this.onSubmit}>
                            {t('forgotPass.button')}
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </Grid>
                </Grid>
                <Snackbar open={authPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
                  <Alert onClose={this.closePopUps} severity='success'>
                    {authMessage}
                  </Alert>
                </Snackbar>
                <Snackbar open={authErrorPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
                  <Alert onClose={this.closePopUps} severity='error'>
                    {authError}
                  </Alert>
                </Snackbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ForgotPasswordForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    authError: state.auth.authError,
    authMessage: state.auth.authMessage,
    authPopUp: state.auth.authPopUp,
    authErrorPopUp: state.auth.authErrorPopUp,
    auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeAuthPopUps: () => dispatch(closeAuthPopUps()),
    sendForgottenPasswordEmail: (email) => dispatch(sendForgottenPasswordEmail(email)),
  };
};

export default compose(withTranslation(), withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ForgotPasswordForm);
