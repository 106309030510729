/**************************************************************************/
/*  File Name: options.js                                                 */
/*  Date:      Aug 12 2021                                                */
/*  Author:    Christopher Cressman                                       */
/*  Purpose:   This will just handle the switch case to show which form   */
/*             to display                                                 */
/*                                                                        */
/**************************************************************************/

import React from 'react';
import LoginChoice from './signInChoice';
import SignUp from './signUp';
import SignIn from './signIn';

const SignInOptions = ({ signInType, props, setSignInType, company, setShowLogin, promo }) => {
  switch (signInType) {
    case 'start':
      return <LoginChoice props={props} setSignInType={setSignInType} company={company} promo={promo} setShowLogin={setShowLogin} />;
    case 'email':
      return <SignUp props={props} company={company} setShowLogin={setShowLogin} promo={promo} />;
    case 'login':
      return <SignIn props={props} company={company} setShowLogin={setShowLogin} promo={promo} />;
    default:
      return <LoginChoice props={props} setSignInType={setSignInType} company={company} promo={promo} setShowLogin={setShowLogin} />;
  }
};

export default SignInOptions;
