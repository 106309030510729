import { Redirect } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { blue, grey } from '@material-ui/core/colors';

import Paper from '@material-ui/core/Paper';
import { createQCEmailUser, signUpWithFacebook, signUpWithGoogle, closeAuthPopUps } from '../../actions/authActions';
import headerImage from '../../images/1x/header-image-desktop.jpg';
import ImageHeader from '../Header/imageHeader';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import NBAward from '../../images/NB_AwardSeal_2021.svg';

const styles = {
  root: {
    flexGrow: 1,
    height: '100%',
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  logo: {
    marginTop: 30,
    width: 155,
    '@media (max-width: 1025px)': {
      width: 100,
      marginTop: 26,
    },
  },
  button: {
    width: '100%',
    textAlign: 'left',
  },
  textField: {
    width: '100%',
  },
  alignCenter: {
    textAlign: 'center',
  },
  purpleLine: {
    background: 'linear-gradient(90deg, rgba(67,22,63,1) 0%, rgba(155,62,150,1) 100%)',
    display: 'block',
    height: 10,
  },
  headerImage: {
    display: 'block',
    margin: 0,
    padding: 0,
  },
  headerBlock: {
    backgroundImage: `url(${headerImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#EDEDED',
    height: 400,
    display: 'block',
  },
  header: {
    height: 400,
  },
  soltaLogo: {
    width: 250,
    marginRight: 50,
    marginTop: 50,
  },
  purpleGradient: {
    //background: 'rgb(255,255,255)',
    background: 'linear-gradient(0deg, rgba(255,255,255,0.45) 0%, rgba(204,156,201,0.45) 100%)',
    height: 60,
    display: 'block',
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const INITIAL_STATE = {
  //loading: false,
  email: false,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  phoneOpt: false,
  emailAddress: '',
  password: '',
  reTypePassword: '',
  regPopUp: false,
  regMessage: null,
  showPassword: false,
  passwordType: 'password',
  showRetypePass: false,
  retypePasswordType: 'password',
};

class SignUpQC extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
  }

  componentWillUnmount() {}

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  addEmailUser = () => {
    /// check phone stuff

    if (this.state.password === this.state.reTypePassword) {
      this.props.createQCEmailUser(this.state);
    } else {
      this.setState({
        regPopUp: true,
        regMessage: this.props.t('signUp.passwordsDontMatch'),
      });
    }
  };

  closeAuthPopUps = () => {
    this.props.closeAuthPopUps();
  };

  closeRegPopUps = () => {
    this.setState({
      regPopUp: false,
      regMessage: null,
    });
  };

  showMyPassword = () => {
    let show = this.state.showPassword ? false : true;
    let pass = show ? 'text' : 'password';
    this.setState({
      showPassword: show,
      passwordType: pass,
    });
  };

  showMyRetypePassword = () => {
    let show = this.state.showRetypePass ? false : true;
    let pass = show ? 'text' : 'password';
    this.setState({
      showRetypePass: show,
      retypePasswordType: pass,
    });
  };

  render() {
    const { emailAddress, password, reTypePassword, regPopUp, regMessage, showPassword, passwordType, retypePasswordType, showRetypePass } = this.state;
    const { auth, t, classes, authError, authMessage, authErrorPopUp, authPopUp } = this.props;

    if (this.props.match.params.id) {
      //console.log('we will redirect to my page', this.props.match.params.id);

      if (auth.uid) return <Redirect to={ROUTES.CLINIC + this.props.match.params.id} />;
    } else {
      if (auth.uid) return <Redirect to={ROUTES.LANDING} />;
    }

    const formFiledOut = emailAddress.length > 0 && password.length >= 8 && reTypePassword.length >= 8;

    const passwordError = password.length < 8 && password !== '';
    const retypePasswordError = reTypePassword !== password && reTypePassword !== '';

    //const {
    //loading
    //} = this.state;
    //console.log('this is the home page props', this.props);
    return (
      <div className='meta-react'>
        <ImageHeader />
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className={classes.root}>
                <Grid container spacing={3} justifyContent='center'>
                  <Grid item xs={12} align='center'>
                    <img src={NBAward} alt='New Beauty Award' style={{ height: 200 }} />
                    <Typography variant='body1' style={{ textTransform: 'uppercase', fontSize: 18, marginTop: 10, color: '#293b59' }}>
                      {t('signUp.bestLaser')}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} align='center'>
                    <Typography variant='h5' align='center'>
                      {t('signUp.cbtitle')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center' justifyContent='center'>
                      <Grid item sm={1} xs={2}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container spacing={3} justifyContent='center'>
                      <Grid item sm={4}></Grid>
                      <Grid item sm={4} xs={12} align='center'>
                        <form>
                          <Grid container spacing={3} alignItems='center' justifyContent='center'>
                            <Grid item xs={12}>
                              <TextField className={classes.textField} onChange={this.onChange} id='emailAddress' name='emailAddress' autoComplete='username' value={emailAddress} label='Email' />
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl>
                                <InputLabel htmlFor='password'>Password</InputLabel>
                                <Input
                                  className={classes.textField}
                                  onChange={this.onChange}
                                  error={passwordError}
                                  id='password'
                                  name='password'
                                  type={passwordType}
                                  autoComplete='new-password'
                                  value={password}
                                  endAdornment={
                                    <InputAdornment position='end'>
                                      <IconButton aria-label='toggle password visibility' onClick={this.showMyPassword}>
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                                <FormHelperText id='reTypePassword'>{t('signUp.passwordRestrictions')}</FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <FormControl>
                                <InputLabel htmlFor='reTypePassword'>Re-Type Password</InputLabel>
                                <Input
                                  className={classes.textField}
                                  onChange={this.onChange}
                                  error={retypePasswordError}
                                  id='reTypePassword'
                                  name='reTypePassword'
                                  type={retypePasswordType}
                                  autoComplete='new-password'
                                  value={reTypePassword}
                                  endAdornment={
                                    <InputAdornment position='end'>
                                      <IconButton aria-label='toggle password visibility' onClick={this.showMyRetypePassword}>
                                        {showRetypePass ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  }
                                />
                                <FormHelperText id='reTypePassword'>{t('signUp.passwordReType')}</FormHelperText>
                              </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                              <Paper elevation={0} className={classes.alignCenter}>
                                <ThermageButton size='large' disabled={!formFiledOut} onClick={this.addEmailUser}>
                                  {t('signUp.button')}
                                </ThermageButton>
                              </Paper>
                            </Grid>
                          </Grid>
                        </form>
                      </Grid>
                      <Grid item xs={4}></Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
              <Snackbar open={authPopUp} autoHideDuration={6000} onClose={this.closeAuthPopUps}>
                <Alert onClose={this.closeAuthPopUps} severity='success'>
                  {authMessage}
                </Alert>
              </Snackbar>
              <Snackbar open={authErrorPopUp} autoHideDuration={6000} onClose={this.closeAuthPopUps}>
                <Alert onClose={this.closeRegPopUps} severity='error'>
                  {authError}
                </Alert>
              </Snackbar>
              <Snackbar open={regPopUp} autoHideDuration={6000} onClose={this.closeRegPopUps}>
                <Alert onClose={this.closeRegPopUps} severity='error'>
                  {regMessage}
                </Alert>
              </Snackbar>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ThermageButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
    '&:disabled': {
      color: '#fff',
      backgroundColor: grey[500],
    },
  },
}))(Button);

SignUpQC.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    authMessage: state.auth.authMessage,
    authPopUp: state.auth.authPopUp,
    authErrorPopUp: state.auth.authErrorPopUp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createQCEmailUser: (newUser) => dispatch(createQCEmailUser(newUser)),
    signUpWithFacebook: () => dispatch(signUpWithFacebook()),
    signUpWithGoogle: () => dispatch(signUpWithGoogle()),
    closeAuthPopUps: () => dispatch(closeAuthPopUps()),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation())(SignUpQC);
