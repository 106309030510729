import Geocode from 'react-geocode';
//import geohash from 'ngeohash';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API);

export const getCompanyLogo = (url) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    //const firestore = getFirestore();
    const storage = firebase.storage().ref();

    if (url) {
      storage
        .child(url)
        .getDownloadURL()
        .then((url) => {
          // `url` is the download URL for 'images/stars.jpg'

          // This can be downloaded directly:
          let xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = function (event) {
            //var blob = xhr.response;
          };
          xhr.open('GET', url);
          xhr.send();

          let resp = { url: url };
          dispatch({ type: 'LOGO_WAS_FOUND', resp });
          // Or inserted into an <img> element:
          //let img = document.getElementById('myimg');
          //img.src = url;
        })
        .catch((error) => {
          dispatch({ type: 'LOGO_WAS_NOT_FOUND', error });
        });
    } else {
      dispatch({ type: 'DEFAULT_LOGO' });
    }
  };
};

export const findCompanyFromCompanyCode = ({ code, promo }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    firebase.analytics().logEvent('search_company', { method: 'company_code', company_id: code });

    firestore
      .collection('companies')
      .where('companyCode', '==', code)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          dispatch({ type: 'NO_COMPANY_FOUND_WITH_THAT_CODE' });
        } else {
          const resp = [];
          snapshot.forEach((doc) => {
            const clinicInfo = doc.data();
            //console.log('this is my clinic data', clinicInfo);
            if (clinicInfo.promos) {
              let inPromoProgram = clinicInfo.promos.includes(promo);
              //console.log('am i in the promo program', inPromoProgram);
              //console.log('this is my clinicInfo', clinicInfo)
              if (inPromoProgram) {
                resp.push({ id: doc.id, data: doc.data() });
                dispatch({ type: 'COMPANY_FOUND_WITH_CODE', resp });
              } else {
                dispatch({ type: 'COMPANY_FOUND_NOT_IN_PROMO_PROGRAM' });
              }
            } else {
              dispatch({ type: 'COMPANY_FOUND_NOT_IN_PROMO_PROGRAM' });
            }
          });
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_LOKING_FOR_COMPANY_WITH_CODE', err });
      });
  };
};

export const findCompanyFromCity = ({ city, promo, user }) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    firebase.analytics().logEvent('search_company', { method: 'company_city', company_id: city });
    console.log('this is my city', city);
    console.log('this is my promo', promo);

    try {
      let settings = await firestore.collection('settings').doc('promoSite').get();

      let cityGeoCode = await Geocode.fromAddress(`${city}, Canada`);

      console.log('this is my cityGeoCode', cityGeoCode);

      if (cityGeoCode.results.length > 0) {
        const searchProv = cityGeoCode.results[0].address_components[2].short_name;

        //Check to see if search province is in lockdown
        if (settings.data().lockDownProv.includes(searchProv)) {
          //console.log('this is my user', user);

          try {
            await firestore
              .collection('lists')
              .doc('lockDownList')
              .set({
                users: firestore.FieldValue.arrayUnion({ email: user.email, prov: searchProv }),
              });
          } catch (err) {
            console.log('this is my error adding user to the list', err);
          }

          dispatch({ type: 'PROVINCE_IN_LOCKDOWN' });
        } else {
          const cityLat = cityGeoCode.results[0].geometry.location.lat;
          const cityLong = cityGeoCode.results[0].geometry.location.lng;
          const distance = 63; //miles 50miles 80km   100km = 62.13
          const latDist = 0.0144927536231884; // degrees latitude per mile
          const lonDist = 0.0181818181818182; // degrees longitude per mile

          const lowerLat = cityLat - latDist * distance;
          const lowerLong = cityLong - lonDist * distance;

          const upperLat = cityLat + latDist * distance;
          const upperLong = cityLong + lonDist * distance;

          let companySearch = await firestore.collection('companies').where('promos', 'array-contains', promo).orderBy('name').get();
          console.log('tjis is my company searhc -> ', companySearch);
          //console.log('this is my snapshot', snapshot);
          if (companySearch.empty) {
            dispatch({ type: 'NO_COMPANY_FOUND_WITH_RUNNING_THAT_PROMO_CODE' });
          } else {
            const citySearch = [];
            companySearch.forEach((doc) => {
              const clinicInfo = doc.data();

              /* console.log('this is my clinic ---> ', doc.data().name);
              console.log('this is my clinic lat ---> ', clinicInfo.location.lat);
              console.log('this is my clinic long -----> ', clinicInfo.location.long); */

              if (clinicInfo.location.lat >= lowerLat && clinicInfo.location.lat <= upperLat) {
                if (clinicInfo.location.long >= lowerLong && clinicInfo.location.long <= upperLong) {
                  //console.log('I get added because I am in the long', doc.data().name);
                  citySearch.push({ id: doc.id, data: doc.data() });
                } else {
                  //dispatch({ type: 'COMPANY_TO_FAR_AWAY_FROM_LOCATION' });
                }
              } else {
                //dispatch({ type: 'COMPANY_TO_FAR_AWAY_FROM_LOCATION' });
              }
            });

            //console.log('this is my resp lenght', resp);

            if (citySearch.length > 0) {
              //console.log('this is my resp from city search', citySearch);
              //We need to match city searches first and then go with the rest via alphabetical
              let sortedCitySearch = [];
              let notInCity = [];
              citySearch.map((c) => {
                if (c.data.location.city === city) {
                  sortedCitySearch.push(c);
                } else {
                  notInCity.push(c);
                }
                return true;
              });

              notInCity.map((c) => {
                return sortedCitySearch.push(c);
              });
              //console.log('this is my sortedSearch', sortedCitySearch);

              dispatch({ type: 'COMPANY_FOUND_IN_LOCATION', sortedCitySearch });
            } else {
              dispatch({ type: 'COMPANY_FOUND_NOT_IN_PROMO_PROGRAM' });
            }
          }
        }
      } else {
        dispatch({ type: 'NO_COMPANY_FOUND_WITH_RUNNING_THAT_PROMO_CODE' });
      }
    } catch (err) {
      dispatch({ type: 'ERROR_SEARCHING_YOUR_LOCATION', err });
    }
  };
};

export const clearCompanySearch = () => {
  return (dispatch, getState) => {
    //console.log('this is the id i am trying to set', promoID)
    dispatch({ type: 'CLEAR_COMPANY_SEARCH' });
  };
};

export const closeCompanyPopUps = () => {
  return (dispatch, getState) => {
    //console.log('this is the id i am trying to set', promoID)
    dispatch({ type: 'CLOSE_COMPANY_POPUPS' });
  };
};

export const loadCompany = (clinicID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    //const firebase = getFirebase();

    firestore
      .collection('companies')
      .doc(clinicID)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          dispatch({ type: 'NO_COMPANY_FOUND_WITH_THAT_CODE' });
        } else {
          const resp = [];

          //console.log('this is my clinic data', clinicInfo);
          resp.push({ id: snapshot.id, data: snapshot.data() });
          dispatch({ type: 'COMPANY_HAS_BEEN_LOADED', resp });
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_LOKING_FOR_COMPANY_WITH_CODE', err });
      });
  };
};
