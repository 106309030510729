import authReducer from './authReducer';
import companyReducer from './companyReducer';
import brandReducer from './brandReducer';
import technologyReducer from './technologyReducer';
import promoReducer from './promoReducer';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';


const rootReducer = combineReducers({
    firestore: firestoreReducer,
    firebase: firebaseReducer,
    auth: authReducer,
    company: companyReducer,
    brand: brandReducer,
    technology: technologyReducer,
    promos: promoReducer

});

export default rootReducer;