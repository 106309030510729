import firebase from 'firebase/app';

////////////////////////////////////////////
// Simple function to get the company logo
////////////////////////////////////////////
export const getCompanyLogo = async (logoURL) => {
  let companyLogo = null;
  //console.log('this is my logo');
  if (logoURL) {
    try {
      let companyLogoURL = await firebase.storage().ref(logoURL).getDownloadURL();
      //console.log('this is my company logo url', companyLogoURL);
      companyLogo = companyLogoURL ? companyLogoURL : null;
    } catch (error) {
      console.log('there was an error getting the logo', error);
    }
  }

  return companyLogo;
};

////////////////////////////////////////////
// Simple function to get the brand logo
////////////////////////////////////////////
export const getBrandLogo = async (logoURL) => {
  let brandLogo = null;

  if (logoURL) {
    let brandLogoURL = await firebase.storage().ref(logoURL).getDownloadURL();
    brandLogo = brandLogoURL ? brandLogoURL : null;
  }

  return brandLogo;
};

////////////////////////////////////////////
// Get technology details
////////////////////////////////////////////
export const getTechnologyDetails = async ({ brandID, technologies }) => {
  console.log('this is my brand Id', brandID);
  if (brandID) {
    let foundTech = technologies.filter((t) => t.id === brandID);
    return foundTech.length > 0 ? foundTech[0] : null;
  } else {
    return null;
  }
};
