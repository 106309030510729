import Button from '@material-ui/core/Button';
import { purple } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';

export const ThermageButton = withStyles((theme) => ({
  root: {
    color: `${theme.palette.getContrastText(purple[500])} !important`,
    backgroundColor: purple[500],
    paddingLeft: 20,
    paddingRight: 20,

    '&:hover': {
      backgroundColor: purple[700],
    },
    '&:active': {
      backgroundColor: purple[700],
    },
    '&:focus': {
      backgroundColor: purple[700],
    },
  },
}))(Button);
