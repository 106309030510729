/*****************************************************************************/
/*  File Name: clinicPromos.js                                               */
/*  Date:      Aug 11, 2021                                                  */
/*  Author:    Christopher Cressman                                          */
/*  Purpose:   This block is used to list the promo details for a clinic     */
/*             this will be used on various pages                            */
/*                                                                           */
/*****************************************************************************/

import React, { useState, useEffect } from 'react'; //useState,
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
//import { makeStyles } from '@material-ui/core/styles';
//import { getBrandLogo } from './actions';
//import styles from './styles';
import PromoInfo from './promoInfo';
import ClinicLogin from '../SignUp/clinicLogin';
import firebase from 'firebase/app';
import moment from 'moment';

/* const useStyles = makeStyles((theme) => ({
  ...styles,
}));
 */
function ClinicPromos({ company, companyPromos, promoTechnology, props }) {
  //const t = props.t;

  //Load all of the default component varaibles
  //const classes = useStyles();
  const profile = useSelector((state) => state.firebase.profile);
  const auth = useSelector((state) => state.firebase.auth);

  const [showLogin, setShowLogin] = useState(false);
  const [promo, setPromo] = useState(null);

  useEffect(() => {}, []);

  const getPromoCode = async (promo) => {
    //console.log('lets get a promo code for this promo', promo.id);
    //We will need to do a couple of things
    //Check to see if the user is logged in
    if (profile.isLoaded && profile.isEmpty) {
      //console.log('lets display a login page');
      setShowLogin(true);
      setPromo(promo);
    } else {
      //User is signed in so get them a code
      firebase.analytics().logEvent('get_promo_code', { name: 'getCode', user: auth.uid, promo: promo.id });

      //Lets get all of the codes
      let promoCodeResp = await firebase.firestore().collection('promoCodes').doc('freeCodes').get();

      //Lets get the first code
      const promoCode = promoCodeResp.data().codes[0];

      //This is to add the promo in
      const promoPath = 'promos.' + promo.id;

      //Add the promo code to the new user
      await firebase
        .firestore()
        .collection('consumer')
        .doc(auth.uid)
        .update({
          [promoPath]: {
            promoCode: promoCode,
            brand: promo.promoTechnology,
            promoCodeClaimed: false,
            clinic: company.id,
          },
        });

      //Now we need to filter out the used promo code
      const unusedPromoCodes = promoCodeResp.data().codes.filter((code) => code !== promoCode);

      //Add the updated list of codes
      await firebase.firestore().collection('promoCodes').doc('freeCodes').set({
        codes: unusedPromoCodes,
      });

      await firebase
        .firestore()
        .collection('promoCodes')
        .doc('promoCodesLeft')
        .update({
          count: firebase.firestore.FieldValue.increment(-1),
        });

      await firebase
        .firestore()
        .collection('promoCodes')
        .doc('usedCodes')
        .update({
          codes: firebase.firestore.FieldValue.arrayUnion(promoCode),
        });

      //Now lets send out an email

      await firebase
        .firestore()
        .collection('mail')
        .add({
          cc: [profile.email],
          message: {
            subject: `Your Promo Code for ${promo['name' + props.i18n.language]}`,
            text: `Hi ${profile.firstName ? profile.firstName : profile.email}, Here is your promo code for the following promotion - ${promo['name' + props.i18n.language]}:<br/><br/> ${promoCode}. Please note that the clinic will need your promo code when you book your appointment.`,
            html: `Hi ${profile.firstName ? profile.firstName : profile.email},<br/><br/> Here is your promo code for the following promotion - ${promo['name' + props.i18n.language]}:<br/><br/> <b>${promoCode}</b>.<br/><br/> Please note that the clinic will need your promo code when you book your appointment. `,
          },
        });

      await firebase
        .firestore()
        .collection('mail')
        .add({
          cc: [profile.email, company.email],
          message: {
            subject: 'You have linked your promo with a clinic',
            text: `Congratulations ${profile.firstName ? profile.firstName : profile.email}, you have linked your ${promo.nameen} with the following clinic: ${company.name}. You can claim your promo by booking an appointment by ${moment(promo.promoEndDate.toDate()).format(
              'MMMM Do, Y'
            )} with the clinic. We have included the clinic in this email to help you book an appointment. `,
            html: `Congratulations ${profile.firstName ? profile.firstName : profile.email},<br/><br/> You have linked your ${promo.nameen} with the following clinic:<b> ${company.name}</b>. <br/><br/> You can claim your promo by booking an appointment by<b> ${moment(promo.promoEndDate.toDate()).format(
              'MMMM Do, Y'
            )}</b> with the clinic.<br/><br/> We have included the clinic in this email to help you book an appointment.<br/><br/> Thank you for signing up with The Solta Advantage Program. `,
          },
        });
    }

    console.log();
  };

  //console.log('these are a my logos', techLogos);

  //console.log('this is my profile', profile);

  if (companyPromos) {
    if (companyPromos.length > 0) {
      if (!showLogin) {
        //We are going to show the clinics promos
        return companyPromos.map((promo) => <PromoInfo company={company} promo={promo} getPromoCode={getPromoCode} promoTechnology={promoTechnology} props={props} key={promo.id} />);
      } else {
        //The use needs to login
        return <ClinicLogin props={props} setShowLogin={setShowLogin} company={company} promo={promo} />;
      }
    } else {
      return '';
    }
  } else {
    return '';
  }
}

export default withTranslation()(ClinicPromos);
