/*****************************************************************************/
/*  File Name: clinicInfo.js                                                 */
/*  Date:      Aug 11, 2021                                                  */
/*  Author:    Christopher Cressman                                          */
/*  Purpose:   This block is used to have just the clinic contact info       */
/*             in it and will be used on various pages                       */
/*                                                                           */
/*****************************************************************************/

import React from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
//import firebase from 'firebase/app';
import styles from './styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import BusinessIcon from '@material-ui/icons/Business';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageIcon from '@material-ui/icons/Language';

const useStyles = makeStyles((theme) => ({
  ...styles,
}));

function ClinicInfo({ company }) {
  //Load all of the default component varaibles

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid item xs={12} container spacing={1}>
        <Grid item xs={1}>
          <BusinessIcon />
        </Grid>
        <Grid item xs={11} container>
          <Grid item xs={12}>
            <Typography variant='body1'>{company.location.streetAddress},</Typography>
          </Grid>
          {company.location.streetAddress2 ? (
            <Grid item xs={12}>
              <Typography variant='body1'>{company.location.streetAddress2},</Typography>
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={12}>
            <Typography variant='body1'>{company.location.city},</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{company.location.province}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body1'>{company.location.postalCode}</Typography>
          </Grid>
        </Grid>
      </Grid>
      {company.website ? ( //IF they have a website
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={1}>
            <LanguageIcon />
          </Grid>
          <Grid item xs={11} container>
            <Grid item xs={12}>
              <Typography variant='body1'>
                <a href={`https://${company.website}`} target='_blank' rel='noopener noreferrer'>
                  {company.website}
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ''
      )}

      {company.phoneNumber ? ( //IF they have a phone number
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={1}>
            <PhoneIcon />
          </Grid>
          <Grid item xs={11} container>
            <Grid item xs={12}>
              <Typography variant='body1'>
                <a href={`tel:${company.phoneNumber}`}> {company.phoneNumber}</a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
      {company.email ? ( //IF they have an email show the email
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={1}>
            <EmailIcon />
          </Grid>
          <Grid item xs={11} container>
            <Grid item xs={12}>
              <Typography variant='body1'>
                <a href={`mailto:${company.email}`}> {company.email}</a>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </div>
  );
}

export default withTranslation()(ClinicInfo);
