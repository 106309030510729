import { green, red, grey } from '@material-ui/core/colors';
const darkGrey = '#656366';
const greyText = darkGrey;
const solta = '#5a9bab';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  flexParent: {
    display: 'flex',
    flexDirection: 'column',
  },
  soltaColor: {
    color: solta,
  },

  soltaColorFill: {
    fill: solta,
  },
  soltaBackground: {
    backgroundColor: solta,
  },
  fullHeight: {
    height: '100%',
  },
  image: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  tableHead: {
    backgroundColor: '#5a9bab',
    color: '#fff',
  },
  whiteButton: {
    color: '#fff',
    borderColor: '#fff',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',

    justifyContent: 'center',
  },
  paper: {
    backgroundColor: '#fff',
    border: '2px solid #000',
    width: '50%',
    boxShadow: 5,
    paddingTop: 20,
    paddingLeft: 40,
    paddingRight: 40,
    paddingBottom: 30,
  },
  spacingTop: {
    marginTop: theme.spacing(2),
  },
  spacingBiggerTop: {
    marginTop: theme.spacing(4),
  },
  courseTitle: {
    fontSize: 28,
    color: greyText,
  },
  coursePages: {
    fontWeight: 100,
    fontSize: 18,
    //marginTop: 30,
  },
  techBrandLogo: {
    display: 'block',
    width: '100%',
  },
  slideImage: {
    display: 'block',
    width: '100%',
  },
  margin10: {
    marginTop: 10,
  },
  padding10: {
    Top: 10,
  },
  margin20: {
    marginTop: 20,
  },
  margin30: {
    marginTop: 30,
  },
  margin40: {
    marginTop: 40,
  },
  margin60: {
    marginTop: 60,
  },
  imageWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    //transform: 'translate(-50%, -50%)',
  },
  imageOverlay: {
    zIndex: 100,
    display: 'block',
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'black',
    opacity: 0.6,
    top: 0,
  },
  loadingBlock: {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  correctAnswer: {
    color: green[500],
    fontWeight: 700,
  },
  wrongAnswer: {
    color: grey[500],
    fontWeight: 200,
  },
  wrongPickedAnswer: {
    color: red[800],
    fontWeight: 700,
  },
  questionNum: {
    display: 'inline-block',
  },
  questionQuest: {
    display: 'inline-block',
  },
  greenText: {
    color: green[500],
  },
  redText: {
    color: red[800],
  },
  textIcon: {
    display: 'inline-flex',
    VerticalAlign: 'text-bottom',
    BoxSizing: 'inherit',
    textAlign: 'center',
    AlignItems: 'center',
    marginTop: 5,
    marginRight: 5,
  },
  title: {
    flexGrow: 1,
    color: '#fff',
  },
  right: {
    textAlign: 'right',
  },
  white: {
    color: '#fff',
  },
  paddingLeft: {
    paddingLeft: 15,
  },
  list: {
    width: 250,
  },
  footer: {
    position: 'relative',
    bottom: 0,
    backgroundColor: darkGrey,
    width: '100%',
    marginTop: 30,
  },
  footerLogo: {
    display: 'block',
    width: '100%',
    maxWidth: 250,
  },
  thinType: {
    fontWeight: 100,
  },
  svgIcon: {
    //display: 'block',
    fontSize: 34,
  },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: '100%',
  },
  formControl: {
    margin: 1,
    minWidth: '100%',
  },
  staffCourseHead: {
    backgroundColor: grey[200],
    //color: '#fff',
  },
  loginLogo: {
    display: 'block',
    maxWidth: '60%',
  },
  typeButtons: {
    cursor: 'pointer',
  },
  reactVideoWrapper: {
    position: 'relative',
    paddingTop: '56.25% /* Player ratio: 100 / (1280 / 720) */',
  },
  reactVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  termsPopUp: {
    maxHeight: '40vh !important',
  },
});

export default styles;
