import React from 'react';
import ThermageLogo from './images/thermage_logo.svg';
import ClearBrilliantLogo from './images/cb_logo.svg';
import SoltaLogo from './images/solta_logo.svg';

const Logos = (props) => {
  let brand = props.brand.technologies.filter((t) => t.id === props.brand.brandID);

  if (brand.length > 0) {
    switch (brand[0].brandName) {
      case 'Thermage':
        return <img src={ThermageLogo} alt='Thermage' {...props} />;
      case 'Clear + Brilliant':
        return <img src={ClearBrilliantLogo} alt='Clear + Brilliant' {...props} />;
      default:
        return <img src={SoltaLogo} alt='Solta' {...props} />;
    }
  } else {
    return <img src={SoltaLogo} alt='Solta' {...props} />;
  }
};

export default Logos;
