const initState = {
  companyError: null,
  companyMessage: null,
  companyPopUp: false,
  companyErrorPopUp: false,
  companyLogo: '',
  loadedLogo: false,
  company: null,
  promoClinics: [],
};

const companyReducer = (state = initState, action) => {
  switch (action.type) {
    case 'NEW_COMPANY_CREATED':
      return {
        ...state,
        companyError: null,
        companyMessage: 'alerts.company.createdCompany', //Company has been created
      };
    case 'ERROR_CREATING_NEW_COMPANY':
      return {
        ...state,
        companyError: action.err.message,
        companyMessage: 'alerts.company.errorCreateCompany', //"Error when creating the company"
      };
    case 'ERROR_SEARCHING_FOR_NEW_COMPANY':
      return {
        ...state,
        companyError: action.err.message,
        companyMessage: 'alerts.company.errorSearchCompany', //Error when search for the company
      };
    case 'COMPANY_LOGO_UPDATED':
      return {
        ...state,
        companyError: null,
        companyMessage: 'alerts.company.companyLogoUploaded', //Company Logo has been uploaded
      };
    case 'COMPANY_LOGO_FAILED_TO_UPLOAD':
      return {
        ...state,
        companyError: action.err.message,
        companyMessage: 'Error uploadeding companies logo',
      };
    case 'NO_COMPANY_FOUND_WITH_THAT_CODE':
      return {
        ...state,
        companyError: 'alerts.company.noCompanyFound', //No company has was found with that code
        companyErrorPopUp: true,
      };
    case 'COMPANY_FOUND_WITH_CODE':
      return {
        ...state,
        company: action.resp[0],
        companyMessage: 'alerts.company.companyFound', //A company has been found
        companyPopUp: true,
      };
    case 'COMPANY_HAS_BEEN_LOADED':
      return {
        ...state,
        promoClinics: [...state.promoClinics, action.resp[0]],
      };

    case 'ADDED_CLINIC_TO_CONSUMER':
      return {
        ...state,
        promoClinics: [...state.promoClinics, action.resp[0]],
      };
    case 'LOGO_WAS_FOUND':
      //console.log('this is my action ', action)
      return {
        ...state,
        companyLogo: action.resp.url,
        loadedLogo: true,
      };
    case 'DEFAULT_LOGO':
      //console.log('this is my action ', action)
      return {
        ...state,
        companyLogo: 'default',
        loadedLogo: true,
      };
    case 'ERROR_LOKING_FOR_COMPANY_WITH_CODE':
      //console.log('this is my action ', action)
      return {
        ...state,
        companyError: action.err.message,
        //loadedLogo: true
      };
    case 'COMPANY_FOUND_NOT_IN_PROMO_PROGRAM':
      return {
        ...state,
        companyError: 'alerts.company.companyFoundNotinPromo', //A company was been found but they are not in the promo program. Please search for another company
        companyErrorPopUp: true,
      };

    case 'COMPANY_FOUND_IN_LOCATION':
      return {
        ...state,
        company: action.sortedCitySearch,
        companyMessage: 'alerts.company.companyFound', //A company has been found
        companyPopUp: true,
      };
    case 'CLEAR_COMPANY_SEARCH':
      return {
        ...state,
        company: null,
        companyError: null,
        companyErrorPopUp: false,
        companyMessage: null,
        companyPopUp: false,
      };
    case 'NO_COMPANY_FOUND_WITH_RUNNING_THAT_PROMO_CODE':
      return {
        ...state,
        companyError: 'alerts.company.noCompanyRunningPromo', //No companies are running this promo at the moment
        companyErrorPopUp: true,
      };
    case 'COMPANY_TO_FAR_AWAY_FROM_LOCATION':
      return {
        ...state,
        companyError: 'alerts.company.noCompany100km', //There are no companies within a 100km radias of that location. Please try a different location
        companyErrorPopUp: true,
      };
    case 'CLOSE_COMPANY_POPUPS':
      return {
        ...state,
        companyError: null,
        companyErrorPopUp: false,
        companyMessage: null,
        companyPopUp: false,
      };
    case 'PROVINCE_IN_LOCKDOWN':
      return {
        ...state,
        companyError: 'home.provinceLockDown',
        companyErrorPopUp: true,
      };
    default:
      return state;
  }
};

export default companyReducer;
