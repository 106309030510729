/*****************************************************************************/
/*  File Name: clinicLogin.js                                                */
/*  Date:      Aug 12, 2021                                                  */
/*  Author:    Christopher Cressman                                          */
/*  Purpose:   We will use this block for the clinic logins it needs to      */
/*             be a little simplified                                        */
/*                                                                           */
/*****************************************************************************/

import React from 'react'; //useState,
//import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import GoogleLogo from '../../../images/icons/GoogleLogo';
import Divider from '@material-ui/core/Divider';
import EmailIcon from '@material-ui/icons/Email';
import firebase, { firestore } from 'firebase/app';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  ...styles,
}));

function LoginChoice({ company, props, setSignInType, promo, setShowLogin }) {
  const t = props.t;

  const classes = useStyles();

  const signUpWithGoogle = async () => {
    console.log('this is my sign up with google function');
    //Lets tag the event for analytics
    firebase.analytics().logEvent('sign_up', { method: 'Google' });

    let provider = new firebase.auth.GoogleAuthProvider();

    let googleUser = await firebase.auth().signInWithPopup(provider);

    //console.log('this is my google user', googleUser.user);

    let firstName = googleUser.user.displayName.substr(0, googleUser.user.displayName.indexOf(' '));
    let email = googleUser.user.email;

    await firebase
      .firestore()
      .collection('consumer')
      .doc(googleUser.user.uid)
      .set({
        firstName: googleUser.user.displayName.substr(0, googleUser.user.displayName.indexOf(' ')),
        lastName: googleUser.user.displayName.substr(googleUser.user.displayName.indexOf(' ') + 1),
        fullName: googleUser.user.displayName,
        initials: googleUser.user.displayName.substr(0, googleUser.user.displayName.indexOf(' '))[0] + googleUser.user.displayName.substr(googleUser.user.displayName.indexOf(' ') + 1)[0],
        promos: {}, //This is just going to be the first round this will not be set till they set later once we get more promos going
        email: googleUser.user.email,
        role: 'consumer',
        userAdded: firestore.FieldValue.serverTimestamp(),
      });

    if (company && promo) {
      //Lets tag this event
      firebase.analytics().logEvent('get_promo_code', { name: 'getCode', user: googleUser.user.uid, promo: promo.id });

      //Lets get all of the codes
      let promoCodeResp = await firebase.firestore().collection('promoCodes').doc('freeCodes').get();

      //Lets get the first code
      const promoCode = promoCodeResp.data().codes[0];

      //This is to add the promo in
      const promoPath = 'promos.' + promo.id;

      //Add the promo code to the new user
      await firebase
        .firestore()
        .collection('consumer')
        .doc(googleUser.user.uid)
        .update({
          [promoPath]: {
            promoCode: promoCode,
            brand: promo.promoTechnology,
            promoCodeClaimed: false,
            clinic: company.id,
          },
        });

      //Now we need to filter out the used promo code
      const unusedPromoCodes = promoCodeResp.data().codes.filter((code) => code !== promoCode);

      //Add the updated list of codes
      await firebase.firestore().collection('promoCodes').doc('freeCodes').set({
        codes: unusedPromoCodes,
      });

      await firebase
        .firestore()
        .collection('promoCodes')
        .doc('promoCodesLeft')
        .update({
          count: firebase.firestore.FieldValue.increment(-1),
        });

      await firebase
        .firestore()
        .collection('promoCodes')
        .doc('usedCodes')
        .update({
          codes: firebase.firestore.FieldValue.arrayUnion(promoCode),
        });

      await firebase
        .firestore()
        .collection('mail')
        .add({
          cc: [email],
          message: {
            subject: `Your Promo Code for ${promo['name' + props.i18n.language]}`,
            text: `Hi ${firstName ? firstName : email}, Here is your promo code for the following promotion - ${promo['name' + props.i18n.language]}:<br/><br/> ${promoCode}. Please note that the clinic will need your promo code when you book your appointment.`,
            html: `Hi ${firstName ? firstName : email},<br/><br/> Here is your promo code for the following promotion - ${promo['name' + props.i18n.language]}:<br/><br/> <b>${promoCode}</b>.<br/><br/> Please note that the clinic will need your promo code when you book your appointment. `,
          },
        });

      //Now lets send out an email
      await firebase
        .firestore()
        .collection('mail')
        .add({
          cc: [email, company.email],
          message: {
            subject: 'You have linked your promo with a clinic',
            text: `Congratulations ${firstName ? firstName : email}, you have linked your ${promo.nameen} with the following clinic: ${company.name}. You can claim your promo by booking an appointment by ${moment(promo.promoEndDate.toDate()).format('MMMM Do, Y')} with the clinic. We have included the clinic in this email to help you book an appointment. `,
            html: `Congratulations ${firstName ? firstName : email},<br/><br/> You have linked your ${promo.nameen} with the following clinic:<b> ${company.name}</b>. <br/><br/> You can claim your promo by booking an appointment by<b> ${moment(promo.promoEndDate.toDate()).format(
              'MMMM Do, Y'
            )}</b> with the clinic.<br/><br/> We have included the clinic in this email to help you book an appointment.<br/><br/> Thank you for signing up with The Solta Advantage Program. `,
          },
        });

      //Finally we need to hide the login information
      setShowLogin(false);
    }
  };

  return (
    <Grid item xs={12} container spacing={3} justifyContent='center'>
      <Grid item xs={12} align='center'>
        <Typography variant='h6'>{t('signUp.alreadySignedUp')}</Typography>
      </Grid>
      <Grid item xs={12} align='center'>
        <Button variant='contained' size='large' className={classes.button} onClick={() => setSignInType('login')}>
          {t('signUp.login')}
        </Button>
      </Grid>
      <Grid item xs={2}>
        <Divider />
      </Grid>

      <Grid item xs={12} align='center'>
        <Button variant='contained' size='large' className={classes.button} onClick={() => setSignInType('email')} startIcon={<EmailIcon />}>
          {t('signUp.signUpEmail')}
        </Button>
      </Grid>
      <Grid item xs={12} align='center'>
        <Button variant='contained' size='large' className={classes.button} onClick={signUpWithGoogle} startIcon={<GoogleLogo />}>
          {t('signUp.signUpGoogle')}
        </Button>
      </Grid>
    </Grid>
  );
}

export default withTranslation()(LoginChoice);
