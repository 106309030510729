import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ImageHeader from '../Header/imageHeader';
import ReactPlayer from 'react-player/lazy';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import CBBeforeA from '../../images/CB_images/Before - A@2x.jpg';
import CBAfterA from '../../images/CB_images/After - A@2x.jpg';
import CBBeforeB from '../../images/CB_images/Before - B@2x.jpg';
import CBAfterB from '../../images/CB_images/After -B@2x.jpg';
import firebase from 'firebase/app';
import LoadingBlock from '../Loading';

const styles = {
  root: {
    flexGrow: 1,
    whiteSpace: 'pre-line',
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  CBLightBlue: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    color: '#0080B2',
    fontSize: 36,
    lineHeight: '40px',
    marginBottom: 18,
    '@media (max-width: 1025px)': {
      fontSize: 28,
      lineHeight: '30px',
    },
    '@media (max-width: 599px)': {
      //textAlign: 'center',
    },
  },
  CBGreyPoint: {
    fontFamily: 'Montserrat',
    fontWeight: 'fontWeightRegular',
    color: '#707070',
    marginBottom: 8,
    fontSize: 16,
    '@media (max-width: 1025px)': {
      fontSize: 16,
    },
    '@media (max-width: 599px)': {
      //textAlign: 'center',
    },
  },
  customerName: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    color: '#9b3e96',
    fontSize: 48,
    '@media (max-width: 1025px)': {
      fontSize: 28,
    },
    '@media (max-width: 599px)': {
      //textAlign: 'center',
    },
  },
  logo: {
    marginTop: 30,
    width: 155,
    display: 'block',
    '@media (max-width: 1025px)': {
      width: 135,
      marginLeft: -40,
      marginTop: 0,
    },
  },
  alignCenter: {
    textAlign: 'center',
  },
  questionMark: {
    right: -50,
    display: 'block',
    position: 'absolute',
    top: 0,
    '@media (max-width: 1440px)': {
      right: -70,
    },
    '@media (max-width: 1300px)': {
      right: -85,
    },
    '@media (max-width: 1025px)': {
      right: -30,
    },
    '@media (max-width: 850px)': {
      right: -50,
    },
    '@media (max-width: 599px)': {
      right: -30,
    },
  },
  logoBlock: {
    position: 'relative',
    display: 'block',
  },
  mobileCenter: {
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  fineprint: {
    fontSize: 11,
  },
  images: {
    width: '100%',
  },
  purple: {
    color: '#9b3e96',
  },
  darkBlue: {
    color: '#283A59',
  },
  lightBlue: {
    color: '#0080B2',
  },
  promoGrey: {
    color: '#707070',
    fontSize: 16,
  },
  points: {
    marginBottom: 10,
  },
};

const INITIAL_STATE = {
  loading: true,
};

class ClearAndBrilliantPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    const storage = firebase.storage().ref();
    storage
      .child('promosite/videos/c_b_how_it_works.m4v')
      .getDownloadURL()
      .then((url) => {
        this.setState({ cbVideom4v: url });
      });

    storage
      .child('promosite/videos/c_b_how_it_works.webm')
      .getDownloadURL()
      .then((url) => {
        this.setState({ cbVideowebm: url, loading: false });
      });
  }

  componentWillUnmount() {}

  componentDidUpdate() {}

  render() {
    const { t, classes } = this.props;
    const { cbVideom4v, cbVideowebm, loading } = this.state;

    if (!loading) {
      return (
        <div className='meta-react'>
          <ImageHeader t={t} />
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className={classes.root}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant='h6' className={classes.CBLightBlue}>
                        {t('cbabout.title')}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} align='center'>
                      <ReactPlayer
                        width='100%'
                        height='auto'
                        //playing
                        controls
                        url={[
                          { src: cbVideom4v, type: 'video/m4v' },
                          { src: cbVideowebm, type: 'video/webm' },
                        ]}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <Typography variant='body1' className={classes.promoGrey}>
                        {t('cbabout.subHeader')}
                      </Typography>

                      <Grid item sm={12} xs={12}>
                        <br></br>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item sm={6} xs={12}>
                          <Typography variant='h6' className={classes.CBLightBlue}>
                            {t('cbabout.pointHeaderOne')}
                          </Typography>
                          <Typography className={classes.CBGreyPoint}>{t('cbabout.pHOnepointOne')}</Typography>
                          <Typography className={classes.CBGreyPoint}>{t('cbabout.pHOnepointTwo')} </Typography>
                          <Typography className={classes.CBGreyPoint}>{t('cbabout.pHOnepointThree')} </Typography>
                          <Typography className={classes.CBGreyPoint}> {t('cbabout.pHOnepointFour')}</Typography>
                        </Grid>
                        <Grid item sm={6} xs={12}>
                          <Typography variant='h6' className={classes.CBLightBlue}>
                            {t('cbabout.pointHeaderTwo')}
                          </Typography>
                          <Typography className={classes.CBGreyPoint}>{t('cbabout.pHTwopointOne')}</Typography>
                          <Typography className={classes.CBGreyPoint}>{t('cbabout.pHTwopointTwo')} </Typography>
                          <Typography className={classes.CBGreyPoint}>{t('cbabout.pHTwopointThree')} </Typography>
                          <Typography className={classes.CBGreyPoint}> {t('cbabout.pHTwopointFour')}</Typography>
                          <Typography className={classes.CBGreyPoint}> {t('cbabout.pHTwopointFive')}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item sm={12} xs={12}>
                        <br></br>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant='subtitle1' className={classes.CBLightBlue}>
                          {t('cbabout.beforeAfterTitle')}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <SimpleReactLightbox>
                        <SRLWrapper>
                          <Grid item container spacing={2} xs={12}>
                            <Grid item sm={3} xs={6}>
                              <img src={CBBeforeA} className={classes.images} alt={t('cbabout.before')} />
                            </Grid>
                            <Grid item sm={3} xs={6}>
                              <img src={CBAfterA} className={classes.images} alt={t('cbabout.after11')} />
                            </Grid>
                            <Grid item sm={3} xs={6}>
                              <img src={CBBeforeB} className={classes.images} alt={t('cbabout.before')} />
                            </Grid>
                            <Grid item sm={3} xs={6}>
                              <img src={CBAfterB} className={classes.images} alt={t('cbabout.after9')} />
                            </Grid>
                          </Grid>
                        </SRLWrapper>
                      </SimpleReactLightbox>
                    </Grid>
                    <Grid item container spacing={2} xs={12}>
                      <Grid item sm={3} xs={3}>
                        <Typography className={classes.CBGreyPoint}> {t('cbabout.before')}</Typography>
                      </Grid>
                      <Grid item sm={3} xs={3}>
                        <Typography className={classes.CBGreyPoint}>{t('cbabout.after11')}</Typography>
                      </Grid>
                      <Grid item sm={3} xs={3}>
                        <Typography className={classes.CBGreyPoint}>{t('cbabout.before')}</Typography>
                      </Grid>
                      <Grid item sm={3} xs={3}>
                        <Typography className={classes.CBGreyPoint}>{t('cbabout.after9')}</Typography>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <br></br>
                      <br></br>
                      <Typography variant='body2' gutterBottom className={classes.fineprint}>
                        {t('cbabout.results')}
                      </Typography>

                      <Typography variant='body2' className={classes.fineprint}>
                        {t('indication.cb.title')}
                        <br />
                        {t('indication.cb.copy')}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className='meta-react'>
          <ImageHeader t={t} />
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className={classes.root}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant='h6' className={classes.CBLightBlue}>
                        {t('cbabout.title')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} align='center'>
                      <LoadingBlock />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

ClearAndBrilliantPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {
    //auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //setPromo: (promoID) => dispatch(setPromo(promoID)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => [])
)(ClearAndBrilliantPage);
