/*****************************************************************************/
/*  File Name: profilePopUp.js                                               */
/*  Date:      Aug 18, 2021                                                  */
/*  Author:    Christopher Cressman                                          */
/*  Purpose:   This popup will happen when a user has an auth but they       */
/*             also don't have a clinic account. It is very rare.            */
/*                                                                           */
/*****************************************************************************/

import React, { useState, useEffect } from 'react'; //useState,
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from './styles';
import Grid from '@material-ui/core/Grid';
//import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { signOut } from '../../actions/authActions';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';

import CancelIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import firebase from 'firebase/app';
import { CANADIAN_PROVINCES } from '../../constants/provinces';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';

import InputLabel from '@material-ui/core/InputLabel';

//import { SoltaButton } from '../../Buttons/soltaButton';

const useStyles = makeStyles((theme) => ({
  ...styles,
}));

function ProfilePopUp(props) {
  const t = props.t;
  const classes = useStyles();
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.firebase.profile);
  const auth = useSelector((state) => state.firebase.auth);

  const [checkingUserStatus, setCheckingUserStatus] = useState(true);
  const [clinicUser, setClincUser] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userProvince, setUserProcince] = useState('');

  useEffect(() => {
    async function checkClinicUser(auth) {
      //First thing we need to do is check to see if the user isn't a clinic user

      let clinicUser = await firebase.firestore().collection('users').doc(auth.uid).get();

      if (!clinicUser.exists) {
        console.log('this user does not exist then we can ');
        //The user is not a clinic user so we can create a profile for them
        setCheckingUserStatus(false);
      } else {
        console.log('this user is a clinic user');
        //Create a pop for this
        setClincUser(true);
      }
    }

    //If the profile is loaded and empty theny we need to run this function
    if (profile.isLoaded && profile.isEmpty && auth.uid) {
      checkClinicUser(auth);
    }

    return () => {
      setCheckingUserStatus(true);
      setClincUser(false);
      setFirstName('');
      setLastName('');
      setUserProcince('');
    };
  }, [auth, profile]);

  const cancelProfile = () => {
    //update with false
    console.log('this is my signout?', props);
    dispatch(signOut());
  };

  const updateProfile = async () => {
    console.log('lets update the profile');

    await firebase
      .firestore()
      .collection('consumer')
      .doc(auth.uid)
      .set({
        firstName: firstName,
        lastName: lastName,
        fullName: firstName + ' ' + lastName,
        initials: firstName[0] + lastName[0],
        email: auth.email,
        promos: {},
        role: 'consumer',
        province: userProvince,
      });
  };

  if (!checkingUserStatus) {
    return (
      <Dialog disableBackdropClick disableEscapeKeyDown aria-labelledby='phone-number-opt-in' aria-describedby='phone-number-opt-in' open={profile.agreeToTerms === undefined} classes={{ paper: classes.termPopUp }}>
        <DialogTitle id='phone-number-opt-in-title'>
          <Grid container spacing={2} justifyContent='flex-start' alignItems='center'>
            <Grid item xs={10}>
              <Typography variant='h6'>It appears that we are missing some of your info</Typography>
            </Grid>
            <Grid item xs={2} align='right'>
              <IconButton onClick={cancelProfile}>
                <CancelIcon className={classes.soltaColor} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Typography variant='body2'>{t('clinicBlocks.email')}</Typography>
              <Typography variant='body1'>{auth.email}</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField value={firstName} onChange={(event) => setFirstName(event.target.value)} name='firstName' label={t('profile.firstName')} className={classes.textField} />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField value={lastName} onChange={(event) => setLastName(event.target.value)} name='lastName' label={t('profile.lastName')} className={classes.textField} />
            </Grid>
            <Grid item xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel htmlFor='age-native-helper'>{t('signUp.yourProvince')}</InputLabel>
                <NativeSelect onChange={(event) => setUserProcince(event.target.value)} name='userProvince' value={userProvince}>
                  <option value=''></option>
                  {CANADIAN_PROVINCES.map((p) => (
                    <option key={p.abbrev} value={p.abbrev}>
                      {p.name}
                    </option>
                  ))}
                </NativeSelect>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container spacing={1}>
            <Grid item xs={4} align='left'>
              <Button onClick={cancelProfile}>Decline</Button>
            </Grid>
            <Grid item xs={8} align='right'>
              <Button color='primary' onClick={updateProfile}>
                {t('profile.updateProfile')}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  } else {
    if (clinicUser) {
      return (
        <Dialog disableBackdropClick disableEscapeKeyDown aria-labelledby='phone-number-opt-in' aria-describedby='phone-number-opt-in' open={profile.agreeToTerms === undefined} classes={{ paper: classes.termPopUp }}>
          <DialogTitle id='phone-number-opt-in-title'>
            <Grid container spacing={2} justifyContent='flex-start' alignItems='center'>
              <Grid item xs={10}>
                <Typography variant='h6'>Something went wrong</Typography>
              </Grid>
              <Grid item xs={2} align='right'>
                <IconButton onClick={cancelProfile}>
                  <CancelIcon className={classes.soltaColor} />
                </IconButton>
              </Grid>
            </Grid>
          </DialogTitle>
          <DialogContent dividers>
            <Grid container spacing={3} item xs={12}>
              <Grid item xs={12}>
                <Typography variant='body1'>It appears that you have logged into the promo site with a Solta Clinic account unfortunantly a Solta Clinic account can not use this site. Please log out and login to https://clinic.solta.ca to use your account.</Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={1}>
              <Grid item xs={12} align='right'>
                <Button color='primary' onClick={cancelProfile}>
                  {t('navigation.signOutButton')}
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      );
    } else {
      return null;
    }
  }
}

export default withTranslation()(ProfilePopUp);
