/**************************************************************************/
/*  File Name: rootSettings.js                                            */
/*  Date:      June 9 2021                                                */
/*  Author:    Christopher Cressman                                       */
/*  Purpose:   This will be the root settings for css that are            */
/*             functional components                                      */
/*                                                                        */
/**************************************************************************/

const baseStyles = {
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: '100%',
  },
  dividerSpacing: {
    marginTop: 5,
    marginBottom: 20,
  },
  boldFont: {
    fontWeight: 700,
  },
};

export default baseStyles;
