import { firestoreConnect } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//import ThermageLogo from '../../images/thermage_eyes.svg';
//import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
//import Divider from '@material-ui/core/Divider';
import LogoHeader from '../Header/tightHeader';
import ReactPlayer from 'react-player/lazy';
//import ThermageEyeVideoM4V from '../../videos/thermage_eyes_focus.m4v';
//import ThermageEyeVideoMOV from '../../videos/thermage_eyes_focus.mov';
//import ThermageEyeVideoWEBM from '../../videos/thermage_eyes_focus.webm';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
//import ThermageEyeImageBeforeA from '../../images/eye_images/before_a.jpg';
//import ThermageEyeImageBeforeB from '../../images/eye_images/before_b.jpg';
//import ThermageEyeImageBeforeC from '../../images/eye_images/before_c.jpg';
//import ThermageEyeImageBeforeD from '../../images/eye_images/before_d.jpg';
//import ThermageEyeImageAfterA from '../../images/eye_images/after_a.jpg';
//import ThermageEyeImageAfterB from '../../images/eye_images/after_b.jpg';
//import ThermageEyeImageAfterC from '../../images/eye_images/after_c.jpg';
//import ThermageEyeImageAfterD from '../../images/eye_images/after_d.jpg';
import ThermageEyeGroupA from '../../images/eye_images/before_n_after_a.jpg';
import ThermageEyeGroupB from '../../images/eye_images/before_n_after_b.jpg';
import ThermageEyeGroupC from '../../images/eye_images/before_n_after_c.jpg';
import ThermageEyeGroupD from '../../images/eye_images/before_n_after_d.jpg';
import firebase from 'firebase/app';
import LoadingBlock from '../Loading';
import ThermageHeader from '../../images/2x/header-image.jpg';
import ThermageWhiteLogo from '../../images/thermage-white.svg';
import { ThermageButton } from '../Buttons/thermageButton';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';

const styles = {
  root: {
    flexGrow: 1,
    whiteSpace: 'pre-line',
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  customerName: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    color: '#9b3e96',
    fontSize: 48,
    '@media (max-width: 1025px)': {
      fontSize: 28,
    },
    '@media (max-width: 599px)': {
      //textAlign: 'center',
    },
  },
  logo: {
    marginTop: 30,
    width: 155,
    display: 'block',
    '@media (max-width: 1025px)': {
      width: 135,
      marginLeft: -40,
      marginTop: 0,
    },
  },
  alignCenter: {
    textAlign: 'center',
  },
  questionMark: {
    right: -50,
    display: 'block',
    position: 'absolute',
    top: 0,
    '@media (max-width: 1440px)': {
      right: -70,
    },
    '@media (max-width: 1300px)': {
      right: -85,
    },
    '@media (max-width: 1025px)': {
      right: -30,
    },
    '@media (max-width: 850px)': {
      right: -50,
    },
    '@media (max-width: 599px)': {
      right: -30,
    },
  },
  logoBlock: {
    position: 'relative',
    display: 'block',
  },
  mobileCenter: {
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  fineprint: {
    fontSize: 11,
  },
  images: {
    width: '100%',
  },
  purple: {
    color: '#9b3e96',
  },
  points: {
    marginBottom: 10,
  },
  header: {
    position: 'relative',
    width: '100%',
    marginBottom: 30,
  },
  headerBackgournd: {
    width: '100%',
    height: 494,
    backgroundImage: `url(${ThermageHeader})`,
    display: 'block',
    backgroundSize: '1224px 494px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: 0,
    backgroundPositionY: 0,
    backgroundColor: '#e0e0e0',
  },
  thermageBlock: {
    display: 'block',
    position: 'absolute',
    width: 300,
    height: 150,
    top: 50,
    right: 0,
    background: 'linear-gradient(134deg, rgba(117, 46, 107, 1) 0%, rgba(66, 21, 62, 1) 66%)',
    zIndex: 100,
    '@media (max-width: 599px)': {
      width: '100%',
      top: 345,
    },
  },
  thermageLogo: {
    top: 15,
    left: 35,
    width: 170,
    height: 110,
    display: 'block',
    position: 'absolute',
  },
  goldBar: {
    display: 'block',
    position: 'absolute',
    width: 'calc( 100% - 174px)',
    height: 3,
    zIndex: 1,
    top: 283,
    left: 171,
    background: 'linear-gradient(270deg, rgba(255, 255, 255, 1) 5%, rgba(201, 154, 79, 1) 25%, rgba(245, 225, 123, 1) 40%, rgba(246, 242, 193, 1) 50%, rgba(245, 225, 123, 1) 60%, rgba(201, 154, 79, 1) 75%, rgba(255, 255, 255, 1) 95%)',
  },
};

const INITIAL_STATE = {
  loading: true,
};

class ThermageEyePage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  async componentDidMount() {
    const storage = firebase.storage().ref();
    await storage
      .child('promosite/videos/thermage_eyes_focus.m4v')
      .getDownloadURL()
      .then((url) => {
        this.setState({ ThermageEyeVideoM4V: url });
      });

    await storage
      .child('promosite/videos/thermage_eyes_focus.mov')
      .getDownloadURL()
      .then((url) => {
        this.setState({ ThermageEyeVideoMOV: url });
      });
    await storage
      .child('promosite/videos/thermage_eyes_focus.webm')
      .getDownloadURL()
      .then((url) => {
        this.setState({ ThermageEyeVideoWEBM: url });
      });

    this.setState({
      loading: false,
    });
  }

  componentWillUnmount() {}

  componentDidUpdate() {}

  render() {
    const { t, classes } = this.props;
    const { ThermageEyeVideoWEBM, ThermageEyeVideoM4V, ThermageEyeVideoMOV, loading } = this.state;

    if (!loading) {
      return (
        <div className='meta-react'>
          <LogoHeader t={t} />
          <Grid item container xs={12}>
            <Grid item xs={12} className={classes.header}>
              <div className={classes.headerBackgournd}></div>
              <div className={classes.thermageBlock}>
                <img src={ThermageWhiteLogo} alt='Thermage' className={classes.thermageLogo} />
              </div>
            </Grid>
          </Grid>
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className={classes.root}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant='h5' className={classes.customerName}>
                        {t('about.title')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='h6'>{t('about.subHeader')}</Typography>
                      <Typography variant='body1'>{t('about.seeDiffernce')}</Typography>
                    </Grid>
                    <Grid item xs={12} align='center'>
                      <ReactPlayer
                        width='100%'
                        height='auto'
                        //playing
                        controls
                        url={[
                          { src: ThermageEyeVideoWEBM, type: 'video/webm' },
                          { src: ThermageEyeVideoM4V, type: 'video/m4v' },
                          { src: ThermageEyeVideoMOV, type: 'video/mov' },
                        ]}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <Typography variant='h6' className={`${classes.purple} ${classes.points}`}>
                        {t('about.pointHeaderOne')}
                      </Typography>
                      <Typography variant='body1' className={classes.points}>
                        {t('about.pointOne')}
                      </Typography>
                      <Typography variant='body1' className={classes.points}>
                        {t('about.pointTwo')}
                      </Typography>
                      <Typography variant='body1' className={classes.points}>
                        {t('about.pointThree')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='h6' className={`${classes.purple}`}>
                        {t('about.beforeNAfter')}
                      </Typography>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <SimpleReactLightbox>
                        <SRLWrapper>
                          <Grid item container spacing={2} xs={12}>
                            <Grid item sm={3} xs={6}>
                              <img src={ThermageEyeGroupA} className={classes.images} alt={t('about.imageCap')} />
                            </Grid>
                            <Grid item sm={3} xs={6}>
                              <img src={ThermageEyeGroupB} className={classes.images} alt='Thermage After Eye' />
                            </Grid>
                            <Grid item sm={3} xs={6}>
                              <img src={ThermageEyeGroupC} className={classes.images} alt='Thermage Before Eye' />
                            </Grid>
                            <Grid item sm={3} xs={6}>
                              <img src={ThermageEyeGroupD} className={classes.images} alt='Thermage After Eye' />
                            </Grid>
                          </Grid>
                        </SRLWrapper>
                      </SimpleReactLightbox>
                    </Grid>
                    <Grid item xs={12} align='center'>
                      <ThermageButton size='large' component={Link} to={ROUTES.LANDING}>
                        {t('signUp.button')}
                      </ThermageButton>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant='body2' className={classes.fineprint}>
                        {t('about.finePrint')}
                      </Typography>
                      <br />
                      <Typography variant='body2' className={classes.fineprint}>
                        {t('indication.thermage.title')}
                      </Typography>
                      <Typography variant='body2' className={classes.fineprint}>
                        {t('indication.thermage.copy')}
                      </Typography>
                      <Typography variant='body2' className={classes.fineprint}>
                        {t('indication.thermage.pointOne')}
                      </Typography>
                      <Typography variant='body2' className={classes.fineprint}>
                        {t('indication.thermage.pointTwo')}
                      </Typography>
                      <Typography variant='body2' className={classes.fineprint}>
                        {t('indication.thermage.copyTwo')}
                      </Typography>
                      <Typography variant='body2' className={classes.fineprint}>
                        {t('indication.thermage.pointThree')}
                      </Typography>
                      <Typography variant='body2' className={classes.fineprint}>
                        {t('indication.thermage.pointFour')}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className='meta-react'>
          <LogoHeader t={t} />
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div className={classes.root}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                      <Typography variant='h6' className={classes.CBLightBlue}>
                        {t('about.title')}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} align='center'>
                      <LoadingBlock />
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

ThermageEyePage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  //console.log('this is my state', state);
  return {
    //auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //setPromo: (promoID) => dispatch(setPromo(promoID)),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
  withTranslation(),
  firestoreConnect((props) => [])
)(ThermageEyePage);
