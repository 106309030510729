import rootSettings from '../../Styles/rootSettings';
import * as COLOURS from '../../Styles/colors';

//const thermageColor = '#45143F';
//const clearColor = '#077FB2';
//const fraxelColor = '#691E33';
//const vaserColor = '#0079C1';

const styles = {
  ...rootSettings,
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  customerName: {
    fontFamily: 'Montserrat',
    fontWeight: 300,
    color: 'rgba(40,58,89,1)',
    fontSize: 38,
    '@media (max-width: 1025px)': {
      fontSize: 28,
    },
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  logo: {
    marginTop: 30,
    width: 155,
    display: 'block',
    '@media (max-width: 1025px)': {
      width: 135,
      marginLeft: -40,
      marginTop: 0,
    },
  },
  image: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  alignCenter: {
    textAlign: 'center',
  },
  questionMark: {
    right: -50,
    display: 'block',
    position: 'absolute',
    top: 0,
    '@media (max-width: 1440px)': {
      right: -70,
    },
    '@media (max-width: 1300px)': {
      right: -85,
    },
    '@media (max-width: 1025px)': {
      right: -30,
    },
    '@media (max-width: 850px)': {
      right: -50,
    },
    '@media (max-width: 599px)': {
      right: -30,
    },
  },
  logoBlock: {
    position: 'relative',
    display: 'block',
  },
  mobileCenter: {
    textAlign: 'left',
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  spacingTop: {
    marginTop: 20,
  },
  companyLogo: {
    maxHeight: 100,
  },
  brandLogo: {
    maxWidth: 125,
    imageRendering: 'auto',
  },
  promoBlock: {
    marginBottom: 25,
  },
  GbtdLM8O9XZhqUI0AdpJ: {
    color: COLOURS.thermageLightColor,
  },
  IsEwUP633cNiauuLEZoT: {
    color: COLOURS.clearColor,
  },
  clinicInfoBlock: {
    marginBottom: 30,
  },
};

export default styles;
