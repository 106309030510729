import React from 'react';
import { ThermageButton } from './thermageButton';
import { ClearBrilliantButton } from './clearBrilliantButton';

const Buttons = (props) => {
  let brand = props.brand.technologies.filter((t) => t.id === props.brand.brandID);

  if (brand.length > 0) {
    switch (brand[0].brandName) {
      case 'Thermage':
        return <ThermageButton {...props}>{props.children}</ThermageButton>;
      case 'Clear + Brilliant':
        return <ClearBrilliantButton {...props}>{props.children}</ClearBrilliantButton>;
      default:
        return <ThermageButton {...props}>{props.children}</ThermageButton>;
    }
  } else {
    return <ThermageButton {...props}>{props.children}</ThermageButton>;
  }
};

export default Buttons;
