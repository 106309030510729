import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
//import { Link } from 'react-router-dom';
//import * as ROUTES from '../../constants/routes';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
//import ThermageLogo from '../../images/thermage_eyes.svg';
//import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Paper from '@material-ui/core/Paper';
import headerImage from '../../images/solta/xlg-desktop@2x.png';
import headerTabletImage from '../../images/solta/desktop@2x.png';
import headerMobileImage from '../../images/solta/mobile-header@2x.png';
//import SoltaLogo from '../../images/solta_logo.svg';
import Hidden from '@material-ui/core/Hidden';
import * as COLOURS from '../Styles/colors';

const styles = {
  root: {
    flexGrow: 1,
    height: '100%',
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  logo: {
    marginTop: 30,
    width: 155,
    '@media (max-width: 959px)': {
      //width: 125,
      marginTop: 50,
    },
    '@media (max-width: 599px)': {
      width: 125,
      marginTop: 0,
    },
  },
  purpleLine: {
    background: 'linear-gradient(90deg, rgba(67,22,63,1) 0%, rgba(155,62,150,1) 100%)',
    display: 'block',
    height: 10,
  },
  headerImage: {
    display: 'block',
    margin: 0,
    padding: 0,
  },
  headerBlock: {
    backgroundImage: `url(${headerImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#EDEDED',
    height: 420,
    display: 'block',
    backgroundSize: '100%',
    backgroundPosition: 'top right',
    '@media (max-width: 2550px)': {
      height: 350,
    },
    '@media (max-width: 2100px)': {
      height: 300,
    },
    '@media (max-width: 1840px)': {
      height: 250,
    },
    '@media (max-width: 1525px)': {
      height: 200,
    },
    '@media (max-width: 1220px)': {
      height: 175,
    },
    '@media (max-width: 1050px)': {
      height: 165,
    },
    '@media (max-width: 1000px)': {
      height: 160,
    },
  },
  headerTabletBlock: {
    backgroundImage: `url(${headerTabletImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#EDEDED',
    height: 250,
    display: 'block',
    backgroundSize: '75%',
    backgroundPosition: 'top right',
  },
  headerMobileBlock: {
    backgroundImage: `url(${headerMobileImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#F2F3F5',
    height: 215,
    backgroundSize: '100%',
    display: 'block',
    backgroundPosition: 'top right',
    '@media (max-width: 715px)': {
      height: 175,
    },
    '@media (max-width: 585px)': {
      height: 160,
    },
    '@media (max-width: 540px)': {
      height: 145,
    },
    '@media (max-width: 480px)': {
      height: 120,
    },
    '@media (max-width: 390px)': {
      height: 100,
    },
    '@media (max-width: 325px)': {
      height: 95,
    },
  },
  header: {
    height: 420,
  },
  soltaLogo: {
    width: 250,
    marginRight: 50,
    marginTop: 50,
    '@media (max-width: 1919px)': {
      marginRight: 0,
    },
    '@media (max-width: 1025px)': {
      width: 175,
    },
    '@media (max-width: 959px)': {
      width: 150,
      marginTop: 80,
    },
  },
  clearAndBrilliantLine: {
    background: 'rgba(40,58,89,1)',
    display: 'block',
    height: 10,
  },

  soltaLine: {
    background: COLOURS.soltaColor,
    display: 'block',
    height: 10,
  },
  whiteGradient: {
    //background: 'rgb(255,255,255)',
    background: 'linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 100%)',
    height: 60,
    display: 'block',
  },
  purpleGradient: {
    //background: 'rgb(255,255,255)',
    background: 'linear-gradient(0deg, rgba(255,255,255,0.45) 0%, rgba(204,156,201,0.45) 100%)',
    height: 60,
    display: 'block',
  },
  logoBlock: {
    position: 'relative',
    display: 'block',
    '@media (max-width: 1919px)': {
      marginTop: 30,
    },
    '@media (max-width: 599px)': {
      marginTop: 0,
    },
  },
  questionMark: {
    right: -50,
    display: 'block',
    position: 'absolute',
    color: ' rgba(255,255,255,0.45) 0% !important',
    top: 25,
    '@media (max-width: 1919px)': {
      right: '25%',
    },
    '@media (max-width: 1300px)': {
      right: '25%',
    },
    '@media (max-width: 959px)': {
      right: '10%',
      top: 40,
    },
    '@media (max-width: 599px)': {
      right: '25%',
      top: 0,
    },
    purple: {
      color: 'rgba(67,22,63,1) !important',
    },
    blue: {
      color: 'rgba(40,58,89,1) !important',
    },
  },
};

class ImageHeader extends Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Hidden lgDown>
            <Grid item xl={12} lg={12} xs={12} className={classes.headerBlock}></Grid>
            <Grid item lg={12} xs={12}>
              <Paper square elevation={0} className={classes.soltaLine}></Paper>
            </Grid>
          </Hidden>

          <Hidden xlUp smDown>
            <Grid item xl={12} lg={12} xs={12} className={classes.headerBlock}></Grid>
            <Grid item lg={12} xs={12}>
              <Paper square elevation={0} className={classes.soltaLine}></Paper>
            </Grid>
          </Hidden>

          <Hidden mdUp xsDown>
            <Grid item xs={12} className={classes.headerMobileBlock}></Grid>
            <Grid item xs={12}>
              <Paper square elevation={0} className={classes.soltaLine}></Paper>
            </Grid>
          </Hidden>

          <Hidden smUp>
            <Grid item xs={12} className={classes.headerMobileBlock}></Grid>
            <Grid item xs={12}>
              <Paper square elevation={0} className={classes.soltaLine}></Paper>
            </Grid>
          </Hidden>
        </Grid>
      </div>
    );
  }
}

ImageHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    //auth: state.firebase.auth,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //createEmailUser: (newUser) => dispatch(createEmailUser(newUser)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation())(ImageHeader);
