import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
//import { useSelector } from 'react-redux';
import { ClearBrilliantButton } from '../Buttons/clearBrilliantButton';
import LoadingBlock from '../Loading';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import firebase from 'firebase/app';
import Logos from '../Logos';
import Skeleton from '@material-ui/lab/Skeleton';

//import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 20,
    //paddingTop: 20,
    padding: 30,
    background: 'linear-gradient(0deg, rgba(155,62,149,0.15) 0%, rgba(155,62,149,0) 10%, rgba(155,62,149,0) 90%, rgba(155,62,149,0.15) 100%)',
  },

  //Thermage Backgrounds
  GbtdLM8O9XZhqUI0AdpJroot: {
    flexGrow: 1,
    marginTop: 20,
    //paddingTop: 20,
    padding: 30,
    background: 'linear-gradient(0deg, rgba(155,62,149,0.15) 0%, rgba(155,62,149,0) 10%, rgba(155,62,149,0) 90%, rgba(155,62,149,0.15) 100%)',
  },

  GbtdLM8O9XZhqUI0AdpJcolor: {
    color: '#9b3e96',
  },

  //Clear & Brillaint Background
  IsEwUP633cNiauuLEZoTroot: {
    flexGrow: 1,
    marginTop: 20,
    marginBottom: 20,
    //paddingTop: 20,
    padding: 30,
    background: 'linear-gradient(0deg, rgba(40,58,89,0.25) 0%, rgba(40,58,89,0) 10%, rgba(40,58,89,0) 90%, rgba(40,58,89,0.25) 100%)',
  },

  IsEwUP633cNiauuLEZoTcolor: {
    color: 'rgba(40,58,89,1)',
  },

  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },

  alignCenter: {
    textAlign: 'center',
  },
  mobileCenter: {
    '@media (max-width: 599px)': {
      textAlign: 'center',
    },
  },
  anotherClinic: {
    opacity: '0.5',
    marginBottom: 20,
  },
  purple: {
    color: '#9b3e96',
  },
  spacingTop: {
    marginTop: 20,
  },
  dividerMargins: {
    marginTop: 30,
    marginBottom: 30,
  },
  brandLogo: {
    maxWidth: 200,
  },
}));

function ClinicBlockConst({ props, promoID, promo }) {
  const classes = useStyles();
  const [promoClinics, setPromoClinic] = useState(null);

  const [technologies, setTechnologies] = useState(null);

  useEffect(() => {
    async function getCompanyInfo(props, promoID) {
      if (props.profile.promos[promoID].clinic) {
        let company = await firebase.firestore().collection('companies').doc(props.profile.promos[promoID].clinic).get();
        if (company.exists) {
          setPromoClinic(company.data());
        }

        let techGroup = [];
        let tech = await firebase.firestore().collection('technologies').get();
        if (!tech.empty) {
          tech.docs.map((t) => {
            return techGroup.push({ id: t.id, ...t.data() });
          });

          return setTechnologies([...techGroup]);
        }
      }
    }

    getCompanyInfo(props, promoID);
  }, [props, promoID]);

  const sendEmailToClinic = (clinicID, clinic) => {
    //console.log('We will program an email to go out to both parties', this.props.promoClinics[0].id);
    props.sendContactEmail({ userID: props.auth.uid, promoID: promoID, clinicID: clinicID, user: props.profile, company: clinic });
  };

  const pickAnotherClinic = () => {
    props.removeClinicToMyPromo({ userID: props.auth.uid, promoID: promoID });
  };

  const sendPromoCodeEmail = async (promo) => {
    //console.log('lets send an email wiht your promo code in it.', promo);
    //console.log('this is my profile', profile.promos[promo.id].promoCode);
    //console.log('this is my profile ', profile);
    try {
      await firebase
        .firestore()
        .collection('mail')
        .add({
          cc: [profile.email],
          message: {
            subject: `Your Promo Code for ${promo['name' + props.i18n.language]}`,
            text: `Hi ${profile.firstName ? profile.firstName : profile.email}, Here is your promo code for the following promotion - ${promo['name' + props.i18n.language]}:<br/><br/> ${profile.promos[promo.id].promoCode}. Please note that the clinic will need your promo code when you book your appointment.`,
            html: `Hi ${profile.firstName ? profile.firstName : profile.email},<br/><br/> Here is your promo code for the following promotion - ${promo['name' + props.i18n.language]}:<br/><br/> <b>${profile.promos[promo.id].promoCode}</b>.<br/><br/> Please note that the clinic will need your promo code when you book your appointment. `,
          },
        });
    } catch (err) {
      console.log('this is an error', err);
    }
  };

  /* const findClinicInfo = (clinicID) => {
    if (props.promoClinics.length > 0) {
      return props.promoClinics.filter((p) => (p ? p.id === clinicID : ''));
    }
  } */

  const { t, profile } = props;

  if (profile) {
    let promoClaimed = profile.promos[promoID].promoCodeClaimed;
    if (promoClinics) {
      let company = promoClinics;
      let lang = props.i18n.language;

      return (
        <div className={profile.promos[promoID].brand ? classes[profile.promos[promoID].brand + 'root'] : classes.root}>
          <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={12} align='center'>
              {technologies ? <Logos brand={{ brandID: promo.promoTechnology, technologies: technologies }} className={classes.brandLogo} /> : <Skeleton variant='rect' width={100} height={100} />}
            </Grid>
            <Grid item xs={12} align='center' className={classes.spacingTop}>
              <Typography variant='h6' gutterBottom>
                {promo['name' + lang]}
              </Typography>
              <Typography variant='body1' gutterBottom>
                {promo['description' + lang]}
              </Typography>

              {profile.promos[promoID].treatmentDate ? (
                <Typography variant='h5' gutterBottom>
                  {t('clinicBlocks.treatmentDate')}
                  {moment(profile.promos[promoID].treatmentDate.toDate()).format('MMMM Do, YYYY')}
                </Typography>
              ) : (
                <div>
                  <Typography variant='h5' gutterBottom>
                    {/* {t('clinicBlocks.promoCode')}
                  {profile.promos[promoID].promoCode} */}
                    Your Promo Code has been emailed to you
                  </Typography>
                  <Button onClick={() => sendPromoCodeEmail(promo)} style={{ marginTop: 10, marginBottom: 15 }}>
                    Resend Promo Code Email
                  </Button>
                </div>
              )}
              <Typography variant='body1'>{promoClaimed ? t('clinicBlocks.promoClaimed') : t('clinicBlocks.pleaseShow', { date: moment(promo.promoEndDate.toDate()).format('MMMM Do, YYYY') })}</Typography>
            </Grid>
            <Grid item xs={2} align='center' className={classes.spacingTop}>
              <Divider />
            </Grid>
            <Grid item xs={12} align='center'>
              <Typography variant='h4' className={profile.promos[promoID].brand ? classes[profile.promos[promoID].brand + 'color'] : classes.purple}>
                {company.name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container item spacing={2}>
                <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                  <Typography variant='body1'>
                    <b>{t('clinicBlocks.location')}</b>
                  </Typography>
                </Grid>
                <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                  <Typography variant='body1'>
                    {company.location.streetAddress}
                    {company.location.streetAddress2}
                  </Typography>
                </Grid>
                <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                  <Typography variant='body1'>
                    <b>{t('clinicBlocks.city')}</b>
                  </Typography>
                </Grid>
                <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                  <Typography variant='body1'>{company.location.city}</Typography>
                </Grid>
                <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                  <Typography variant='body1'>
                    <b>{t('clinicBlocks.phone')}</b>
                  </Typography>
                </Grid>
                <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                  <Typography variant='body1'>
                    <a href={`tel://${company.phoneNumber}`} target='_blank' rel='noopener noreferrer'>
                      {company.phoneNumber}
                    </a>
                  </Typography>
                </Grid>
                <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                  <Typography variant='body1'>
                    <b>{t('clinicBlocks.email')}</b>
                  </Typography>
                </Grid>
                <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                  <Typography variant='body1'>
                    <a href={`mailto:${company.email}`} target='_blank' rel='noopener noreferrer'>
                      {company.email}
                    </a>
                  </Typography>
                </Grid>
                <Grid item sm={2} xs={12} className={classes.mobileCenter}>
                  <Typography variant='body1'>
                    <b>{t('clinicBlocks.website')}</b>
                  </Typography>
                </Grid>
                <Grid item sm={10} xs={12} className={classes.mobileCenter}>
                  <Typography variant='body1'>
                    <a href={`${company.website}`} target='_blank' rel='noopener noreferrer'>
                      {company.website}
                    </a>
                  </Typography>
                </Grid>
                <Grid item xs={5}></Grid>
                <Grid item xs={2} align='center'>
                  <Divider />
                </Grid>
                {promoClaimed ? null : (
                  <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12} align='center' className={classes.spacingTop}>
                      <Typography variant='body2'>{t('clinicBlocks.emailSent')}</Typography>

                      <Typography variant='body2'>{t('clinicBlocks.emailSentTwo')}</Typography>
                    </Grid>
                    <Grid item xs={12} align='center'>
                      <ClearBrilliantButton variant='contained' color='primary' onClick={() => sendEmailToClinic(company[0].id, company)} className={classes.margin}>
                        {t('clinicBlocks.contact')}
                      </ClearBrilliantButton>
                    </Grid>
                    <Grid item xs={12} align='center'>
                      <Button onClick={pickAnotherClinic} className={classes.anotherClinic}>
                        {t('clinicBlocks.pickAnother')}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    } else {
      return <LoadingBlock />;
    }
  } else {
    return <LoadingBlock />;
  }
}

export default ClinicBlockConst;
