/*****************************************************************************/
/*  File Name: clinicCodeLandingPage.js                                      */
/*  Date:      July 22, 2021                                                 */
/*  Author:    Christopher Cressman                                          */
/*  Purpose:   This is the updated clinic landing page. We have converted    */
/*             the old class component from clinicCodeLanding.js             */
/*             This will list all of the promos that a clinic now offers     */
/*****************************************************************************/

import React, { useState, useEffect } from 'react';
//import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
//import firebase from 'firebase/app';
import styles from './styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import LoadingBlock from '../Loading';
import LogoHeader from '../Header';
import firebase from 'firebase/app';
import { getCompanyLogo } from './actions';
import ClinicInfo from './clinicInfo';
import ClinicPromos from './clinicPromos';

const useStyles = makeStyles((theme) => ({
  ...styles,
}));

function ClinicCodeLandingPage(props) {
  //Load all of the default component varaibles
  const t = props.t;
  //const today = new Date();
  const companyCode = props.match.params.id;
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState([]);
  const [companyLogo, setCompanyLogo] = useState('');
  const [companyPromos, setCompanyPromos] = useState([]);
  const [promoTechnology, setPromoTechnology] = useState([]);

  //Load all of the prop variables
  //const auth = useSelector((state) => state.firebase.auth);
  //const profile = useSelector((state) => state.firebase.profile);
  //const props = useSelector((state) => state);

  //console.log('these are my props', props);

  useEffect(() => {
    async function getCompanyInfo(params) {
      //First thing we need to do is laod the company info
      let company = await firebase.firestore().collection('companies').where('companyCode', '==', companyCode).get();

      if (!company.empty) {
        //We have found a company now we can get their promos and logo
        //Lets get the logo
        let clinic = company.docs[0].data();

        let companyLogo = await getCompanyLogo(clinic.logo);
        //console.log('this my company logo', companyLogo);
        //Set the company logo
        setCompanyLogo(companyLogo);
        //Set the company Data
        setCompany({ id: company.docs[0].id, ...clinic });

        //Now we will want to get the promos that the clinic is enrolled in
        //console.log('this is my clinics promos ---> ', clinic.promos);
        let clinicPromos = [];

        let promoTech = [];
        let promoTechID = [];

        await Promise.all(
          clinic.promos.map(async (promoID) => {
            let promo = await firebase.firestore().collection('promos').doc(promoID).get();

            if (promo.exists) {
              //We do not want to load any promos that have completed
              //We will compare them with todays date
              if (new Date(promo.data().promoEndDate.toDate()) >= new Date() && promo.id !== '8pH8XQUL4SbOUIUvVlWg') {
                //console.log('this is my promo -->', promo.data());
                clinicPromos.push({ id: promo.id, ...promo.data() });

                // If we have not already grabbed the technology then we will
                // grab the details so we can use their resources
                if (!promoTechID.includes(promo.data().promoTechnology)) {
                  let tech = await firebase.firestore().collection('technologies').doc(promo.data().promoTechnology).get();

                  if (tech.exists) {
                    promoTech.push({ id: tech.id, ...tech.data() });
                    promoTechID.push(promo.data().promoTechnology);
                  }
                }
              }
            }
            return true;
          })
        );

        setCompanyPromos([...clinicPromos]);
        setPromoTechnology([...promoTech]);

        //console.log('this is my clinics promos', clinicPromos);
      } else {
        //There is no company with that information
        setCompany(null);
      }
      setLoading(false);
    }

    getCompanyInfo();
  }, [companyCode]);

  //console.log('this is my comapny ---> ', company);
  //console.log('this is my promos ---> ', companyPromos);

  return (
    <div className='meta-react'>
      <LogoHeader t={t} />
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className={classes.root}>
              <Grid item xs container spacing={2} alignItems='center' justifyContent='center'>
                <Grid item xs={8}>
                  <Typography variant='h4'>{company.name}</Typography>
                </Grid>
                <Grid item xs={4} align='right'>
                  <img src={companyLogo} alt={company.name} className={classes.companyLogo} />
                </Grid>
                <Grid item xs={12}>
                  <Divider className={classes.dividerSpacing} />
                </Grid>
                <Grid item xs={12} container>
                  {!loading ? (
                    company !== null ? (
                      <Grid item xs={12} container>
                        <Grid item sm={6} xs={12} container spacing={2} className={classes.clinicInfoBlock}>
                          <ClinicInfo company={company} />
                        </Grid>

                        <Grid item sm={6} xs={12} container spacing={2}>
                          <ClinicPromos company={company} companyPromos={companyPromos} promoTechnology={promoTechnology} props={props} />
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid item xs={12}>
                        <Typography variant='h6'>We are sorry there is no company found with that code</Typography>
                      </Grid>
                    )
                  ) : (
                    <Grid item xs={12} align='center'>
                      <LoadingBlock />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(ClinicCodeLandingPage);
