import { Redirect, Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { blue, grey } from '@material-ui/core/colors';

import GoogleLogo from '../../images/icons/GoogleLogo';

import EmailIcon from '@material-ui/icons/Email';
import Paper from '@material-ui/core/Paper';
import { createEmailUser, signUpWithFacebook, signUpWithGoogle, closeAuthPopUps, createQCEmailUser } from '../../actions/authActions';
import headerImage from '../../images/1x/header-image-desktop.jpg';
import ImageHeader from '../Header/imageHeader';
//import { FacebookLoginButton, GoogleLoginButton, createButton, createSvgIcon } from 'react-social-login-buttons';
//import EmailSvg from '../../images/icons/mail.svg';
//import { MDBBtn, MDBIcon, MDBContainer } from 'mdbreact';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
//import NBAward from '../../images/NB_AwardSeal_2021.svg';
import * as PROVINCES from '../../constants/provinces';
import NativeSelect from '@material-ui/core/NativeSelect';
//import LogoHeader from '../Header';

const styles = {
  root: {
    flexGrow: 1,
    height: '100%',
    marginTop: 60,
  },
  paper: {
    padding: 2,
    textAlign: 'left',
    color: '#000',
  },
  logo: {
    marginTop: 30,
    width: 155,
    '@media (max-width: 1025px)': {
      width: 100,
      marginTop: 26,
    },
  },
  button: {
    width: '100%',
    textAlign: 'left',
  },
  textField: {
    width: '100%',
  },
  alignCenter: {
    textAlign: 'center',
  },
  purpleLine: {
    background: 'linear-gradient(90deg, rgba(67,22,63,1) 0%, rgba(155,62,150,1) 100%)',
    display: 'block',
    height: 10,
  },
  headerImage: {
    display: 'block',
    margin: 0,
    padding: 0,
  },
  headerBlock: {
    backgroundImage: `url(${headerImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#EDEDED',
    height: 400,
    display: 'block',
  },
  header: {
    height: 400,
  },
  soltaLogo: {
    width: 250,
    marginRight: 50,
    marginTop: 50,
  },
  purpleGradient: {
    //background: 'rgb(255,255,255)',
    background: 'linear-gradient(0deg, rgba(255,255,255,0.45) 0%, rgba(204,156,201,0.45) 100%)',
    height: 60,
    display: 'block',
  },
};

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const INITIAL_STATE = {
  //loading: false,
  email: false,
  firstName: '',
  lastName: '',
  phoneNumber: '',
  phoneOpt: false,
  emailAddress: '',
  password: '',
  reTypePassword: '',
  regPopUp: false,
  regMessage: null,
  showPassword: false,
  passwordType: 'password',
  showRetypePass: false,
  retypePasswordType: 'password',
  canadianProvinces: [PROVINCES.ONTARIO, PROVINCES.QUEBEC, PROVINCES.BRITISH_COLUMBIA, PROVINCES.ALBERTA, PROVINCES.NOVA_SCOTIA, PROVINCES.NEW_BRUNSWICK, PROVINCES.MANITOBA, PROVINCES.PRINCE_EDWARD_ISLAND, PROVINCES.SASKATCHEWAN, PROVINCES.NEWFOUNDLAND],
  userProvince: '',
};

class SignUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
  }

  componentWillUnmount() {}

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleCheck = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  addEmailUser = () => {
    /// check phone stuff
    if (this.state.phoneNumber === '') {
      this.setState({ phoneOpt: false });
    } else if (this.state.phoneNumber !== '') {
      this.setState({ phoneOpt: true });
    }
    if (this.state.password === this.state.reTypePassword) {
      this.props.createEmailUser(this.state);
    } else {
      this.setState({
        regPopUp: true,
        regMessage: this.props.t('signUp.passwordsDontMatch'),
      });
    }
  };

  addEmailUserQC = () => {
    /// check phone stuff

    if (this.state.password === this.state.reTypePassword) {
      this.props.createQCEmailUser(this.state);
    } else {
      this.setState({
        regPopUp: true,
        regMessage: this.props.t('signUp.passwordsDontMatch'),
      });
    }
  };

  addFacebookUser = () => {
    this.props.signUpWithFacebook();
  };

  addGoogleUser = () => {
    this.props.signUpWithGoogle();
  };

  startEmail = () => {
    this.setState({
      email: true,
    });
  };

  closeAuthPopUps = () => {
    this.props.closeAuthPopUps();
  };

  closeRegPopUps = () => {
    this.setState({
      regPopUp: false,
      regMessage: null,
    });
  };

  showMyPassword = () => {
    let show = this.state.showPassword ? false : true;
    let pass = show ? 'text' : 'password';
    this.setState({
      showPassword: show,
      passwordType: pass,
    });
  };

  showMyRetypePassword = () => {
    let show = this.state.showRetypePass ? false : true;
    let pass = show ? 'text' : 'password';
    this.setState({
      showRetypePass: show,
      retypePasswordType: pass,
    });
  };

  render() {
    const { email, firstName, lastName, emailAddress, password, reTypePassword, regPopUp, regMessage, showPassword, passwordType, retypePasswordType, showRetypePass, phoneNumber, userProvince, canadianProvinces } = this.state;
    const { auth, t, classes, authError, authMessage, authErrorPopUp, authPopUp } = this.props;

    if (this.props.match.params.id) {
      //console.log('we will redirect to my page', this.props.match.params.id);

      if (this.props.match.params.id === 'dermapure') {
        if (auth.uid) return <Redirect to={ROUTES.NATIONAL + this.props.match.params.id} />;
      } else {
        if (auth.uid) return <Redirect to={ROUTES.CLINIC + this.props.match.params.id} />;
      }
    } else {
      if (auth.uid) return <Redirect to={ROUTES.LANDING} />;
    }

    const formFiledOut = firstName.length > 0 && lastName.length > 0 && emailAddress.length > 0 && password.length >= 8 && reTypePassword.length >= 8;
    const formFiledOutQC = emailAddress.length > 0 && password.length >= 8 && reTypePassword.length >= 8;

    const passwordError = password.length < 8 && password !== '';
    const retypePasswordError = reTypePassword !== password && reTypePassword !== '';

    //const {
    //loading
    //} = this.state;
    //console.log('this is the home page props', this.props);

    return (
      <div className='meta-react'>
        <ImageHeader />
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className={classes.root}>
                <Grid container spacing={3} justifyContent='center'>
                  {/* <Grid item xs={12} align='center'>
                    <img src={NBAward} alt='New Beauty Award' style={{ height: 200 }} />
                    <Typography variant='body1' style={{ textTransform: 'uppercase', fontSize: 18, marginTop: 10, color: '#293b59' }}>
                      {t('signUp.bestLaser')}
                    </Typography>
                  </Grid> */}
                  <Grid item xs={6} align='center'>
                    <Typography variant='h5' align='center'>
                      {t('signUp.title')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3} alignItems='center' justifyContent='center'>
                      <Grid item sm={1} xs={2}>
                        <Divider />
                      </Grid>
                    </Grid>
                  </Grid>
                  {email ? (
                    <Grid item xs={12}>
                      <Grid container spacing={3} justifyContent='center'>
                        <Grid item sm={4}></Grid>
                        <Grid item sm={4} xs={12} align='center'>
                          {userProvince === '' ? (
                            <FormControl className={classes.formControl}>
                              <InputLabel htmlFor='age-native-helper'>{t('signUp.yourProvince')}</InputLabel>
                              <NativeSelect onChange={this.onChange} name='userProvince' value={userProvince}>
                                <option value=''></option>
                                {canadianProvinces.map((p) => (
                                  <option key={p.abbrev} value={p.abbrev}>
                                    {p.name}
                                  </option>
                                ))}
                              </NativeSelect>
                            </FormControl>
                          ) : userProvince === PROVINCES.QUEBEC.abbrev ? (
                            <form>
                              <Grid container spacing={3} alignItems='center' justifyContent='center'>
                                <Grid item xs={12}>
                                  <TextField className={classes.textField} onChange={this.onChange} id='emailAddress' name='emailAddress' autoComplete='username' value={emailAddress} label='Email' />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl>
                                    <InputLabel htmlFor='password'>Password</InputLabel>
                                    <Input
                                      className={classes.textField}
                                      onChange={this.onChange}
                                      error={passwordError}
                                      id='password'
                                      name='password'
                                      type={passwordType}
                                      autoComplete='new-password'
                                      value={password}
                                      endAdornment={
                                        <InputAdornment position='end'>
                                          <IconButton aria-label='toggle password visibility' onClick={this.showMyPassword}>
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                    />
                                    <FormHelperText id='reTypePassword'>{t('signUp.passwordRestrictions')}</FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl>
                                    <InputLabel htmlFor='reTypePassword'>Re-Type Password</InputLabel>
                                    <Input
                                      className={classes.textField}
                                      onChange={this.onChange}
                                      error={retypePasswordError}
                                      id='reTypePassword'
                                      name='reTypePassword'
                                      type={retypePasswordType}
                                      autoComplete='new-password'
                                      value={reTypePassword}
                                      endAdornment={
                                        <InputAdornment position='end'>
                                          <IconButton aria-label='toggle password visibility' onClick={this.showMyRetypePassword}>
                                            {showRetypePass ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                    />
                                    <FormHelperText id='reTypePassword'>{t('signUp.passwordReType')}</FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                  <Paper elevation={0} className={classes.alignCenter}>
                                    <ThermageButton size='large' disabled={!formFiledOutQC} onClick={this.addEmailUserQC}>
                                      {t('signUp.button')}
                                    </ThermageButton>
                                  </Paper>
                                </Grid>
                              </Grid>
                            </form>
                          ) : (
                            <form>
                              <Grid container spacing={3} alignItems='center' justifyContent='center'>
                                <Grid item xs={12}>
                                  <TextField className={classes.textField} onChange={this.onChange} id='firstName' name='firstName' value={firstName} label='First Name' />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField className={classes.textField} onChange={this.onChange} id='lastName' name='lastName' value={lastName} label='Last Name' />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField className={classes.textField} onChange={this.onChange} id='phoneNumber' name='phoneNumber' value={phoneNumber} label='Phone Number' />
                                </Grid>
                                <Grid item xs={12}>
                                  <TextField className={classes.textField} onChange={this.onChange} id='emailAddress' name='emailAddress' autoComplete='username' value={emailAddress} label='Email' />
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl>
                                    <InputLabel htmlFor='password'>Password</InputLabel>
                                    <Input
                                      className={classes.textField}
                                      onChange={this.onChange}
                                      error={passwordError}
                                      id='password'
                                      name='password'
                                      type={passwordType}
                                      autoComplete='new-password'
                                      value={password}
                                      endAdornment={
                                        <InputAdornment position='end'>
                                          <IconButton aria-label='toggle password visibility' onClick={this.showMyPassword}>
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                    />
                                    <FormHelperText id='reTypePassword'>{t('signUp.passwordRestrictions')}</FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                  <FormControl>
                                    <InputLabel htmlFor='reTypePassword'>Re-Type Password</InputLabel>
                                    <Input
                                      className={classes.textField}
                                      onChange={this.onChange}
                                      error={retypePasswordError}
                                      id='reTypePassword'
                                      name='reTypePassword'
                                      type={retypePasswordType}
                                      autoComplete='new-password'
                                      value={reTypePassword}
                                      endAdornment={
                                        <InputAdornment position='end'>
                                          <IconButton aria-label='toggle password visibility' onClick={this.showMyRetypePassword}>
                                            {showRetypePass ? <Visibility /> : <VisibilityOff />}
                                          </IconButton>
                                        </InputAdornment>
                                      }
                                    />
                                    <FormHelperText id='reTypePassword'>{t('signUp.passwordReType')}</FormHelperText>
                                  </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                  <Paper elevation={0} className={classes.alignCenter}>
                                    <ThermageButton size='large' disabled={!formFiledOut} onClick={this.addEmailUser}>
                                      {t('signUp.button')}
                                    </ThermageButton>
                                  </Paper>
                                </Grid>
                              </Grid>
                            </form>
                          )}
                        </Grid>
                        <Grid item xs={4}></Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      <Grid container spacing={3} justifyContent='center'>
                        <Grid item md={4} xs={12}>
                          <Grid container spacing={3} justifyContent='center'>
                            <Grid item xs={12}>
                              <Button variant='contained' size='large' className={classes.button} onClick={this.startEmail} startIcon={<EmailIcon />}>
                                {t('signUp.signUpEmail')}
                              </Button>
                            </Grid>

                            <Grid item xs={12}>
                              <Button variant='contained' size='large' className={classes.button} onClick={this.addGoogleUser} startIcon={<GoogleLogo />}>
                                {t('signUp.signUpGoogle')}
                              </Button>
                            </Grid>

                            <Grid item xs={12} align='center'>
                              <Typography variant='h6'>{t('signUp.alreadySignedUp')}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <Divider />
                            </Grid>
                            <Grid item xs={12} align='center'>
                              <Button variant='contained' size='large' className={classes.button} component={Link} to={ROUTES.LOGIN}>
                                {t('signUp.login')}
                              </Button>
                            </Grid>
                            <Grid item xs={12} align='center'>
                              <Typography variant='body2'>{t('signUp.legal')}</Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </div>
              <Snackbar open={authPopUp} autoHideDuration={6000} onClose={this.closeAuthPopUps}>
                <Alert onClose={this.closeAuthPopUps} severity='success'>
                  {authMessage}
                </Alert>
              </Snackbar>
              <Snackbar open={authErrorPopUp} autoHideDuration={6000} onClose={this.closeAuthPopUps}>
                <Alert onClose={this.closeRegPopUps} severity='error'>
                  {authError}
                </Alert>
              </Snackbar>
              <Snackbar open={regPopUp} autoHideDuration={6000} onClose={this.closeRegPopUps}>
                <Alert onClose={this.closeRegPopUps} severity='error'>
                  {regMessage}
                </Alert>
              </Snackbar>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ThermageButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
    '&:disabled': {
      color: '#fff',
      backgroundColor: grey[500],
    },
  },
}))(Button);

SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    authError: state.auth.authError,
    authMessage: state.auth.authMessage,
    authPopUp: state.auth.authPopUp,
    authErrorPopUp: state.auth.authErrorPopUp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createEmailUser: (newUser) => dispatch(createEmailUser(newUser)),
    signUpWithFacebook: () => dispatch(signUpWithFacebook()),
    signUpWithGoogle: () => dispatch(signUpWithGoogle()),
    closeAuthPopUps: () => dispatch(closeAuthPopUps()),
    createQCEmailUser: (newUser) => dispatch(createQCEmailUser(newUser)),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles), withTranslation())(SignUp);
