/*****************************************************************************/
/*  File Name: clinicLogin.js                                                */
/*  Date:      Aug 12, 2021                                                  */
/*  Author:    Christopher Cressman                                          */
/*  Purpose:   We will use this block for the clinic logins it needs to      */
/*             be a little simplified                                        */
/*                                                                           */
/*****************************************************************************/

import React, { useState, useEffect } from 'react'; //useState,
import { useSelector, useDispatch } from 'react-redux';

import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import SignInOptions from './components/options';
import styles from './styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import Divider from '@material-ui/core/Divider';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '../Alert';
import { closeAuthPopUps } from '../../actions/authActions';

const useStyles = makeStyles((theme) => ({
  ...styles,
}));

function ClinicLogin({ company, promoID, props, setShowLogin, promoTechnology, promo }) {
  //const t = props.t;

  const classes = useStyles();
  const [signInType, setSignInType] = useState('start');
  const dispatch = useDispatch();

  //For the popups
  const authPopUp = useSelector((props) => props.auth.authPopUp);
  const authMessage = useSelector((props) => props.auth.authMessage);
  const authErrorPopUp = useSelector((props) => props.auth.authErrorPopUp);
  const authError = useSelector((props) => props.auth.authError);
  const regPopUp = useSelector((props) => props.auth.regPopUp);
  const regMessage = useSelector((props) => props.auth.regMessage);

  useEffect(() => {}, []);

  const closePopUps = () => {
    dispatch(closeAuthPopUps());
  };

  return (
    <div className='clinic-login root'>
      <Grid item xs container spacing={1}>
        <Grid item xs={12} align='center'>
          <Typography variant='h6'>Please create an account or login to get your promo code for:</Typography>
          <Typography variant='h5'>{promo['name' + props.i18n.language] ? promo['name' + props.i18n.language] : promo.nameen}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider className={classes.dividerSpacing} />
        </Grid>
        <Grid item xs={12}>
          <SignInOptions signInType={signInType} props={props} setSignInType={setSignInType} company={company} promoID={promoID} setShowLogin={setShowLogin} promoTechnology={promoTechnology} promo={promo} />
        </Grid>
      </Grid>
      <Snackbar open={authPopUp} autoHideDuration={6000} onClose={closePopUps}>
        <Alert onClose={closePopUps} severity='success'>
          {authMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={authErrorPopUp} autoHideDuration={6000} onClose={closePopUps}>
        <Alert onClose={closePopUps} severity='error'>
          {authError}
        </Alert>
      </Snackbar>
      <Snackbar open={regPopUp} autoHideDuration={6000} onClose={closePopUps}>
        <Alert onClose={closePopUps} severity='error'>
          {regMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default withTranslation()(ClinicLogin);
