/*****************************************************************************/
/*  File Name: signUp.js                                                     */
/*  Date:      Aug 12, 2021                                                  */
/*  Author:    Christopher Cressman                                          */
/*  Purpose:   This is the sign up components for everyone that doesn't      */
/*             live in QC                                                    */
/*                                                                           */
/*****************************************************************************/

import React, { useState, useEffect } from 'react'; //useState,
import { useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styles from '../styles';
import Grid from '@material-ui/core/Grid';
//import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import { SoltaButton } from '../../Buttons/soltaButton';
import * as PROVINCES from '../../../constants/provinces';
import NativeSelect from '@material-ui/core/NativeSelect';
import firebase from 'firebase/app';
import moment from 'moment';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import * as ROUTES from '../../../constants/routes';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  ...styles,
}));

function SignUp({ company, promo, props, setShowLogin }) {
  const t = props.t;
  const dispatch = useDispatch();
  const classes = useStyles();
  const canadianProvinces = [PROVINCES.ONTARIO, PROVINCES.QUEBEC, PROVINCES.BRITISH_COLUMBIA, PROVINCES.ALBERTA, PROVINCES.NOVA_SCOTIA, PROVINCES.NEW_BRUNSWICK, PROVINCES.MANITOBA, PROVINCES.PRINCE_EDWARD_ISLAND, PROVINCES.SASKATCHEWAN, PROVINCES.NEWFOUNDLAND];

  const [userProvince, setUserProvince] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [reTypePassword, setReTypePassword] = useState('');
  const [passwordType, setPasswordType] = useState('password');
  const [showPassword, setShowPassword] = useState(false);
  const [retypePasswordType, setRetypePasswordType] = useState('password');
  const [showRetypePass, setShowRetypePass] = useState(false);
  const [termsServices, setTermsServices] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {}, []);

  const showMyPassword = () => {
    setPasswordType(showPassword ? 'password' : 'text');
    setShowPassword(showPassword ? false : true);
  };

  const showMyRetypePassword = () => {
    setRetypePasswordType(showRetypePass ? 'password' : 'text');
    setShowRetypePass(showRetypePass ? false : true);
  };

  const addEmailUser = async () => {
    //First lets trigger the auth with analytics
    firebase.analytics().logEvent('sign_up', { method: 'Email' });

    //Then lets create the user based on province
    let newAuth = null;
    //let newUser = null;

    try {
      //Lets create our auth user
      newAuth = await firebase.auth().createUserWithEmailAndPassword(emailAddress, password);

      if (userProvince !== 'QC') {
        try {
          await firebase
            .firestore()
            .collection('consumer')
            .doc(newAuth.user.uid)
            .set({
              firstName: firstName,
              lastName: lastName,
              fullName: firstName + ' ' + lastName,
              initials: firstName[0] + lastName[0],
              email: emailAddress,
              role: 'consumer',
              userAdded: firebase.firestore.FieldValue.serverTimestamp(),
              phoneNumber: phoneNumber,
              province: userProvince,
              phoneOpt: phoneNumber ? true : false,
              agreeToTerms: termsServices,
              agreeToTermsDate: firebase.firestore.FieldValue.serverTimestamp(),
            });
        } catch (err) {
          dispatch({ type: 'NEW_USER_ERROR', err });
        }
      } else {
        try {
          await firebase.firestore().collection('consumer').doc(newAuth.user.uid).set({
            email: emailAddress,
            fullName: emailAddress,
            role: 'consumer',
            userAdded: firebase.firestore.FieldValue.serverTimestamp(),
            province: userProvince,
            phoneOpt: false,
            agreeToTerms: termsServices,
            agreeToTermsDate: firebase.firestore.FieldValue.serverTimestamp(),
          });
        } catch (err) {
          dispatch({ type: 'NEW_USER_ERROR', err });
        }
      }
    } catch (err) {
      dispatch({ type: 'NEW_USER_ERROR', err });
    }

    //If we have a company and companyPromo Present then we need to add in
    //Promo code for the clinic
    //console.log('this is my company --> ', company);
    //console.log('this is my promo ------>', promo);

    if (company && promo) {
      //Lets tag this event
      firebase.analytics().logEvent('get_promo_code', { name: 'getCode', user: newAuth.user.uid, promo: promo.id });

      //Lets get all of the codes
      let promoCodeResp = await firebase.firestore().collection('promoCodes').doc('freeCodes').get();

      //Lets get the first code
      const promoCode = promoCodeResp.data().codes[0];

      //This is to add the promo in
      const promoPath = 'promos.' + promo.id;

      //Add the promo code to the new user
      await firebase
        .firestore()
        .collection('consumer')
        .doc(newAuth.user.uid)
        .update({
          [promoPath]: {
            promoCode: promoCode,
            brand: promo.promoTechnology,
            promoCodeClaimed: false,
            clinic: company.id,
          },
        });

      //Now we need to filter out the used promo code
      const unusedPromoCodes = promoCodeResp.data().codes.filter((code) => code !== promoCode);

      //Add the updated list of codes
      await firebase.firestore().collection('promoCodes').doc('freeCodes').set({
        codes: unusedPromoCodes,
      });

      await firebase
        .firestore()
        .collection('promoCodes')
        .doc('promoCodesLeft')
        .update({
          count: firebase.firestore.FieldValue.increment(-1),
        });

      await firebase
        .firestore()
        .collection('promoCodes')
        .doc('usedCodes')
        .update({
          codes: firebase.firestore.FieldValue.arrayUnion(promoCode),
        });

      await firebase
        .firestore()
        .collection('mail')
        .add({
          cc: [emailAddress],
          message: {
            subject: `Your Promo Code for ${promo['name' + props.i18n.language]}`,
            text: `Hi ${firstName ? firstName : emailAddress}, Here is your promo code for the following promotion - ${promo['name' + props.i18n.language]}:<br/><br/> ${promoCode}. Please note that the clinic will need your promo code when you book your appointment.`,
            html: `Hi ${firstName ? firstName : emailAddress},<br/><br/> Here is your promo code for the following promotion - ${promo['name' + props.i18n.language]}:<br/><br/> <b>${promoCode}</b>.<br/><br/> Please note that the clinic will need your promo code when you book your appointment. `,
          },
        });

      //Now lets send out an email
      await firebase
        .firestore()
        .collection('mail')
        .add({
          cc: [emailAddress, company.email],
          message: {
            subject: 'You have linked your promo with a clinic',
            text: `Congratulations ${firstName ? firstName : emailAddress}, you have linked your ${promo.nameen} with the following clinic: ${company.name}. You can claim your promo by booking an appointment by ${moment(promo.promoEndDate.toDate()).format('MMMM Do, Y')} with the clinic. We have included the clinic in this email to help you book an appointment. `,
            html: `Congratulations ${firstName ? firstName : emailAddress},<br/><br/> You have linked your ${promo.nameen} with the following clinic:<b> ${company.name}</b>. <br/><br/> You can claim your promo by booking an appointment by<b> ${moment(promo.promoEndDate.toDate()).format(
              'MMMM Do, Y'
            )}</b> with the clinic.<br/><br/> We have included the clinic in this email to help you book an appointment.<br/><br/> Thank you for signing up with The Solta Advantage Program. `,
          },
        });

      //Finally we need to hide the login information
      setShowLogin(false);
    }
  };

  const formFiledOut = firstName.length > 0 && lastName.length > 0 && emailAddress.length > 0 && password.length >= 8 && reTypePassword.length >= 8 && termsServices;
  const formFiledOutQC = emailAddress.length > 0 && password.length >= 8 && reTypePassword.length >= 8 && termsServices;

  const passwordError = password.length < 8 && password !== '';
  const retypePasswordError = reTypePassword !== password && reTypePassword !== '';

  return (
    <Grid container spacing={3} justifyContent='center'>
      <Grid item sm={12} xs={12} align='center'>
        {userProvince === '' ? (
          <FormControl className={classes.textField}>
            <InputLabel htmlFor='age-native-helper'>{t('signUp.yourProvince')}</InputLabel>
            <NativeSelect onChange={(event) => setUserProvince(event.target.value)} name='userProvince' value={userProvince}>
              <option value=''></option>
              {canadianProvinces.map((p) => (
                <option key={p.abbrev} value={p.abbrev}>
                  {p.name}
                </option>
              ))}
            </NativeSelect>
          </FormControl>
        ) : userProvince === PROVINCES.QUEBEC.abbrev ? (
          <form>
            <Grid container spacing={3} alignItems='center' justifyContent='center'>
              <Grid item xs={12}>
                <TextField className={classes.textField} onChange={(event) => setEmailAddress(event.target.value)} id='emailAddress' name='emailAddress' autoComplete='username' value={emailAddress} label='Email' />
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <InputLabel htmlFor='password'>Password</InputLabel>
                  <Input
                    className={classes.textField}
                    onChange={(event) => setPassword(event.target.value)}
                    error={passwordError}
                    id='password'
                    name='password'
                    type={passwordType}
                    autoComplete='new-password'
                    value={password}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton aria-label='toggle password visibility' onClick={showMyPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id='reTypePassword'>{t('signUp.passwordRestrictions')}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <InputLabel htmlFor='reTypePassword'>Re-Type Password</InputLabel>
                  <Input
                    className={classes.textField}
                    onChange={(event) => setReTypePassword(event.target.value)}
                    error={retypePasswordError}
                    id='reTypePassword'
                    name='reTypePassword'
                    type={retypePasswordType}
                    autoComplete='new-password'
                    value={reTypePassword}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton aria-label='toggle password visibility' onClick={showMyRetypePassword}>
                          {showRetypePass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id='reTypePassword'>{t('signUp.passwordReType')}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={termsServices} onChange={(event) => setTermsServices(event.target.checked)} name='termsServices' color='primary' />}
                  label={
                    <div>
                      <span>I agree to the </span>
                      <Link to={ROUTES.TERMS} target='_blank'>
                        Terms and Services
                      </Link>
                    </div>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <SoltaButton size='large' disabled={!formFiledOutQC} onClick={addEmailUser}>
                  {t('signUp.button')}
                </SoltaButton>
              </Grid>
            </Grid>
          </form>
        ) : (
          <form>
            <Grid container spacing={3} alignItems='center' justifyContent='center'>
              <Grid item xs={12}>
                <TextField className={classes.textField} onChange={(event) => setFirstName(event.target.value)} id='firstName' name='firstName' value={firstName} label='First Name' />
              </Grid>
              <Grid item xs={12}>
                <TextField className={classes.textField} onChange={(event) => setLastName(event.target.value)} id='lastName' name='lastName' value={lastName} label='Last Name' />
              </Grid>
              <Grid item xs={12}>
                <TextField className={classes.textField} onChange={(event) => setPhoneNumber(event.target.value)} id='phoneNumber' name='phoneNumber' value={phoneNumber} label='Phone Number' />
              </Grid>
              <Grid item xs={12}>
                <TextField className={classes.textField} onChange={(event) => setEmailAddress(event.target.value)} id='emailAddress' name='emailAddress' autoComplete='username' value={emailAddress} label='Email' />
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <InputLabel htmlFor='password'>Password</InputLabel>
                  <Input
                    className={classes.textField}
                    onChange={(event) => setPassword(event.target.value)}
                    error={passwordError}
                    id='password'
                    name='password'
                    type={passwordType}
                    autoComplete='new-password'
                    value={password}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton aria-label='toggle password visibility' onClick={showMyPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id='reTypePassword'>{t('signUp.passwordRestrictions')}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl>
                  <InputLabel htmlFor='reTypePassword'>Re-Type Password</InputLabel>
                  <Input
                    className={classes.textField}
                    onChange={(event) => setReTypePassword(event.target.value)}
                    error={retypePasswordError}
                    id='reTypePassword'
                    name='reTypePassword'
                    type={retypePasswordType}
                    autoComplete='new-password'
                    value={reTypePassword}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton aria-label='toggle password visibility' onClick={showMyRetypePassword}>
                          {showRetypePass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id='reTypePassword'>{t('signUp.passwordReType')}</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox checked={termsServices} onChange={(event) => setTermsServices(event.target.checked)} name='termsServices' color='primary' />}
                  label={
                    <div>
                      <span>I agree to the </span>
                      <Link to={ROUTES.TERMS} target='_blank'>
                        Terms and Services
                      </Link>
                    </div>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <SoltaButton disabled={!formFiledOut} onClick={addEmailUser}>
                  {t('signUp.button')}
                </SoltaButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Grid>
    </Grid>
  );
}

export default withTranslation()(SignUp);
